import React from "react";
import "./CustomRadio.style.css";

interface CustomRadioProps {
  label: string | React.ReactNode;
  value: string;
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string; // Add className for dynamic classes
  style?: React.CSSProperties; // Add style for inline styles
}

const CustomRadio: React.FC<CustomRadioProps> = ({
  label,
  value,
  name,
  checked,
  className = "",
  onChange,
}) => {
  return (
    <label className={`custom-radio ${className}`}>
      <input
        type="radio"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span className="radio-mark"></span>
      {label}
    </label>
  );
};

export default CustomRadio;
