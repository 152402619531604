import React from "react";
import "./StoreFrontSkeleton.style.css"; // Link the CSS file for styles

export const SkeletonLoader: React.FC = () => {
  return (
    <div>
      {/* Store Header Skeleton */}
      <div className="storefront-skeleton-header">
        <div className="storefront-skeleton-logo"></div>
        <div className="storefront-skeleton-store-info">
          <div className="storefront-skeleton-text"></div>
          <div className="storefront-skeleton-text small"></div>
        </div>
      </div>

      {/* Store Stats Skeleton */}
      <div className="storefront-skeleton-stats">
        <div className="storefront-skeleton-stat">
          <div className="storefront-skeleton-circle"></div>
          <div className="storefront-skeleton-text small"></div>
        </div>
        <div className="storefront-skeleton-stat">
          <div className="storefront-skeleton-circle"></div>
          <div className="storefront-skeleton-text small"></div>
        </div>
        <div className="storefront-skeleton-stat">
          <div className="storefront-skeleton-circle"></div>
          <div className="storefront-skeleton-text small"></div>
        </div>
      </div>

      <div className="storefront-skeleton-free-delivery"></div>

      {/* Tabs Skeleton */}
      <div className="storefront-skeleton-tabs">
        <div className="storefront-skeleton-tab"></div>
        <div className="storefront-skeleton-tab"></div>
      </div>

      {/* Products Skeleton */}
      <div className="storefront-skeleton-products">
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
          <div className="storefront-skeleton-product-card-name"></div>
          <div className="storefront-skeleton-product-card-description"></div>
          <div className="storefront-skeleton-product-card-price"></div>
        </div>
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
          <div className="storefront-skeleton-product-card-name"></div>
          <div className="storefront-skeleton-product-card-description"></div>
          <div className="storefront-skeleton-product-card-price"></div>
        </div>
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
        </div>
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
