import React from "react";

interface LoaderProps {
  size: number; // Size of the loader in pixels
}

const Loader: React.FC<LoaderProps> = ({size}) => {
  const loaderStyle: React.CSSProperties = {
    border: `${size / 5}px solid #f3f3f3`, // Light gray border
    borderTop: `${size / 5}px solid #3498db`, // Blue border for spinner
    borderRadius: "50%",
    width: `${size}px`, // Dynamic width based on size prop
    height: `${size}px`, // Dynamic height based on size prop
    animation: "spin 1s linear infinite",
    position: "relative",
    margin: "auto",
  };

  return <div style={loaderStyle}></div>;
};

export default Loader;
