import React, {useState} from "react";
import "react-toastify/dist/ReactToastify.css";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import ProductInfo from "../ProductInfo/ProductInfo";
import "./LiveStream.style.css";
import SaleProducts from "../SaleProducts/SaleProducts";
import {LiveStreamProps} from "../../../features/LiveStream/LiveStreamInterface";

interface LiveStreamComponentProps {
  item: LiveStreamProps;
  onHandleStreamPage?: () => void;
  showCountdown: boolean;
  showSaleProducts: boolean;
  streamId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
}

const LiveStream: React.FC<LiveStreamComponentProps> = ({
  item,
  onHandleStreamPage,
  showCountdown,
  showSaleProducts,
  streamId,
  user,
}) => {
  const [timeLeft, setTimeLeft] = useState<string>("");

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src =
      "https://fastly.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY";
  };

  return (
    <div
      onClick={() => {
        if (onHandleStreamPage) {
          onHandleStreamPage();
        }
      }}
      style={{
        cursor: onHandleStreamPage ? "pointer" : "none",
        maxWidth: "90vw",
      }}>
      <div
        style={
          onHandleStreamPage
            ? {}
            : {
                display: "flex",
                justifyContent: "center",
              }
        }>
        <div style={onHandleStreamPage ? {} : {width: "90%"}}>
          <img
            alt="Thumbnail"
            className="background-thumbnail"
            style={{width: "100%", height: "100%"}}
            src={item.thumbnail}
            onError={handleImageError}
          />
        </div>

        <div>
          <CountdownTimer
            timeLeft={timeLeft}
            setTimeLeft={setTimeLeft}
            time={item.scheduled_time}
            status={item.status}
            showCountdown={showCountdown}
          />
        </div>
      </div>
      <ProductInfo
        title={item.title}
        description={item.description}
        status={
          timeLeft === "Live Now"
            ? item.status === "in_progress" || item.status === "url_generated"
              ? "Live"
              : "Stream Ended"
            : "Upcoming"
        }
        sellerName={item.seller_store_name || item.seller_id}
        storeId={item.seller_store_id}
        scheduledTime={item.scheduled_time}
        showFullDescription={!onHandleStreamPage}
        sellerImage={item?.seller_profile_url ?? ""}
        streamId={streamId}
        user={user}
      />

      {showSaleProducts && (
        <SaleProducts
          products={item.stream_varient_mapping || []}
          storeId={item.seller_store_id || ""}
          status={
            timeLeft === "Live Now"
              ? item.status === "in_progress" || item.status === "url_generated"
                ? "Live"
                : "Stream Ended"
              : "Upcoming"
          }
        />
      )}
    </div>
  );
};

export default LiveStream;
