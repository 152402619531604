import React from "react";
import "./CategoryProducts.styles.css"; // Make sure to import your CSS file

const CategoryProductSkeleton = () => {
  return (
    <div className="category-products-product-card-skeleton skeleton">
      {/* Skeleton for Image */}
      <div className="category-product-image-container-skeleton skeleton"></div>

      {/* Skeleton for Product Details */}
      <div className="category-product-details-container-skeleton skeleton">
        <div className="category-product-name skeleton-text"></div>
        <div className="category-product-description skeleton-text"></div>
        <div className="category-product-price-container-skeleton">
          <div className="price skeleton-text"></div>
          <div className="old-price skeleton-text"></div>
        </div>
      </div>
    </div>
  );
};

export default CategoryProductSkeleton;
