import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const ExpandMoreSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1194_3845"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20">
        <rect width={width} height={height} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1194_3845)">
        <path
          d="M10 12.2178C9.89957 12.2178 9.80609 12.2018 9.71956 12.1697C9.63302 12.1377 9.55076 12.0826 9.47277 12.0047L5.72759 8.25947C5.6122 8.14409 5.55317 7.99906 5.5505 7.82438C5.54782 7.6497 5.60685 7.502 5.72759 7.38128C5.84831 7.26056 5.99467 7.2002 6.16667 7.2002C6.33867 7.2002 6.48503 7.26056 6.60575 7.38128L10 10.7755L13.3943 7.38128C13.5096 7.2659 13.6547 7.20687 13.8293 7.20419C14.004 7.20153 14.1517 7.26056 14.2724 7.38128C14.3932 7.502 14.4535 7.64837 14.4535 7.82038C14.4535 7.99238 14.3932 8.13874 14.2724 8.25947L10.5272 12.0047C10.4492 12.0826 10.367 12.1377 10.2804 12.1697C10.1939 12.2018 10.1004 12.2178 10 12.2178Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default ExpandMoreSvg;
