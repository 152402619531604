import React, {useEffect} from "react";
import moment from "moment";
import "./CountdownTimer.style.css";

type CountdownTimerProps = {
  timeLeft: string;
  setTimeLeft: (time: string) => void;
  time: string;
  status: string; // add more status options if needed
  showCountdown: boolean;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  timeLeft,
  setTimeLeft,
  time,
  status,
  showCountdown,
}) => {
  useEffect(() => {
    const updateTimer = () => {
      const currentTime = moment();
      const scheduledTime = moment(time);
      const duration = moment.duration(scheduledTime.diff(currentTime));

      if (
        duration.asSeconds() > 0 &&
        (status === "open" || status === "url_generated")
      ) {
        const hours = Math.floor(duration.asHours())
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor(duration.minutes())
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor(duration.seconds())
          .toString()
          .padStart(2, "0");

        setTimeLeft(`${hours}:${minutes}:${seconds}`);
      } else {
        setTimeLeft("Live Now");
      }
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [time, status, setTimeLeft]);

  return (
    <React.Fragment>
      {timeLeft === "Live Now" || !showCountdown ? (
        <div />
      ) : (
        <div className="countdown-container">
          <div className="countdown-timer">
            <span>
              Starts in <span className="timer-count">{timeLeft}</span>
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CountdownTimer;
