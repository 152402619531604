import React from "react";

interface IndicatorSvgProps {
  width: number;
  height: number;
  color?: string; // Optional color prop for customization
}

const primary60 = "#1E799C"; // Replace with the actual primary color in your theme

const IndicatorSvg: React.FC<IndicatorSvgProps> = ({
  width,
  height,
  color = primary60,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" rx="16" fill={color} />
      <path
        d="M7.6001 17.2L12.4001 22L24.4001 10"
        stroke="#F1F1F3"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IndicatorSvg;
