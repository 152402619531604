import React from "react";
import "./Category.css";
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useNavigate} from "react-router";
/* eslint-disable  @typescript-eslint/no-require-imports*/
const cd1 = require("../../image/vinyl/cd1.png");
const vinyl2 = require("../../image/vinyl/vinyl2.png");
const vinyl3 = require("../../image/vinyl/vinyl3.png");
const vinyl4 = require("../../image/vinyl/vinyl4.jpeg");

const top1 = require("../../image/women_wears_top/top1.png");
const top2 = require("../../image/women_wears_top/top2.png");
const top3 = require("../../image/women_wears_top/top3.jpeg");
const top4 = require("../../image/women_wears_top/top4.jpeg");

const jwel1 = require("../../image/jwellery/jwel1.jpeg");
const jwel2 = require("../../image/jwellery/jwel2.jpeg");

const ART1 = require("../../image/arts/art1.jpeg");
const ART2 = require("../../image/arts/art2.jpeg");

const jackect1 = require("../../image/jackets/jackets1.jpeg");
const jackect2 = require("../../image/jackets/jacket2.jpeg");
const jackect3 = require("../../image/jackets/jacket3.jpeg");
const jackect4 = require("../../image/jackets/jacket4.jpeg");
const jackect5 = require("../../image/jackets/jacket5.jpeg");

const CategorySection = () => {
  // const { categories, loading } = useSelector((state: RootState) => state.user);

  const Categories = [
    [
      {image: cd1, product_id: "1727852998"},
      {image: vinyl2, product_id: "1727851797"},
      {image: vinyl3, product_id: "1731564640"},
      {image: vinyl4, product_id: "1732004362"},
    ],
    [
      {image: jwel1, product_id: "1731864397"},
      {image: jwel2, product_id: "1731924182"},
    ],
    [
      {image: top1, product_id: "1732190716"},
      {image: top2, product_id: "1732255019"},
      {image: top3, product_id: ""},
      {image: top4, product_id: "1730640496"},
    ],
    [
      {image: jackect1, product_id: "1731346841"},
      {image: jackect2, product_id: "1726557185"},
      {image: jackect3, product_id: "1732296732"},
      {image: jackect4, product_id: "1732187850"},
      {image: jackect5, product_id: "1731385286"},
    ],
    [
      {image: ART1, product_id: "1732013084"},
      {image: ART2, product_id: "1732012557"},
    ],
  ];

  return (
    <section className="category-section">
      <div className="category-product-grid">
        <CategoryCard
          images={Categories[0]}
          className=""
          featured={false}
          categoryName="Vinyl Records"
          categoryId={8}
          subCategoryId={71}
        />
        <CategoryCard
          images={Categories[2]}
          featured={false}
          className="hide-on-mobile"
          categoryName={`Women's Tops`}
          categoryId={2}
          subCategoryId={50}
        />
        <CategoryCardV2
          images={Categories[3]}
          featured={true}
          className="hide-on-mobile"
          categoryName="Racing Jackets"
          categoryId={1}
          subCategoryId={3}
        />
        <CategoryCard
          images={Categories[1]}
          className="hide-on-mobile"
          featured={false}
          categoryName="Handmade Gems"
          categoryId={7}
          subCategoryId={35}
        />
        <CategoryCard
          images={Categories[4]}
          featured={false}
          className=""
          categoryName="Arts, Crafts and Stationary"
          categoryId={8}
          subCategoryId={103}
        />
      </div>
    </section>
  );
};

export default CategorySection;

interface CategoryProps {
  images: any;
  featured: boolean;
  categoryName: string;
  className: string;
  categoryId: number;
  subCategoryId: number;
}

interface imgSrc {
  image: string;
  product_id: string;
}

const CategoryCard: React.FC<CategoryProps> = ({
  images,
  featured,
  className,
  categoryName,
  categoryId,
  subCategoryId,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`category-product-card ${featured ? "featured-card" : ""} ${className}`}
      onClick={() => {
        navigate(
          `/products?categories=${categoryId}&subcategories=${subCategoryId}`,
        );
      }}>
      <div className="heading">
        {/* <span>Zoop</span> */}
        <span>{categoryName}</span>
      </div>
      <div className="images-grid">
        {images.map((imgSrc: imgSrc, index: any) => {
          return (
            <img
              key={index}
              src={imgSrc.image}
              alt={`Product ${index}`}
              onClick={() => {
                if (imgSrc?.product_id) {
                  navigate(`/product/${imgSrc?.product_id}`);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const CategoryCardV2: React.FC<CategoryProps> = ({
  images,
  featured,
  className,
  categoryName,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`category-product-card ${featured ? "featured-card" : ""} ${className}`}
      onClick={() => {
        navigate(`/storefront/5253f827-e688-436b-8f82-b777790369ab`);
      }}>
      <div className="heading">
        <span>{categoryName}</span>
      </div>
      <div className="images-grid-v2">
        {images.map((imgSrc: imgSrc, index: any) => (
          <img key={index} src={imgSrc.image} alt={`Product ${index}`} />
        ))}
      </div>
    </div>
  );
};
