import React from "react";
import "./OrderDetails.style.css";

type OrderDetailsProps = {
  quantity: number;
  price: number;
  discount: number;
  deliveryCharge: number;
};

const OrderDetails: React.FC<OrderDetailsProps> = ({
  quantity,
  price,
  discount,
  deliveryCharge,
}) => {
  const totalPrice = (+price || 0) - (+discount || 0) + (+deliveryCharge || 0);

  return (
    <div className="order-details-container">
      <div className="order-details-flex-container">
        <div>
          <span className="order-details-title-text">Order details</span>
        </div>
      </div>
      <div className="order-details-flex-container">
        <div>
          <span className="order-details-item-text">No. of item</span>
        </div>
        <div>
          <span className="order-details-item-text">{quantity}</span>
        </div>
      </div>
      <div className="order-details-flex-container">
        <div>
          <span className="order-details-item-text">Price</span>
        </div>
        <div>
          <span className="order-details-item-text">₹{price}</span>
        </div>
      </div>
      <div className="order-details-flex-container">
        <div>
          <span className="order-details-item-text">Discount</span>
        </div>
        <div>
          <span className="order-details-item-text" style={{color: "#0B801E"}}>
            -₹{discount}
          </span>
        </div>
      </div>
      <div className="order-details-flex-container">
        <div>
          <span className="order-details-item-text">Delivery charge</span>
        </div>
        <div>
          <span className="order-details-item-text">₹{deliveryCharge}</span>
        </div>
      </div>

      <hr style={{border: "0.5px solid #CECCD3"}} />
      <div className="order-details-flex-container">
        <div>
          <span className="order-details-final-text">Order Total</span>
        </div>
        <div>
          <span className="order-details-final-text" style={{color: "#222026"}}>
            ₹{totalPrice}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
