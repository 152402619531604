import React, {useState, useEffect, useRef, useMemo} from "react";
import "./StreamVideo.style.css";
import {IoEyeOutline} from "react-icons/io5";
import {useNavigate, useParams} from "react-router";
import HLSPlayer from "../../../components/HslPlayer/HslPlayer";
import Carousel from "../../../components/mobile/Carousel/Carousel";
import ProductCard from "../../../components/mobile/ProductCard/ProductCard";
import Chat from "../../../components/mobile/Chat/Chat";
import Loader from "../../../Loader/Loader";
import io, {Socket} from "socket.io-client";
import {getLiveStreamProducts} from "../../../features/LiveStream/LiveStreamApi";
import BottomSheet from "../../../components/mobile/BottomSheet/BottomSheet";
import {findIndexByKey} from "../../../utils/helpers";
import {Switch} from "@mui/material";
import ShopSvg from "../../../components/SvgImages/ShopSvg/ShopSvg";
import ChatSvg from "../../../components/SvgImages/ChatSvg/ChatSvg";
import HideChatSvg from "../../../components/SvgImages/HideChatSvg/HideChatSvg";
import ShopProducts from "../../../components/mobile/ShopProducts/ShopProducts";
import RefreshIcon from "@mui/icons-material/Refresh";
import {useDispatch} from "react-redux";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import {toast} from "react-toastify";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import cookieUtils from "../../../utils/cookies";
import {apiConfig} from "../../../interceptors/api";
import {StreamVariantMapping} from "../../../features/LiveStream/LiveStreamInterface";

export const urlSocket: string = apiConfig.notificationUrl;

const label = {inputProps: {"aria-label": "Switch demo"}};

const StreamVideo: React.FC = () => {
  const socket: Socket = useMemo(() => {
    return io(urlSocket, {
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
    });
  }, []);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [showMessage, setShowMessage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [liveStreamProducts, setLiveStreamProducts] = useState<
    StreamVariantMapping[]
  >([]);
  const [publicUrl, setPublicUrl] = useState("");
  const [streamStatus, setStreamStatus] = useState("open");
  const [isExitBottomSheetOpen, setIsExitBottomSheetOpen] = useState(false);
  const [currentActivatedIndex, setCurrentActivatedIndex] = useState(-1);
  const [storeId, setStoreId] = useState("");
  const [isShopProductOpen, setIsShopProductOpen] = useState(false);
  const [userCount, setUserCount] = useState(0);

  const params = useParams<{id: string}>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (params.id) {
      fetchStreamProductsInitiation();
    }
  }, [params.id]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Failed to play video:", error);
      });
    }
  }, []);

  const fetchStreamProductsInitiation = async () => {
    setLoading(true);
    await fetchStreamProducts();
    setLoading(false);
  };

  const fetchStreamProducts = async () => {
    try {
      const payload = {stream_id: params.id};
      const response = await getLiveStreamProducts(payload);
      if (response.data && response.data[0]) {
        const data = response.data[0];
        if (
          data &&
          data.status !== "in_progress" &&
          data.status !== "url_generated"
        ) {
          toast.error(
            `Stream has ${
              data.status === "completed" ? "ended" : "not started"
            }`,
          );
          navigate(`/stream/${params.id}`);
        }
        const variantMapping = data["stream_varient_mapping"];
        setLiveStreamProducts(variantMapping);
        setPublicUrl(data.public_url);
        setStoreId(data.seller_store_id);
        setStreamStatus(data.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleProductActivated = (products: StreamVariantMapping[]) => {
    setLiveStreamProducts(prevLiveStreamProducts => {
      const tempLiveProducts = prevLiveStreamProducts.map(product => ({
        ...product,
      }));

      for (const product of products) {
        const index = findIndexByKey(
          tempLiveProducts,
          "varient_id",
          product.varient_id,
        );

        if (index !== -1) {
          tempLiveProducts[index] = {
            ...tempLiveProducts[index],
            is_marked_active: product.is_marked_active,
          };
          setCurrentActivatedIndex(index);
        }
      }

      return tempLiveProducts;
    });
  };

  const handleUpdateProductCount = (products: StreamVariantMapping[]) => {
    setLiveStreamProducts(prevLiveStreamProducts => {
      const tempUpdateLiveProducts = prevLiveStreamProducts.map(product => ({
        ...product,
      }));

      for (const product of products) {
        const index = findIndexByKey(
          tempUpdateLiveProducts,
          "varient_id",
          product.varient_id,
        );

        if (index !== -1) {
          tempUpdateLiveProducts[index] = {
            ...tempUpdateLiveProducts[index],
            varient_count: product.varient_count,
          };
        }
      }

      return tempUpdateLiveProducts;
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="stream-container">
      <div className="stream-body-container">
        <div className="live-container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "2%",
            }}>
            <div style={{display: "none"}}>
              <span className="live-text" style={{fontSize: "10px"}}>
                Mobile View
              </span>
              <Switch
                {...label}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "white",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "white",
                  },
                }}
              />
            </div>

            <div className="view-count">
              <IoEyeOutline
                size={"24px"}
                style={{marginRight: "10px", color: "white"}}
              />
              <span className="live-text">{userCount}</span>
            </div>
            <div className="stream-live">
              {" "}
              <span className="live-text">LIVE</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <button
              className="exit-stream"
              onClick={() => {
                ReactGA.event({
                  category: "User", // Represents the category of the event, e.g., "User"
                  action: "Exit Stream", // The action performed, e.g., "Mobile Input"
                  label:
                    "Exit Stream by " +
                    localStorage.getItem("userId") +
                    "on" +
                    params.id, // A label describing the event, e.g., "User Mobile Input"
                  value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
                  nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
                  // @ts-expect-error: Ignoring the type check for custom dimensions
                  dimension1: location.pathname + location.search, // Custom dimension for the page URL
                  dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
                  dimension3: params.id, // Custom dimension for the mobile number input
                });
                setIsExitBottomSheetOpen(true);
              }}>
              Exit Stream
            </button>
          </div>
        </div>

        {streamStatus == "in_progress" && publicUrl ? (
          <HLSPlayer
            data={{
              public_url: publicUrl,
              //"https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8",
            }}
          />
        ) : (
          <div className="stream-video-url-generated">
            <span style={{color: "white", fontFamily: "Plus Jakarta Sans"}}>
              Stream is about to start
            </span>

            <span
              style={{color: "white", fontFamily: "Plus Jakarta Sans"}}
              onClick={() => {
                navigate(0);
              }}>
              <RefreshIcon />
            </span>
          </div>
        )}

        <div className="stream-video-action-container">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              rowGap: "5%",
            }}>
            <div
              className="stream-video-action-item-container"
              onClick={() => {
                setShowMessage(!showMessage);
              }}>
              {showMessage ? (
                <div className="stream-video-action-item-logo">
                  <ChatSvg width={"24px"} height={"24px"} />
                </div>
              ) : (
                <div
                  className="stream-video-action-item-logo"
                  style={{padding: "0.27rem"}}>
                  <HideChatSvg width={"25px"} height={"25px"} />
                </div>
              )}
              <div className="stream-video-action-item-title">Chat</div>
            </div>

            <div
              className="stream-video-action-item-container"
              onClick={() => {
                setIsShopProductOpen(true);
              }}>
              <div className="stream-video-action-item-logo">
                <ShopSvg width={"24px"} height={"24px"} />
              </div>
              <div className="stream-video-action-item-title">Shop</div>
            </div>
          </div>
        </div>

        <div className="chat-wrapper-container ">
          <Chat
            username={localStorage.getItem("username") ?? ""}
            roomId={params.id ?? ""}
            socket={socket}
            onProductActivated={products => {
              handleProductActivated(products);
            }}
            onUpdateVariantCount={products => {
              handleUpdateProductCount(products);
            }}
            onChangeUserCount={value => {
              setUserCount(value);
            }}
            show={showMessage}
            onConnect={() => {
              fetchStreamProducts();
            }}
          />

          <Carousel jumpToIndex={currentActivatedIndex}>
            {liveStreamProducts.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                storeId={storeId}
                streamId={params.id ?? ""}
              />
            ))}
          </Carousel>
          {/*<ProductCarousel products={products} />*/}
        </div>
      </div>
      <ShopProducts
        open={isShopProductOpen}
        streamId={params.id ?? ""}
        onClose={() => setIsShopProductOpen(false)}
        storeId={storeId}
        products={
          liveStreamProducts && liveStreamProducts.length > 0
            ? liveStreamProducts
            : []
        }
      />
      <BottomSheet
        header={"Exit Stream"}
        isOpen={isExitBottomSheetOpen}
        onClose={() => {
          setIsExitBottomSheetOpen(false);
        }}>
        <div className="exit-stream-content-container">
          <p>Are you sure you want to exit stream?</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "5%",
            }}>
            <div style={{width: "50%"}}>
              <button
                className="exit-stream-yes-button"
                onClick={() => {
                  navigate("/");
                }}>
                Yes
              </button>
            </div>
            <div style={{width: "50%"}}>
              <button
                className="exit-stream-no-button"
                onClick={() => {
                  setIsExitBottomSheetOpen(false);
                }}>
                No
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default StreamVideo;
