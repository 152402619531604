import React, {useState} from "react";
import "./Accordion.css";

// Define the shape of each accordion item
interface AccordionItem {
  title: string;
  content: string | JSX.Element; // Content can be a string or JSX element
}

interface AccordionProps {
  accordionData: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({accordionData}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {accordionData.map((item, index) => (
        <div key={index} className="accordion-item">
          <button
            className="accordion-button"
            onClick={() => toggleAccordion(index)}>
            <span className="accordion-title">{item.title}</span>
            <svg
              className={`accordion-icon ${activeIndex === index ? "active" : ""}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <div
            className={`accordion-content ${
              activeIndex === index ? "active" : ""
            }`}>
            <div className="accordion-content-inner">{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
