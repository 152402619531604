import React from "react";
import "./Avatar.style.css";

interface AvatarProps {
  name?: string;
  width: number;
  height: number;
  colorClass: string;
  fullname?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  width,
  height,
  colorClass,
  fullname,
}) => {
  let avatarName = "";

  if (fullname) {
    const splitName = fullname.split(" ");

    if (splitName.length > 1) {
      // Initials from first and last name
      avatarName = splitName[0][0] + splitName[splitName.length - 1][0];
    } else {
      avatarName = splitName[0][0];
    }
  }

  return (
    <div
      style={{minWidth: `${width}px`, minHeight: `${height}px`}}
      className={`avatar ${colorClass}`}>
      <span className={`${colorClass}-text`}>{avatarName || "U"}</span>
    </div>
  );
};

export default Avatar;
