/* eslint-disable */
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const useUrlParams = (
  setCategoryIds: Function,
  setSubCategoryIds: Function,
  setSelectedFilters: Function,
  setSelectedSortOption: Function,
) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    const categoriesParam = urlParams.get("categories");
    const subcategoriesParam = urlParams.get("subcategories");
    const sortParam = urlParams.get("sort");

    if (categoriesParam) {
      setCategoryIds(categoriesParam);
      const selectedCategories = categoriesParam.split(",").map(id => ({
        id: parseInt(id),
        name: "", // Fetch category name if needed
      }));
      setSelectedFilters((prev: any) => ({
        ...prev,
        selectedCategories,
      }));
    }

    if (subcategoriesParam) {
      setSubCategoryIds(subcategoriesParam);
      const selectedSubCategories = subcategoriesParam.split(",").map(id => ({
        id: parseInt(id),
        name: "", // Fetch subcategory name if needed
      }));
      setSelectedFilters((prev: any) => ({
        ...prev,
        selectedSubCategories,
      }));
    }

    if (sortParam) {
      setSelectedSortOption(sortParam.replace("-", " ").toUpperCase());
    }
  }, [
    location.search,
    setCategoryIds,
    setSelectedFilters,
    setSelectedSortOption,
  ]);
};
