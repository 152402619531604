// cookieUtils.js
import Cookies from "js-cookie";

const cookieUtils = {
  // Set a cookie with a name, value, and optional attributes (e.g., expiration, path, secure)
  setCookie: (name, value, options = {}) => {
    Cookies.set(name, value, {
      expires: options.expires || 7, // Default expires in 7 days
      path: options.path || "/",
      secure: options.secure || true,
      sameSite: options.sameSite || "Strict", // Protects against CSRF attacks
      ...options,
    });
  },

  // Get a cookie by its name
  getCookie: name => {
    return Cookies.get(name);
  },

  // Remove a cookie by its name and optional attributes (e.g., path)
  removeCookie: (name, options = {}) => {
    Cookies.remove(name, {
      path: options.path || "/",
      ...options,
    });
  },
};

export default cookieUtils;
