import {api, apiConfig} from "../../interceptors/api";

export const initiatePaymentApi = async payload => {
  try {
    const response = await api.post(
      `${apiConfig.paymentUrl}/api/payment/createPaymentSession`,
      payload,
    );

    return response.data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Failed to initiate payment");
  }
};
