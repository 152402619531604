import React from "react";

interface FilterSvgProps {
  width: number | string;
  height: number | string;
  color?: string;
}

const FilterSvg: React.FC<FilterSvgProps> = ({
  width,
  height,
  color = "#7B7589",
}) => (
  <svg width={width} height={height} viewBox="0 0 16 12" fill="none">
    <path
      d="M0.222656 1.55556C0.222656 1.09533 0.595752 0.722229 1.05599 0.722229H14.9449C15.4051 0.722229 15.7782 1.09533 15.7782 1.55556C15.7782 2.0158 15.4051 2.3889 14.9449 2.3889H1.05599C0.595752 2.3889 0.222656 2.0158 0.222656 1.55556Z"
      fill={color}
    />
    <path
      d="M4.38932 10.4445C4.38932 9.98421 4.76242 9.61112 5.22266 9.61112H10.7782C11.2384 9.61112 11.6115 9.98421 11.6115 10.4445C11.6115 10.9047 11.2384 11.2778 10.7782 11.2778H5.22266C4.76242 11.2778 4.38932 10.9047 4.38932 10.4445Z"
      fill={color}
    />
    <path
      d="M3.00043 5.16667C2.5402 5.16667 2.1671 5.53977 2.1671 6.00001C2.1671 6.46024 2.5402 6.83334 3.00043 6.83334H13.0004C13.4607 6.83334 13.8338 6.46024 13.8338 6.00001C13.8338 5.53977 13.4607 5.16667 13.0004 5.16667H3.00043Z"
      fill={color}
    />
  </svg>
);

export default FilterSvg;
