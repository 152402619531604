import * as React from "react";

export interface HomeSvgProps {
  width: number;
  height: number;
  color?: string;
}

export const HomeSvg = function HomeSvg(props: HomeSvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 17"
      fill="none">
      <path
        d="M1.99997 14.9999H5.34615V9.96142C5.34615 9.70533 5.43277 9.49067 5.606 9.31744C5.77923 9.14421 5.9939 9.05759 6.25 9.05759H9.74995C10.0061 9.05759 10.2207 9.14421 10.3939 9.31744C10.5672 9.49067 10.6538 9.70533 10.6538 9.96142V14.9999H14V6.15374C14 6.10246 13.9888 6.05598 13.9663 6.01432C13.9439 5.97265 13.9134 5.93579 13.875 5.90374L8.18268 1.62489C8.13139 1.58002 8.07049 1.55759 7.99997 1.55759C7.92946 1.55759 7.86856 1.58002 7.81727 1.62489L2.12497 5.90374C2.08652 5.93579 2.05607 5.97265 2.03362 6.01432C2.01119 6.05598 1.99997 6.10246 1.99997 6.15374V14.9999ZM0.5 14.9999V6.15374C0.5 5.86752 0.564025 5.59638 0.692075 5.34029C0.820125 5.08421 0.997125 4.87332 1.22307 4.70762L6.91537 0.419167C7.23126 0.178133 7.59227 0.0576172 7.99842 0.0576172C8.40457 0.0576172 8.76662 0.178133 9.08457 0.419167L14.7769 4.70762C15.0028 4.87332 15.1798 5.08421 15.3079 5.34029C15.4359 5.59638 15.5 5.86752 15.5 6.15374V14.9999C15.5 15.4089 15.3522 15.7611 15.0567 16.0566C14.7612 16.3521 14.4089 16.4999 14 16.4999H10.0577C9.80159 16.4999 9.58692 16.4132 9.41367 16.24C9.24044 16.0668 9.15382 15.8521 9.15382 15.596V10.5575H6.84613V15.596C6.84613 15.8521 6.75951 16.0668 6.58627 16.24C6.41302 16.4132 6.19836 16.4999 5.94227 16.4999H1.99997C1.59101 16.4999 1.23877 16.3521 0.943275 16.0566C0.647758 15.7611 0.5 15.4089 0.5 14.9999Z"
        fill={props.color ? props.color : "#222026"}
      />
    </svg>
  );
};
