import React from "react";
import "./CircularAvatar.style.css";

interface CircularAvatarProps {
  imageSource: string; // The source URL or path of the image
}

const CircularAvatar: React.FC<CircularAvatarProps> = ({imageSource}) => {
  return (
    <div className="avatar_container">
      <img src={imageSource} alt="avatar" className="avatar_image" />
    </div>
  );
};

export default CircularAvatar;
