// src/components/ProductInfo.tsx
import React, {useState} from "react";
import "./ProductInfo.style.css";
import SellerInfo from "../SellerInfo";
import {formatTimestamp} from "../../../utils/helpers";
import {useNavigate} from "react-router";
import {addUserReminder} from "../../../features/User/UserApi";
import {toast} from "react-toastify";
import RemindMePopup from "../../RemindMe/RemindMePopup";
import {CiBellOn} from "react-icons/ci";
import ShareSvg from "../../SvgImages/ShareSvg/ShareSvg";

interface ProductInfoProps {
  title: string;
  description: string;
  status: string;
  sellerName: string;
  scheduledTime: string;
  showFullDescription: boolean;
  sellerImage: string;
  storeId: string;
  streamId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  title,
  description,
  status,
  sellerName,
  scheduledTime,
  showFullDescription,
  sellerImage,
  storeId,
  streamId,
}) => {
  const navigate = useNavigate();

  // const { isSignedIn } = useSelector((state: RootState) => state.authentication);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reminderLoader, setReminderLoader] = useState(false);
  const [reminderSubmitted, setReminderSubmitted] = useState(false);
  const handleCardClick = (storeId: string) => {
    navigate(`/storefront/${storeId}`, {
      state: {
        storeId: storeId,
      },
    });
  };

  const handleReminder = async () => {
    setIsModalOpen(true);
  };

  const handleReminderSubmit = () => {
    setReminderSubmitted(true);
    setIsModalOpen(false);
  };

  const handlePopupSubmit = async (phoneNumber: string) => {
    setReminderLoader(true);
    try {
      const payload = {
        event_id: streamId,
        email: "",
        mobile: phoneNumber,
        store_id: "",
        status: "pending",
        user_id: phoneNumber,
        event_name: title,
      };
      const response = await addUserReminder(payload);
      setReminderLoader(false);
      toast.success(response.message, {
        toastId: response.data?.event_id,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setReminderLoader(false);
      console.log("Unexpected Error:", error.message || error);
      toast.error("Error while creating reminder, Please contact support");
    }
  };

  return (
    <div>
      <div
        className="mobile-product-details-container"
        style={{
          background:
            status === "Live"
              ? "#EF4444"
              : status === "Upcoming"
                ? "#3897f1"
                : "#111827",
        }}>
        <span className="mobile-product-details-status-title">{status}</span>
      </div>

      <div
        className="mobile-product-details-seller-container"
        onClick={() => handleCardClick(storeId)}>
        <SellerInfo image={sellerImage} name={sellerName} />
      </div>

      <div className="mobile-product-details">
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <h2 className="mobile-product-name">{title}</h2>
          {status.toLowerCase() === "upcoming" ? (
            <>
              {reminderSubmitted ? (
                <div
                  style={{
                    border: "2px solid ##EEFBFD",
                    backgroundColor: "#EEFBFD",
                  }}
                  className="remind-me-live-stream"
                  onClick={() => handleReminder()}>
                  <CiBellOn
                    size={"20px"}
                    style={{color: reminderSubmitted ? "#1E799C" : "black"}}
                  />
                  <span
                    style={{
                      color: "#1E799C",
                    }}>{`We'll notify you`}</span>
                </div>
              ) : (
                <div
                  className="remind-me-live-stream"
                  onClick={() => handleReminder()}>
                  <CiBellOn size={"20px"} />

                  <span style={{marginLeft: "5px"}}> Remind me</span>
                </div>
              )}

              <div
                style={{
                  marginTop: "15px",
                }}
                onClick={() => {
                  const url = window.location.href; // Detect the current hosted URL
                  if (navigator.share) {
                    navigator
                      .share({
                        title: title ?? "Check out!!!",
                        text: `Check out ${title ?? ""} stream on Zoop :`,
                        url: url, // Use the detected URL
                      })
                      .then(() => console.log("Shared successfully!"))
                      .catch(error => console.error("Error sharing:", error));
                  } else {
                    alert("Your browser does not support the Web Share API.");
                  }
                }}>
                <ShareSvg height={25} width={25} />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <h2
          className="mobile-product-description"
          style={{WebkitLineClamp: showFullDescription ? 100 : 2}}>
          {description}
        </h2>

        <h2 className="mobile-product-stream-time">
          {formatTimestamp(scheduledTime)}{" "}
        </h2>
      </div>

      <RemindMePopup
        onSubmit={handlePopupSubmit}
        onCancel={() => setIsModalOpen(false)}
        onOkay={handleReminderSubmit}
        isOpen={isModalOpen}
        setLoader={setReminderLoader}
        loader={reminderLoader}
      />
    </div>
  );
};

export default ProductInfo;
