import React from "react";

interface InProgressIndicatorSvgProps {
  width: number;
  height: number;
  inProgress: boolean;
}

const primary60 = "#1E799C"; // Replace with the actual primary color in your theme

const InProgressIndicatorSvg: React.FC<InProgressIndicatorSvgProps> = ({
  width,
  height,
  inProgress,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="white">
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="15"
        stroke={inProgress ? primary60 : "#E5E5E8"}
        strokeWidth="2"
      />
      <circle cx="16" cy="16" r="6" fill={inProgress ? primary60 : "#F1F1F3"} />
    </svg>
  );
};

export default InProgressIndicatorSvg;
