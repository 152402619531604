import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  checkOtpAuthentication,
  getSendOtpAuthentication,
} from "./AuthenticationApi";
import cookieUtils from "../../utils/cookies";
import {
  AuthenticationState,
  CheckOtpAuthenticationPayload,
  SendOtpAuthenticationPayload,
} from "./AuthenticationInterface";

const initialState: AuthenticationState = {
  isSignedIn: false,
  authType: null,
  loading: false,
  error: null,
};

/* eslint no-empty: "error" */
export const getSendOtpAuthenticationThunk = createAsyncThunk(
  "authentication/sendOtp",
  async (payload: SendOtpAuthenticationPayload, {rejectWithValue}) => {
    try {
      return await getSendOtpAuthentication(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getVerifyOtpAuthenticationThunk = createAsyncThunk(
  "authentication/checkOtp",
  async (payload: CheckOtpAuthenticationPayload, {rejectWithValue}) => {
    try {
      return await checkOtpAuthentication(payload);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    reset: () => initialState,
    setAuthentication: (state, action) => {
      if (action.payload) {
        state.isSignedIn = action.payload;
      } else {
        localStorage.clear();
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSendOtpAuthenticationThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSendOtpAuthenticationThunk.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getSendOtpAuthenticationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getVerifyOtpAuthenticationThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVerifyOtpAuthenticationThunk.fulfilled, (state, action) => {
        state.loading = false;
        cookieUtils.setCookie("token", action.payload.data.token);
        //localStorage.setItem("token", action.payload.data.data.token);
        localStorage.setItem("userId", action.payload.data.user_id);
        localStorage.setItem("isOtpVerified", "true");
        state.isSignedIn = true;
      })
      .addCase(getVerifyOtpAuthenticationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default authenticationSlice.reducer;
export const {reset, setAuthentication} = authenticationSlice.actions;
