import React, {useState} from "react";
import {algoliasearch} from "algoliasearch";

import ZoopLogo from "../../SvgImages/ZooLogo/ZoopGreyLogo";
import BottomSheet from "../BottomSheet/BottomSheet";
import "./Header.style.css";
import cookieUtils from "../../../utils/cookies";
import Menu from "../Menu/Menu";
import {menuConfig} from "../../../constants/menuConfig";
import {useSelector} from "react-redux";
import {RootState} from "../../../interceptors/store";
import {matchPath, useLocation, useNavigate} from "react-router";
import {LoginUserIcon} from "../../SvgImages/LoginUserIcon/LoginUser";
import {ShoppingCart} from "../../SvgImages/ShoppingCart/ShoppingCart";
import {SearchBar} from "../../SearchBar/SearchBar";
import {Configure, InstantSearch} from "react-instantsearch";

const Header: React.FC = () => {
  const [isLogoutBottomSheetOpen, setIsLogoutBottomSheetOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);

  const location = useLocation();

  const handleSearch = (query: string) => {
    navigate(`/search?query=${encodeURIComponent(query.trim())}`);
  };

  const renderMobileView = !["/", "/products"].some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  );

  return (
    <>
      <div className="mobile-home-header">
        <div
          style={{cursor: "pointer"}}
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}>
          <ZoopLogo height={100} width={100} />
        </div>
        <InstantSearch indexName="products" searchClient={client}>
          <Configure hitsPerPage={20} />
          <SearchBar onSearch={handleSearch} isVisible={renderMobileView} />
        </InstantSearch>

        <div style={{textAlign: "right"}}>
          {isSignedIn ? (
            <div
              style={{
                display: "flex",
                textAlign: "right",
                justifyContent: "center",
                alignItems: "center",
                gap: "1.2rem",
                marginLeft: "8px",
              }}>
              <span
                style={{
                  marginTop: "4px",
                  display: "flex",
                  gap: ".5rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (isSignedIn) {
                    navigate("/checkout");
                  } else {
                    navigate("/login");
                  }
                }}>
                <ShoppingCart width={30} height={30} />
                {location.pathname === "/" ? (
                  <span className="header-cart-text">Cart</span>
                ) : (
                  ""
                )}
              </span>

              <Menu
                menu={menuConfig}
                onClick={id => {
                  if (id === "logout") {
                    setIsLogoutBottomSheetOpen(true);
                  }
                }}
              />
            </div>
          ) : (
            <div style={{display: "flex", gap: "1rem"}}>
              <div style={{textAlign: "right"}}>
                <button
                  className="header-logout-button"
                  onClick={() => {
                    navigate("/login");
                  }}>
                  <span style={{marginRight: "5px"}}>
                    <LoginUserIcon width={20} height={20} color={"white"} />{" "}
                  </span>
                  Login
                </button>
              </div>

              {/* <div style={{textAlign: "right"}}>
                <button
                  className="header-logout-button"
                  onClick={() => {
                    window.location.href =
                      "https://docs.google.com/forms/d/e/1FAIpQLSfOy6ZPu-ypYXtkc2s46INnEnd1piGjoIhQg1YF5KULLdhZKQ/viewform";
                  }}>
                  <span style={{marginRight: "5px"}}>
                    <LoginUserIcon width={20} height={20} color={"white"} />{" "}
                  </span>
                  Become a Seller
                </button>
              </div> */}
            </div>
          )}
        </div>
      </div>
      <BottomSheet
        header="Logout"
        isOpen={isLogoutBottomSheetOpen}
        onClose={() => setIsLogoutBottomSheetOpen(false)}>
        <div className="header-logout-content-container">
          <p style={{color: "black"}}>Are you sure you want to logout?</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "5%",
            }}>
            <div style={{width: "50%"}}>
              <button
                className="logout-yes-button"
                onClick={() => {
                  localStorage.clear();
                  cookieUtils.removeCookie("token");
                  window.location.href = "/";
                }}>
                Yes
              </button>
            </div>
            <div style={{width: "50%"}}>
              <button
                className="logout-no-button"
                onClick={() => setIsLogoutBottomSheetOpen(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

export default Header;
