import React, {useState, useEffect, useRef, TouchEvent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./DisplayProductCarousel.css";

type DisplayProductCarouselProps = {
  children: React.ReactNode;
  jumpToIndex?: number;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  hideLeftArrow?: boolean;
  hideRightArrow?: boolean;
};

const DisplayProductCarousel: React.FC<DisplayProductCarouselProps> = ({
  children,
  jumpToIndex,
  currentIndex,
  setCurrentIndex,
  hideLeftArrow = false,
  hideRightArrow = false,
}) => {
  const [startX, setStartX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const touchRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      jumpToIndex !== undefined &&
      jumpToIndex !== null &&
      jumpToIndex >= 0 &&
      jumpToIndex !== currentIndex
    ) {
      setCurrentIndex(jumpToIndex);
    }
  }, [jumpToIndex, currentIndex, setCurrentIndex]);

  const handleNext = () => {
    setCurrentIndex(
      prevIndex => (prevIndex + 1) % React.Children.count(children),
    );
  };

  const handlePrev = () => {
    setCurrentIndex(
      prevIndex =>
        (prevIndex - 1 + React.Children.count(children)) %
        React.Children.count(children),
    );
  };

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!isSwiping) return;

    const currentX = e.touches[0].clientX;
    const diff = startX - currentX;

    if (diff > 50) {
      handleNext();
      setIsSwiping(false);
    } else if (diff < -50) {
      handlePrev();
      setIsSwiping(false);
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
  };

  return (
    <div
      className="display-carousel-container"
      ref={touchRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      {!hideLeftArrow && (
        <button
          className="display-carousel-button display-carousel-button-left"
          onClick={handlePrev}>
          <FontAwesomeIcon icon={faChevronLeft} size="2x" />
        </button>
      )}

      <div className="display-carousel-content">
        {React.Children.map(children, (child, index) => {
          const isCurrent = index === currentIndex;
          const isLeft =
            index ===
            (currentIndex - 1 + React.Children.count(children)) %
              React.Children.count(children);
          const isRight =
            index === (currentIndex + 1) % React.Children.count(children);

          return (
            <div
              style={{
                position: "absolute",
                left: isCurrent
                  ? "50%"
                  : isRight
                    ? "100%"
                    : isLeft
                      ? "0%"
                      : "100%",
                transform: "translateX(-50%)",
                transition: "all 0.4s ease-in-out",
                opacity: isCurrent ? 1 : isLeft || isRight ? 0.5 : 0,
                width: isCurrent ? "50%" : "35%",
                transformOrigin: "center",
              }}>
              {child}
            </div>
          );
        })}
      </div>

      {!hideRightArrow && (
        <button
          className="display-carousel-button display-carousel-button-right"
          onClick={handleNext}>
          <FontAwesomeIcon icon={faChevronRight} size="2x" />
        </button>
      )}

      <div className="display-carousel-indicators">
        {React.Children.map(children, (_, index) => (
          <div
            className={`display-carousel-dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default DisplayProductCarousel;
