import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const StoreImgSvg: React.FC<SvgProps> = ({width, height, color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 16" fill="none">
      <path
        d="M1.96173 0.25H16.0387C16.2512 0.25 16.4293 0.321901 16.573 0.465701C16.7168 0.609518 16.7887 0.787717 16.7887 1.0003C16.7887 1.2129 16.7168 1.39099 16.573 1.53457C16.4293 1.67816 16.2512 1.74995 16.0387 1.74995H1.96173C1.74925 1.74995 1.57113 1.67805 1.42738 1.53425C1.28363 1.39043 1.21175 1.21223 1.21175 0.999651C1.21175 0.787051 1.28363 0.60896 1.42738 0.465376C1.57113 0.321793 1.74925 0.25 1.96173 0.25ZM2.15405 15.75C1.89797 15.75 1.68331 15.6633 1.51008 15.4901C1.33685 15.3169 1.25023 15.1022 1.25023 14.8461V9.74995H0.940604C0.655987 9.74995 0.421696 9.63873 0.237729 9.4163C0.0537456 9.19387 -0.00491294 8.94034 0.0617537 8.65573L1.06175 3.96345C1.10732 3.75535 1.21363 3.58442 1.38068 3.45065C1.54775 3.31688 1.73759 3.25 1.9502 3.25H16.0502C16.2628 3.25 16.4527 3.31688 16.6197 3.45065C16.7868 3.58442 16.8931 3.75535 16.9387 3.96345L17.9387 8.65573C18.0053 8.94034 17.9467 9.19387 17.7627 9.4163C17.5787 9.63873 17.3444 9.74995 17.0598 9.74995H16.7502V15C16.7502 15.2125 16.6783 15.3906 16.5345 15.5343C16.3907 15.6781 16.2125 15.75 15.9999 15.75C15.7873 15.75 15.6092 15.6781 15.4656 15.5343C15.322 15.3906 15.2502 15.2125 15.2502 15V9.74995H10.7502V14.8461C10.7502 15.1022 10.6636 15.3169 10.4903 15.4901C10.3171 15.6633 10.1024 15.75 9.84635 15.75H2.15405ZM2.75018 14.25H9.25023V9.74995H2.75018V14.25ZM1.66555 8.25H16.3349L15.5714 4.74995H2.42903L1.66555 8.25Z"
        fill={color ? color : "#222026"}
      />
    </svg>
  );
};

export default StoreImgSvg;
