import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import "./Menu.css";
import {MenuConfig} from "../../../constants/menuConfig";

interface MenuProps {
  menu: MenuConfig[];
  onClick: (id: string) => void;
}

const Menu: React.FC<MenuProps> = ({menu, onClick}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation(); // Get the current location

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent the default behavior of the button
    setIsOpen(prev => !prev); // Toggle the menu open/close
  };

  const closeMenu = () => {
    setIsOpen(false); // Close the menu
  };

  const isExternalLink = (path: string) => {
    return /^(http|https):\/\//.test(path); // Check if it's an external link
  };

  const handleLinkClick = (route: MenuConfig, event: React.MouseEvent) => {
    if (route.path === location.pathname) {
      // Prevent navigation if the link is the same as the current path
      event.preventDefault();
      closeMenu();
    } else {
      // Handle the normal navigation
      closeMenu(); // Close the menu
      onClick(route.id); // Call onClick with route ID
    }
  };

  return (
    <div className="hamburger-menu">
      <button
        className="hamburger-icon"
        onClick={toggleMenu} // Toggle menu open/close
        aria-label="Toggle menu"
        aria-expanded={isOpen}>
        <span className={`bar ${isOpen ? "open" : ""}`}></span>
        <span className={`bar ${isOpen ? "open" : ""}`}></span>
        <span className={`bar ${isOpen ? "open" : ""}`}></span>
      </button>

      {/* Sidebar overlay that opens from the left */}
      <div
        className={`menu-overlay ${isOpen ? "show" : ""}`}
        onClick={closeMenu} // Close the menu when the overlay is clicked
      >
        <aside className="menu-sidebar" onClick={e => e.stopPropagation()}>
          {/* Header Text */}
          <div className="menu-header">
            <h2>Menu</h2>
          </div>

          {/* Menu Items */}
          {menu.map(route => {
            const isExternal = isExternalLink(route.path);
            return isExternal ? (
              <a
                key={route.path}
                href={route.path}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  closeMenu();
                  onClick(route.id);
                }}
                className={`menu-item ${
                  location.pathname === route.path ? "active" : ""
                }`}>
                {route.label}
              </a>
            ) : (
              <Link
                key={route.path}
                to={route.path}
                onClick={e => handleLinkClick(route, e)}
                className={`menu-item ${
                  location.pathname === route.path ? "active" : ""
                }`}>
                {route.label}
              </Link>
            );
          })}
        </aside>
      </div>
    </div>
  );
};

export default Menu;
