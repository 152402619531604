import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const FailureSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 119 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.625 45.625L73.375 73.375M73.375 45.625L45.625 73.375M115 59.5C115 90.1518 90.1518 115 59.5 115C28.8482 115 4 90.1518 4 59.5C4 28.8482 28.8482 4 59.5 4C90.1518 4 115 28.8482 115 59.5Z"
        stroke="#D85656"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FailureSvg;
