/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, ChangeEvent} from "react";
import {ToastContainer, toast} from "react-toastify";
import Loader from "../../../../Loader/Loader";
import "./LoginMobile.style.css";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import DisplayProductCarousel from "../../DisplayProductCarousel/DisplayProductCarousel";
import {useSelector, useDispatch} from "react-redux";
import {formatTimestamp} from "../../../../utils/helpers";
import useMobilePlatform from "../../../../hooks/usePlatform";
import StreamListSkeleton from "./components/StreamListSkeleton";
import {AppDispatch, RootState} from "../../../../interceptors/store";
import {getSendOtpAuthenticationThunk} from "../../../../features/Authentication/AuthenticationSlice";

// Define types for the component props
interface LoginMobileProps {
  mobileNumber: string;
  setIsOtpSent: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>;
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
  setMessageId: React.Dispatch<React.SetStateAction<string>>;
}

const LoginMobile: React.FC<LoginMobileProps> = ({
  mobileNumber,
  setIsOtpSent,
  setIsEditing,
  setMobileNumber,
  setTimeLeft,
  setMessageId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const dispatch = useDispatch<AppDispatch>();

  const platform = useMobilePlatform();
  const {streams, loading: streamsLoading} = useSelector(
    (state: RootState) => state.liveStream,
  );

  const showErrorEvent = (data: any) => {
    ReactGA.event({
      category: "Error",
      action: "SendOtpError",
      label: "Send OTP Error",
      value: 1,
      nonInteraction: true,
      // @ts-expect-error: Ignoring the type check for custom dimensions
      dimension1: location.pathname + location.search,
      dimension2: localStorage.getItem("userId") || null,
      dimension3: "+91",
      dimension4: "buyer",
      dimension5: mobileNumber,
      dimension6: JSON.stringify(data),
    });
  };

  const handleMobileNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (input.length === 10) {
      ReactGA.event({
        category: "User",
        action: "Mobile Input",
        label: "User Mobile Input",
        value: 1,
        nonInteraction: true,
        // @ts-expect-error: Ignoring the type check for custom dimensions
        dimension1: location.pathname + location.search,
        dimension2: localStorage.getItem("userId") || null,
        dimension3: input,
      });
    }
    if (input.length <= 10) {
      setMobileNumber(input);
    }
  };

  const handleNextClick = async () => {
    if (mobileNumber.length === 10) {
      setLoading(true);
      try {
        ReactGA.event({
          category: "User",
          action: "SendOtp",
          label: "Send OTP",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: "+91",
          dimension4: "buyer",
          dimension5: mobileNumber,
        });

        const action = await dispatch(
          getSendOtpAuthenticationThunk({
            country_code: "+91",
            user_type: "buyer",
            mobile: mobileNumber,
          }),
        );

        if (getSendOtpAuthenticationThunk.fulfilled.match(action)) {
          if (action && action?.payload.success) {
            if (action?.payload?.data?.message_id) {
              toast.success("OTP sent successfully", {
                toastId: action?.payload?.data?.message_id,
              });
              setIsOtpSent(true);
              setTimeLeft(30);
              setLoading(false);
              setMessageId(action?.payload?.data?.message_id);
            } else {
              showErrorEvent(action?.payload as any);
              toast.error("Error sending OTP");
            }
          }
        } else {
          showErrorEvent(action?.payload as any);
          toast.error("Error sending OTP");
        }
      } catch {
        toast.error("An error occurred. Please try again.");
      }
    } else {
      toast.error("Please enter a valid 10-digit mobile number.");
    }
  };

  const preventWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent the default wheel behavior

    // Blur the input element that triggered the event
    (event.target as HTMLInputElement).blur();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      return;
    }
  };

  return (
    <>
      <ToastContainer />
      {!loading ? (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
          <div
            className="carousel-container"
            style={{height: platform === "ios" ? "35vh" : "37vh"}}>
            <DisplayProductCarousel
              jumpToIndex={0}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              hideLeftArrow={true}
              hideRightArrow={true}>
              {streamsLoading
                ? Array.from({length: 5}).map((_, index) => (
                    <StreamListSkeleton key={`stream-${index}`} />
                  ))
                : streams &&
                  streams.map((stream: any, index: number) => (
                    <div
                      className="carousel-stream"
                      key={`stream-${stream.id}`}
                      style={{
                        height: currentIndex === index ? "30vh" : "25vh",
                      }}>
                      <div className="carousel-image-wrapper">
                        <img
                          className="carousel-image"
                          src={stream.thumbnail}
                          alt="Product"
                        />
                        <span className="carousel-image-title">
                          {stream.title}
                        </span>
                      </div>
                      <div className="carousel-stream-timing">
                        {formatTimestamp(stream.scheduled_time)}
                      </div>
                    </div>
                  ))}
            </DisplayProductCarousel>
          </div>
          <div className="loginMobile-container" onWheel={preventWheel}>
            <div className="enter-number-heading">Enter your phone number</div>
            <div className="enter-number-subheading">
              Enter your number to verify with OTP
            </div>

            <input
              value={mobileNumber}
              type="number"
              onChange={handleMobileNumberChange}
              maxLength={13}
              placeholder="Enter Number"
              onWheel={preventWheel}
              onKeyDown={handleKeyDown}
            />
            <div
              style={{
                // position: "fixed",
                bottom: 0,
                width: "100%",
                marginTop: "46%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}>
              <button
                style={{
                  marginBottom: platform === "ios" ? "0%" : "2%",
                  backgroundColor: "#1e799c",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  handleNextClick();
                  setIsEditing(false);
                }}>
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default LoginMobile;
