/* eslint-disable @typescript-eslint/no-explicit-any */

import {useEffect, useRef} from "react";

// Define the types for the debounced function
type DebouncedFunction<T extends any[]> = (...args: T) => void;

const useDebounce = <T extends any[]>(
  callback: DebouncedFunction<T>,
  delay: number,
): DebouncedFunction<T> => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [callback, delay]);

  function debouncedFunction(...args: T) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }

  return debouncedFunction;
};

export default useDebounce;
