import React from "react";
import "./OrdersPageCardSkeleton.style.css"; // Optional: If you want to keep the styles in a separate file

const OrdersPageCardSkeleton = () => {
  return (
    <div className="skeleton-card">
      <div className="orders-page-card-header-skeleton">
        <div className="orders-page-card-skeleton-img-top skeleton"></div>
        <div className="orders-page-header-text-skeleton">
          <div className="orders-page-card-skeleton-text skeleton"></div>
          <div className="orders-page-card-skeleton-text skeleton"></div>
        </div>
      </div>
      <div className="orders-page-product-container">
        <div className="orders-page-left-container skeleton"></div>
        <div className="orders-page-right-container">
          <div className="orders-page-right-container-top">
            <div className="orders-page-card-skeleton-text skeleton"></div>
            <div className="orders-page-card-skeleton-text skeleton"></div>
          </div>
          <div className="orders-page-button-container-skeleton">
            <div className="orders-page-quantity-container-skeleton">
              <div className="orders-page-quantity-box-skeleton skeleton"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersPageCardSkeleton;
