import React from "react";

export interface CometSvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

export const CometSvg = function CometSvg(props: CometSvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M98.9145 76.2059C131.098 54.3725 140.557 48.3645 140.557 46.7579C140.557 45.6279 136.274 46.4012 133.061 48.1265C115.69 57.4065 78.5685 83.7605 79.4611 86.2592C80.1751 88.4005 83.3871 86.7352 98.9145 76.2059Z"
        fill="#EF4444"
      />
      <path
        d="M194.693 35.3352C194.693 33.2526 189.756 31.2299 186.722 32.0632C184.461 32.6579 174.645 39.4992 155.727 51.4566C137.464 62.9379 133.3 66.0912 134.371 67.7566C136.037 70.4339 145.079 63.7119 177.441 45.8646C195.229 35.9892 194.693 36.2866 194.693 35.3352Z"
        fill="#EF4444"
      />
      <path
        d="M114.978 77.7509C77.6179 98.9882 85.8872 95.8355 56.5585 110.53C30.3832 123.677 26.8732 124.51 17.4145 120.286C6.8252 115.527 5.39787 116.895 9.74054 127.722C11.6439 132.481 12.1205 134.326 11.8819 136.289C11.3465 140.75 9.08587 147.235 6.94454 150.15C0.162538 159.549 14.2019 155.563 15.2725 155.088C20.8059 152.708 26.3972 155.088 34.4879 163.238C39.8419 168.592 48.1705 169.662 46.1479 164.725C41.8052 154.136 43.1739 143.249 57.3319 130.935C68.8139 121 86.1252 107.198 101.473 96.7282C123.901 81.3796 125.805 79.7736 123.961 77.6316C122.711 76.1449 117.655 76.2042 114.978 77.7509ZM46.6245 129.388C33.0605 143.011 34.3099 152.172 33.9525 153.244C26.2192 149.258 27.4085 149.555 17.5339 149.02L19.0805 145.688C22.4712 138.371 19.8539 128.555 19.8539 128.496L24.3152 129.923C32.8219 132.6 39.8419 131.292 48.7659 125.402C50.6692 124.153 52.3945 123.082 52.5725 123.082C52.8112 123.022 50.1339 125.878 46.6239 129.388H46.6245Z"
        fill="#EF4444"
      />
    </svg>
  );
};
