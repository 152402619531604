import {useState, useEffect, useCallback} from "react";

// Define types for the hook parameters
interface UseTimerProps {
  initialTime: number;
  interval: number;
  onTick?: (currentTime: number) => void;
  onFinish?: () => void;
}

interface UseTimerReturn {
  time: number;
  stopTimer: () => void;
  resetTimer: (number: number) => void;
}

let timer: NodeJS.Timeout;

const useTimer = ({
  initialTime,
  interval,
  onTick,
  onFinish,
}: UseTimerProps): UseTimerReturn => {
  const [time, setTime] = useState<number>(initialTime);

  useEffect(() => {
    const tick = () => {
      setTime(prevTime => {
        const currentTime = prevTime - interval / 1000;

        if (onTick) {
          onTick(currentTime);
        }

        if (currentTime <= 0) {
          stopTimer();
          if (onFinish) {
            onFinish();
          }
          clearInterval(timer);
          return 0;
        }

        return currentTime;
      });
    };

    timer = setInterval(tick, interval);

    return () => {
      clearInterval(timer);
    };
  }, [initialTime, interval, onTick, onFinish]);

  const stopTimer = useCallback(() => {
    setTime(0);
  }, []);

  const resetTimer = useCallback(() => {
    setTime(initialTime);
  }, [initialTime]);

  return {time, stopTimer, resetTimer};
};

export default useTimer;
