/* eslint-disable no-constant-binary-expression */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import "./ProductDetail.style.css";
import {useLocation, useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import {getProductThunk} from "../../../features/Inventory/InventorySlice";
import Loader from "../../../Loader/Loader";
import {convertIntoINR, findByKey} from "../../../utils/helpers";
import Carousel from "../../../components/mobile/Carousel/Carousel";
import SelectAddress from "../../../components/mobile/SelectAddress/SelectAddress";
import cookieUtils from "../../../utils/cookies";
import {TiStarFullOutline} from "react-icons/ti";
import Slider from "../../../components/Slider/Slider";
import {colourCode} from "../../../constants/colourCode";
import Accordion from "../../../components/Accordion/Accordion";
import {Link} from "react-router-dom";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {
  ProductById,
  ProductPricingDetail,
} from "../../../features/Inventory/InventoryInterface";
import {AddProductToCartPayload} from "../../../features/Cart/CartInterface";
import {addProductToCartThunk} from "../../../features/Cart/CartSlice";

const ProductDetail: React.FC = () => {
  const [product, setProduct] = useState<ProductById | null>(null);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(-1);
  const [variantValues, setVariantValues] = useState<{[key: string]: any}>({});
  const [selectedVariantValue, setSelectedVariantValue] = useState<{
    [key: string]: string | number;
  }>({});
  const [isBuyNowInitiated, setIsBuyNowInitiated] = useState<boolean>(false);

  const {storeFront} = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{id: string}>();
  const location = useLocation();
  const [addToCart, setAddToCart] = useState<boolean>(false);
  const {loading} = useSelector((state: RootState) => state.cart);

  const streamId = location?.state?.stream_id;

  const {isSignedIn} = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      fetchProduct();
    }
  }, [params.id]);

  useEffect(() => {
    if (product) {
      populateVariants();
    }
  }, [product]);

  useEffect(() => {
    if (
      product &&
      selectedVariantValue &&
      Object.keys(selectedVariantValue).length
    ) {
      onChangeSelectedVariantValue();
    }
  }, [product, selectedVariantValue]);

  const fetchProduct = async () => {
    try {
      const payload = {
        product_id: params.id ?? "",
      };
      const action = await dispatch(getProductThunk(payload));

      if (getProductThunk.fulfilled.match(action)) {
        if (action?.payload?.product) {
          const temp = action.payload.product;
          if (temp) {
            const tempProductPricingDetails: ProductPricingDetail[] = [];

            temp.productPricingDetails?.forEach((variant, index) => {
              tempProductPricingDetails.push({
                ...variant,
                product_price: variant.product_price,
                cost_price: variant.product_price,
                selling_price: variant.selling_price,
                varient_id: variant.id,
              });

              const defaultVariant = temp?.productPricingDetails[0];
              if (
                defaultVariant.variantValueDetails &&
                defaultVariant.variantValueDetails.length > 0
              ) {
                const tempSelectedVariantValue: {
                  [key: string]: string | number;
                } = {};

                defaultVariant.variantValueDetails.map(variantValue => {
                  if (variantValue?.varientCategory?.[0]?.varient_name) {
                    tempSelectedVariantValue[
                      variantValue?.varientCategory?.[0]?.varient_name
                    ] = variantValue.varient_value;
                  }
                });

                setSelectedVariantValue(tempSelectedVariantValue);
                setSelectedVariantIndex(index);
              }
            });

            temp["productPricingDetails"] = tempProductPricingDetails;

            setProduct(temp);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const populateVariants = () => {
    const tempVariants: {[key: string]: any} = {};
    product?.productPricingDetails?.forEach(variant =>
      variant.variantValueDetails?.forEach(variantValue => {
        if (variantValue?.varientCategory?.[0]?.varient_name) {
          if (tempVariants[variantValue?.varientCategory?.[0]?.varient_name]) {
            const ob = findByKey(
              tempVariants[variantValue?.varientCategory?.[0]?.varient_name],
              "variant_value_id",
              variantValue.id,
            );

            if (!ob) {
              tempVariants[
                variantValue?.varientCategory?.[0]?.varient_name
              ].push({
                name: variantValue.varient_name,
                value: variantValue.varient_value,
                variant_value_id: variantValue.id,
              });
            }
          } else {
            tempVariants[variantValue?.varientCategory?.[0]?.varient_name] = [
              {
                name: variantValue.varient_name,
                value: variantValue.varient_value,
                variant_value_id: variantValue.id,
              },
            ];
          }
        }
      }),
    );

    setVariantValues(tempVariants);
  };

  console.log("variant", variantValues);

  const onChangeSelectedVariantValue = () => {
    const findVariantIndex = (data: any, selectedVariantValue: any) => {
      return data.findIndex((item: any) => {
        // Check if all variant values match
        return item.variantValueDetails.every((variant: any) => {
          const categoryName = variant.varientCategory[0].varient_name;
          const value = variant.varient_value;

          // Match against the selected values
          return selectedVariantValue[categoryName] === value;
        });
      });
    };

    const index = findVariantIndex(
      product?.productPricingDetails,
      selectedVariantValue,
    );

    setSelectedVariantIndex(index);
  };

  const addProductTocart = async () => {
    const payload: AddProductToCartPayload = {
      product_id: product?.id.toString() ?? "",
      product_pricing_details_id:
        product?.["productPricingDetails"]?.[
          selectedVariantIndex
        ].varient_id?.toString() ?? "",
      quantity: "1",
    };

    try {
      const action = await dispatch(addProductToCartThunk(payload));
      if (addProductToCartThunk.fulfilled.match(action)) {
        setAddToCart(true);
        // setLoading(false)
      } else {
        setAddToCart(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  if (!product) {
    return <Loader />;
  }

  return (
    <div style={{maxWidth: "1000px", alignSelf: "center"}}>
      <div className="product-details">
        <div className="product-details-seller-details">
          <div
            className="product-details-seller-details-icon"
            onClick={() => {
              console.log("clicked");
              navigate(`/storefront/${product.store_id}`);
            }}>
            <img
              alt="StoreThumbnail"
              className="product-details-store-image"
              src={
                storeFront?.details?.profile_url
                  ? storeFront?.details?.profile_url
                  : "/avatar.png"
              }
            />
          </div>
          <div className="product-details-seller-details-content">
            <div className="seller-details-1">{product?.store_name}</div>
          </div>
          {false && <button className="follow-button">Follow</button>}
        </div>
        <Carousel jumpToIndex={0} showIndicator={true}>
          {product?.productImageDetails?.map((image, index) => (
            <div key={index} className="product-img-container">
              <div key={index}>
                <img
                  alt="Thumbnail"
                  className="productImage"
                  // style={{ width: "100%", height: "10%" }}
                  src={image ?? "/avatar.png"}
                />
              </div>
            </div>
          ))}
        </Carousel>
        <div className="product-details-content-container">
          <div className="product-header-container">
            <div className="product-header-left">
              <div className="product-header-title">
                {product?.product_name}
              </div>
              {false && (
                <div className="product-header-rediv">
                  {product?.product_description}
                </div>
              )}
            </div>
            <div className="product-price-container">
              {selectedVariantValue &&
              Object.keys(selectedVariantValue).length &&
              selectedVariantIndex != -1 ? (
                <React.Fragment>
                  <div className="product-details-price">
                    <div className="product-details-selling-price">
                      ₹
                      {convertIntoINR(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["selling_price"],
                      )}
                    </div>
                    {parseInt(
                      product?.productPricingDetails?.[selectedVariantIndex]?.[
                        "product_price"
                      ],
                    ) -
                      parseInt(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["selling_price"],
                      ) !=
                    0 ? (
                      <div className="product-details-discount-price">
                        ₹
                        {convertIntoINR(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"],
                        )}
                      </div>
                    ) : (
                      <div />
                    )}

                    {parseInt(
                      product?.productPricingDetails?.[selectedVariantIndex]?.[
                        "product_price"
                      ],
                    ) -
                      parseInt(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["selling_price"],
                      ) !=
                    0 ? (
                      <div className="product-details-discount-percent">
                        {parseInt(
                          Math.ceil(
                            (100 *
                              (parseInt(
                                product?.productPricingDetails?.[
                                  selectedVariantIndex
                                ]?.["product_price"]?.toString(),
                              ) -
                                parseInt(
                                  product?.productPricingDetails?.[
                                    selectedVariantIndex
                                  ]?.["selling_price"]?.toString(),
                                ))) /
                              parseInt(
                                product?.productPricingDetails?.[
                                  selectedVariantIndex
                                ]?.["product_price"]?.toString(),
                              ),
                          ).toString(),
                        )}
                        {`% off`}
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <span>Select Variant</span>
              )}
            </div>
          </div>
        </div>

        {variantValues &&
          Object.keys(variantValues).length > 0 &&
          !variantValues?.["No Variant"] && (
            <div className="product-details-size-box">
              {Object.entries(variantValues).map(
                ([variantCategory, variantCategoryDetail], index) => {
                  return (
                    <div
                      key={index}
                      style={{marginBottom: "5%", marginLeft: "2%"}}>
                      <span className="product-details-variant-label">
                        {variantCategory}
                      </span>
                      <div className="product-details-size-selector">
                        <br />
                        <div className="product-details-size-selector-list">
                          {variantCategory === "Colour" ? (
                            <Slider
                              options={variantCategoryDetail?.map(
                                (variantCategoryValue: any) => {
                                  return {
                                    id: variantCategoryValue.name,
                                    value:
                                      colourCode?.[variantCategoryValue.name] ??
                                      variantCategoryValue.name,
                                  };
                                },
                              )}
                              renderOption={(option, isSelected) => (
                                <div
                                  className="color-option"
                                  style={{
                                    backgroundColor: option.value,
                                    border: isSelected
                                      ? "2px solid #FFF"
                                      : "none",
                                    outline: isSelected
                                      ? "2px solid black"
                                      : "none",
                                  }}
                                />
                              )}
                              onSelect={(selectedOption: any) => {
                                setSelectedVariantValue({
                                  ...selectedVariantValue,
                                  [variantCategory]: selectedOption.id,
                                });
                              }}
                            />
                          ) : (
                            <React.Fragment>
                              <Slider
                                options={variantCategoryDetail.map(
                                  (variantCategoryValue: any) => {
                                    return {
                                      id: variantCategoryValue.name,
                                      value: variantCategoryValue.name,
                                    };
                                  },
                                )}
                                renderOption={(
                                  option: any,
                                  isSelected: boolean,
                                ) => (
                                  <div
                                    className="size-option"
                                    style={{
                                      backgroundColor: isSelected
                                        ? "#1E799C"
                                        : "#ffffff",
                                      color: isSelected ? "#ffffff" : "#000000",
                                    }}>
                                    {option.value}
                                  </div>
                                )}
                                onSelect={selectedOption => {
                                  console.log(
                                    "selected variable :::",
                                    selectedOption,
                                  );
                                  setSelectedVariantValue({
                                    ...selectedVariantValue,
                                    [variantCategory]: selectedOption.id,
                                  });
                                }}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          )}
        <div className="product-details-accordion-container">
          <div className="product-details-title-container">
            <div
              style={{
                width: "40%",
                border: "1px solid #E5E7EB",
              }}></div>
            <div
              style={{
                margin: "auto",
                //styleName: Label/Medium/Semi Bold;
                fontFamily: "Plus Jakarta Sans",
                fontSize: "14px",
                fontWeight: "600",
                // line-height: 16px;
                // text-align: left;
              }}>
              Details
            </div>
            <div
              style={{
                width: "40%",
                border: "1px solid #E5E7EB",
              }}></div>
          </div>
          <div className="product-details-accordion-container-item">
            <Accordion
              accordionData={[
                {
                  title: "Product Details",
                  content: (
                    <p>
                      {" "}
                      {product?.product_description
                        .split("\n")
                        .map((line, index) => {
                          // Check if the line is empty and if the previous line was also empty, indicating two consecutive newlines
                          const isIndented = line === "";
                          return (
                            <p
                              key={index}
                              style={{marginBottom: isIndented ? "20px" : "0"}}>
                              {line}
                            </p>
                          );
                        })}
                    </p>
                  ),
                },
                {
                  title: "Seller Details",
                  content: (
                    <div className="product-details-store-info">
                      {storeFront?.details?.store_id && (
                        <button
                          className="product-detail"
                          onClick={() => {
                            navigate(
                              `/storefront/${storeFront.details?.store_id}`,
                            );
                          }}>
                          <span className="product-detail-text">
                            Visit Storefront
                          </span>
                        </button>
                      )}
                    </div>
                  ),
                },

                {
                  title: "Shipping Policy",
                  content: (
                    <p className="product-details-return-policy">
                      The seller is responsible for the shipment of the sold
                      items to the customer. We urge all sellers to ship their
                      products within 5-7 days of receiving an order. Once the
                      order is picked up from the seller, we rely on our courier
                      partners to deliver the order at the earliest to the
                      customer. The order is usually delivered between 3-5 days
                      post pickup from the seller. We are not responsible for
                      any delays caused by our courier partners. You can read
                      their terms{" "}
                      <Link
                        to="https://checkout.shiprocket.in/terms-conditions/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-details-link">
                        here
                      </Link>{" "}
                      . We are not liable for any damages (including, without
                      limitation, any incidental or consequential damages)
                      arising from the failure of a seller or any shipping
                      carrier, or a contractor engaged by the seller or shipping
                      carrier to deliver.
                    </p>
                  ),
                },
                {
                  title: "Return/Exchange Policy",
                  content: (
                    <p className="product-details-return-policy">
                      We are committed to ensuring a trustworthy buying
                      experience for everyone. So in those rare circumstances
                      where you have an issue with your order, we have got your
                      back. However, considering the nature of listings from our
                      sellers, we will be able to offer exchange, returns or
                      refund only in rare scenarios. Our &apos;Buyer Protection
                      Policy&apos; goes into more detail below on when buyers
                      are entitled to a refund and how to get a refund. We urge
                      you to carefully read the same available on our{" "}
                      <Link
                        to="https://seller.zooplive.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-details-link">
                        website{" "}
                      </Link>
                      . In case of any dispute, we collect information and
                      evidence from both the buyer and seller. A balanced
                      decision is then taken in light of the facts, our
                      guidelines and policies. For any queries or grievances,
                      write to us:{" "}
                      <Link
                        to="mailto:help@zooplive.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="product-details-link">
                        help@zooplive.com
                      </Link>{" "}
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </div>

        {false && (
          <div className="delivery-container">
            <div className="title-container">
              <div
                style={{
                  width: "28%",
                  border: "1px solid #E5E7EB",
                }}></div>
              <div
                style={{
                  margin: "auto",
                  //styleName: Label/Medium/Semi Bold;
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "14px",
                  fontWeight: "600",
                  // line-height: 16px;
                  // text-align: left;
                }}>
                Check Delivery
              </div>
              <div
                style={{
                  width: "28%",
                  border: "1px solid #E5E7EB",
                }}></div>
            </div>
            <div className="input-group">
              <input type="text" placeholder="Enter delivery pincode" />
              <button className="check-button">Check</button>
            </div>
            <div className="features">
              <div className="feature">
                <div className="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                  </svg>
                </div>
                <p>Refund in 48h</p>
              </div>
              <div className="feature">
                <div className="feature-icon">
                  {/* <img src="./public/faster-return.svg"/> */}
                </div>
                <p>Faster Return</p>
              </div>
              <div className="feature">
                <div className="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z" />
                  </svg>
                </div>
                <p>Buyer protection</p>
              </div>
            </div>
          </div>
        )}

        {false && (
          <div className="rediv-container">
            <div className="title-container">
              <div
                style={{
                  width: "35%",
                  border: "1px solid #E5E7EB",
                }}></div>
              <div
                style={{
                  margin: "auto",
                  //styleName: Label/Medium/Semi Bold;
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "14px",
                  fontWeight: "600",
                  // line-height: 16px;
                  // text-align: left;
                }}>
                Redivs
              </div>
              <div
                style={{
                  width: "35%",
                  border: "1px solid #E5E7EB",
                }}></div>
            </div>
            <div className="rating-container" style={{alignItems: "center"}}>
              <TiStarFullOutline
                style={{color: "black", width: "1.5rem", height: "1.5rem"}}
              />
              <div className="details-rating-content">
                <div className="rating" style={{fontSize: "16px"}}>
                  4.2
                </div>
                <div className="rating" style={{fontSize: "14px"}}>
                  (173)
                </div>
              </div>
            </div>
            <div className="rediv-images"></div>

            <div className="user-redivs"></div>
          </div>
        )}
        <div className="product-detail-button-container">
          {!location?.state?.hide_buy_now && (
            <>
              <button
                className="product-detail-button-add-cart"
                disabled={selectedVariantIndex == -1}
                onClick={() => {
                  if (isSignedIn) {
                    if (addToCart) {
                      navigate("/checkout");
                    } else {
                      addProductTocart();
                    }
                  } else {
                    navigate("/login");
                  }
                }}>
                <span
                  className="product-detail-text"
                  style={{color: "#1e799c"}}>
                  {loading ? <Loader /> : ""}
                  {addToCart ? "Go to Cart" : "Add to Cart"}
                </span>
              </button>

              <button
                className="product-detail"
                disabled={selectedVariantIndex == -1}
                onClick={() => {
                  if (isSignedIn) {
                    if (streamId) {
                      navigate(-1);
                    } else {
                      setIsBuyNowInitiated(true);
                    }
                  } else {
                    navigate("/login");
                  }
                }}>
                <span className="product-detail-text">
                  {streamId ? "Back to Stream" : "Buy Now"}
                </span>
              </button>
            </>
          )}
        </div>

        <SelectAddress
          {...(streamId ? {streamId: streamId} : {})}
          storeId={product.store_id}
          open={isBuyNowInitiated && product ? true : false}
          onClose={() => {
            setIsBuyNowInitiated(false);
          }}
          product={
            selectedVariantIndex != -1 &&
            product?.["productPricingDetails"]?.[selectedVariantIndex]
              ? product?.["productPricingDetails"]?.[selectedVariantIndex]
              : {}
          }
        />
      </div>
    </div>
  );
};

export default ProductDetail;
