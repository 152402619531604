import * as React from "react";

export interface LoginUserProps {
  width: number;
  height: number;
  color?: string;
}

export const LoginUserIcon = function LoginUser(props: LoginUserProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 7.69141C7.0375 7.69141 6.21358 7.34874 5.52825 6.66341C4.84275 5.97791 4.5 5.15391 4.5 4.19141C4.5 3.22891 4.84275 2.40499 5.52825 1.71966C6.21358 1.03416 7.0375 0.691406 8 0.691406C8.9625 0.691406 9.78642 1.03416 10.4718 1.71966C11.1573 2.40499 11.5 3.22891 11.5 4.19141C11.5 5.15391 11.1573 5.97791 10.4718 6.66341C9.78642 7.34874 8.9625 7.69141 8 7.69141ZM0.5 13.7877V13.0837C0.5 12.594 0.633 12.1405 0.899 11.7232C1.165 11.3058 1.5205 10.985 1.9655 10.7607C2.95383 10.2762 3.95092 9.91274 4.95675 9.67041C5.96258 9.42807 6.977 9.30691 8 9.30691C9.023 9.30691 10.0374 9.42807 11.0433 9.67041C12.0491 9.91274 13.0462 10.2762 14.0345 10.7607C14.4795 10.985 14.835 11.3058 15.101 11.7232C15.367 12.1405 15.5 12.594 15.5 13.0837V13.7877C15.5 14.2093 15.3523 14.568 15.0568 14.8637C14.7613 15.1592 14.4026 15.3069 13.9808 15.3069H2.01925C1.59742 15.3069 1.23875 15.1592 0.94325 14.8637C0.64775 14.568 0.5 14.2093 0.5 13.7877ZM2 13.8069H14V13.0837C14 12.8812 13.9413 12.6937 13.824 12.5212C13.7067 12.3488 13.5474 12.2082 13.3462 12.0992C12.4846 11.6748 11.6061 11.3533 10.7107 11.1347C9.81525 10.9162 8.91167 10.8069 8 10.8069C7.08833 10.8069 6.18475 10.9162 5.28925 11.1347C4.39392 11.3533 3.51542 11.6748 2.65375 12.0992C2.45258 12.2082 2.29333 12.3488 2.176 12.5212C2.05867 12.6937 2 12.8812 2 13.0837V13.8069ZM8 6.19141C8.55 6.19141 9.02083 5.99557 9.4125 5.60391C9.80417 5.21224 10 4.74141 10 4.19141C10 3.64141 9.80417 3.17057 9.4125 2.77891C9.02083 2.38724 8.55 2.19141 8 2.19141C7.45 2.19141 6.97917 2.38724 6.5875 2.77891C6.19583 3.17057 6 3.64141 6 4.19141C6 4.74141 6.19583 5.21224 6.5875 5.60391C6.97917 5.99557 7.45 6.19141 8 6.19141Z"
        fill="white"
      />
    </svg>
  );
};
