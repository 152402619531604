import React, {useState, useRef, TouchEvent} from "react";

// Define the types for each tab, including label and content
interface Tab {
  label: string;
  content: React.ReactNode;
  hide?: boolean;
}

// Define the props for the DynamicTabs component
interface DynamicTabsProps {
  tabs: Tab[];
  scrollable?: boolean;
}

const DynamicTabs: React.FC<DynamicTabsProps> = ({tabs, scrollable = true}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  // Swipe gesture tracking
  const touchStartX = useRef<number>(0);
  const touchEndX = useRef<number>(0);
  const touchStartY = useRef<number>(0);
  const touchEndY = useRef<number>(0);

  const minSwipeDistance = 50;
  const maxVerticalSwipeDistance = 30;

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    touchEndX.current = e.touches[0].clientX;
    touchEndY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = () => {
    const horizontalDistance = touchStartX.current - touchEndX.current;
    const verticalDistance = touchStartY.current - touchEndY.current;

    if (Math.abs(verticalDistance) < maxVerticalSwipeDistance) {
      if (
        horizontalDistance > minSwipeDistance &&
        activeTabIndex < tabs.length - 1
      ) {
        setActiveTabIndex(activeTabIndex + 1);
      } else if (horizontalDistance < -minSwipeDistance && activeTabIndex > 0) {
        setActiveTabIndex(activeTabIndex - 1);
      }
    }

    touchStartX.current = 0;
    touchEndX.current = 0;
    touchStartY.current = 0;
    touchEndY.current = 0;
  };

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div style={styles.container}>
      <div style={styles.tabHeader}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            style={{
              ...styles.tabButton,
              ...(activeTabIndex === index ? styles.activeTab : {}),
              display: tab.hide ? "none" : "initial",
            }}
            onClick={() => handleTabClick(index)}>
            {tab.label}
          </button>
        ))}
      </div>
      <div
        style={{
          ...styles.tabContent,
          overflow: scrollable ? "scroll" : "hidden", // Conditionally apply scrolling
          maxHeight: scrollable ? "60vh" : "none",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
        {tabs[activeTabIndex]?.content}
      </div>
    </div>
  );
};

export default DynamicTabs;

// Styles
const styles = {
  container: {
    maxWidth: "100%",
    margin: "0 auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  tabHeader: {
    display: "flex",
    justifyContent: "space-around",
    borderBottom: "1px solid #ddd",
  },
  tabButton: {
    padding: "15px 0px",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "none",
    fontSize: "16px",
    fontColor: "#374151",
    transition: "color 0.3s ease",
    outline: "none",
    fontFamily: "Plus Jakarta Sans",
    width: "100%",
  },
  activeTab: {
    color: "#1e799c",
    borderBottom: "1.5px solid #1e799c",
    fontWeight: "500",
    fontFamily: "Plus Jakarta Sans",
  },
  tabContent: {
    animation: "fadeIn 0.5s ease-in-out",
    fontFamily: "Plus Jakarta Sans",
    overflow: "scroll",
  },
  "@keyframes fadeIn": {
    from: {opacity: 0},
    to: {opacity: 1},
  },
};
