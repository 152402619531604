import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
  addAddressThunk,
  getCityStateByPincodeThunk,
} from "../../../features/User/UserSlice";
import BottomSheet from "../BottomSheet/BottomSheet";
import {toast} from "react-toastify";
import "./AddAddress.style.css";
import {AppDispatch} from "../../../interceptors/store";

interface FooterProps {
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  disabled: boolean;
}

const Footer: React.FC<FooterProps> = ({
  onClose,
  onSubmit,
  loading,
  disabled,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: "3%",
        width: "90%",
        right: window.innerWidth > 700 ? "3%" : "",
        padding: "0.5rem 1.25rem",
        boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        background: "white",
        // maxWidth: "1000px",
      }}>
      <div className="add-address-action-wrapper">
        <button className="add-address-negative-button" onClick={onClose}>
          Discard
        </button>
      </div>
      <div className="add-address-action-wrapper">
        <button
          className="add-address-positive-button"
          onClick={onSubmit}
          disabled={disabled}>
          {loading ? <div className="payment-spinner" /> : <span>Save</span>}
        </button>
      </div>
    </div>
  );
};

interface AddAddressProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  addressDisabled: boolean;
  initialDeliveryAddress?: Address;
  type?: "add" | "edit";
}

export interface Address {
  id?: string | number;
  address_line_1: string;
  address_line_2: string;
  pincode: string;
  state: string;
  city: string;
  save_as: string;
}

export const defaultDeliveryAddress: Address = {
  address_line_1: "",
  address_line_2: "",
  pincode: "",
  state: "",
  city: "",
  save_as: "",
};

const AddAddress: React.FC<AddAddressProps> = ({
  open,
  onClose,
  onSuccess,
  addressDisabled,
  initialDeliveryAddress = defaultDeliveryAddress,
  type = "add",
}) => {
  const [deliveryAddress, setDeliveryAddress] = useState<Address>(
    initialDeliveryAddress,
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    checkDisability();
  }, [deliveryAddress]);

  useEffect(() => {
    if (
      deliveryAddress.pincode &&
      deliveryAddress.pincode.toString().length === 6
    ) {
      fetchCityState();
    }
  }, [deliveryAddress.pincode]);

  const checkDisability = () => {
    const isDisabled = Object.entries(deliveryAddress).some(([key, value]) => {
      if (!value) return true;
      if (key === "pincode" && value.toString().length !== 6) return true;
      return false;
    });
    setDisabled(isDisabled);
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: keyof Address,
  ) => {
    const updatedValue = event.target.value;
    setDeliveryAddress(prev => ({
      ...prev,
      [id]: updatedValue,
      ...(id === "pincode" && updatedValue.length !== 6
        ? {city: "", state: ""}
        : {}),
    }));
  };

  const fetchCityState = async () => {
    try {
      const payload = {pincode: deliveryAddress.pincode};
      const action = await dispatch(getCityStateByPincodeThunk(payload));
      if (getCityStateByPincodeThunk.fulfilled.match(action)) {
        const data = action.payload;
        if (data && data[0]) {
          setDeliveryAddress(prev => ({
            ...prev,
            city: data[0].city,
            state: data[0].state,
          }));
        } else {
          toast.error("Invalid pincode");
        }
      } else {
        toast.error("Invalid pincode");
      }
    } catch {
      toast.error("Error while validating pincode");
    }
  };

  const onSubmit = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      user_address_details: {
        address_name: deliveryAddress.save_as,
        address_details_line1: deliveryAddress.address_line_1,
        address_details_line2: deliveryAddress.address_line_2,
        pincode: deliveryAddress.pincode,
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        country: "india",
        address_type: "DELIVERY",
      },
    };

    if (deliveryAddress?.id) {
      payload["user_address_details"]["address_id"] =
        deliveryAddress?.id ?? null;
    }

    setLoading(true);
    try {
      const action = await dispatch(addAddressThunk(payload));
      if (addAddressThunk.fulfilled.match(action)) {
        onSuccess();
      } else {
        toast.error(
          `Error while ${type === "edit" ? "updating" : "adding"} address`,
        );
      }
    } catch (error) {
      console.error(
        `Error ${type === "edit" ? "updating" : "adding"} address:`,
        error,
      );
    }
    setLoading(false);
  };

  return (
    <BottomSheet
      header={`${type === "edit" ? "Edit" : "Add new"} address`}
      isOpen={open}
      onClose={onClose}
      footer={
        <Footer
          onClose={onClose}
          onSubmit={onSubmit}
          loading={loading}
          disabled={disabled || addressDisabled}
        />
      }>
      <div className="add-address-container">
        <div className="add-address-input-container">
          {["address_line_1", "address_line_2", "pincode", "save_as"].map(
            field => (
              <div key={field} className="add-address-input-wrapper">
                <label className="add-address-input-label">
                  {field
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, l => l.toUpperCase())}
                </label>
                <input
                  className="add-address-input"
                  type="text"
                  id={field}
                  placeholder={field
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, l => l.toUpperCase())}
                  value={deliveryAddress[field as keyof Address] || ""}
                  onChange={event => onChange(event, field as keyof Address)}
                />
              </div>
            ),
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            {["state", "city"].map(field => (
              <div
                key={field}
                className="add-address-input-wrapper"
                style={{width: "45%"}}>
                <label className="add-address-input-label">
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </label>
                <input
                  className="add-address-input"
                  type="text"
                  id={field}
                  placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={deliveryAddress[field as keyof Address] || ""}
                  onChange={event => onChange(event, field as keyof Address)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default AddAddress;
