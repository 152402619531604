import React from "react";
import {useEffect, useState} from "react";
import {getOrdersThunk} from "../../../features/Order/OrderSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import cookieUtils from "../../../utils/cookies";
import "./OrdersPage.style.css";
import OrdersPageCard from "./components/OrdersPageCard/OrdersPageCard";
import OrdersPageCardSkeleton from "./components/Skeleton/OrdersPageCardSkeleton";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {OrderItem} from "../../../features/Order/OrderInterface";

const OrdersPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  const [itemsPerPage] = useState<number>(5);

  // UseSelector to get the orderItems from the Redux store
  const {orderItems} = useSelector((state: RootState) => state.order);

  const totalItems = orderItems.count; // Total number of orders
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      // Dispatch the async thunk to fetch orders
      try {
        await dispatch(
          getOrdersThunk({
            page: currentPage,
            limit: itemsPerPage,
            order_type: "desc",
          }),
        );
      } catch (error) {
        console.log("error", error);
      }
      setLoading(false);
    };

    fetchOrders();
  }, [dispatch, currentPage, itemsPerPage]);

  const handleCardClick = (orderId: string) => {
    navigate(`/orders/${orderId}`);
  };

  return (
    <div>
      <div className="order-list-heading">My Orders</div>

      {loading ? (
        // Skeleton loader while loading the orders
        Array.from({length: 1}).map((_, index) => (
          <OrdersPageCardSkeleton key={`stream-${index}`} />
        ))
      ) : (
        <div style={{marginTop: "5%"}}>
          {orderItems.data.length > 0 ? (
            orderItems.data.map((order: OrderItem) => (
              <div
                key={order.id}
                onClick={() => handleCardClick(order?.order_id)}>
                <OrdersPageCard
                  key={order.id}
                  productName={order.product_name}
                  productDescription={order.product_description}
                  productQuantity={order.quantity}
                  productImage={order?.product_image_details?.[0]}
                  orderStatus={order.status || "Unknown"}
                  statusDate={order.status_date}
                  variants={order.variants}
                />
              </div>
            ))
          ) : (
            <div className="Orders-Page-No-Orders">No orders found.</div>
          )}
        </div>
      )}
      {/* {orderItems.count > 0 && (
        <div className="pagination-container">
          <div className="pagination-content">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(prevPage => prevPage - 1)}>
              Prev
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(prevPage => prevPage + 1)}>
              Next
            </button>
          </div>
        </div>
      )} */}

      {/* Pagination Controls */}
      <div style={{paddingBottom: "3%"}}>
        {orderItems.count > 0 && (
          <div className="storefront-pagination-container">
            <button
              className="storefront-pagination-arrow"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev: number) => prev - 1)}>
              &#8249;
            </button>
            <div className="storefront-pagination-numbers">
              Page
              <span
                className={`storefront-pagination-number ${currentPage === 1 ? "active" : ""}`}
                onClick={() => setCurrentPage(1)}>
                1
              </span>
              {currentPage > 3 && (
                <span className="storefront-pagination-dots">...</span>
              )}
              {currentPage > 2 && (
                <span
                  className="storefront-pagination-number"
                  onClick={() => setCurrentPage(currentPage - 1)}>
                  {currentPage - 1}
                </span>
              )}
              {currentPage !== 1 && currentPage !== totalPages && (
                <span className="storefront-pagination-number active">
                  {currentPage}
                </span>
              )}
              {currentPage < totalPages - 1 && (
                <span
                  className="storefront-pagination-number"
                  onClick={() => setCurrentPage(currentPage + 1)}>
                  {currentPage + 1}
                </span>
              )}
              {currentPage < totalPages - 2 && (
                <span className="storefront-pagination-dots">...</span>
              )}
              {totalPages > 1 && (
                <span
                  className={`storefront-pagination-number ${
                    currentPage === totalPages ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(totalPages)}>
                  {totalPages}
                </span>
              )}
            </div>
            <button
              className="storefront-pagination-arrow"
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev: number) => prev + 1)}>
              &#8250;
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdersPage;
