import React from "react";
import "./Stores.css";
import {useSelector} from "react-redux";
import {RootState} from "../../interceptors/store";
import {useNavigate} from "react-router";
// import {Hashtag} from "../SvgImages/Hashtag/Hashtag";

const StoresCardSection = () => {
  const {featuredStores} = useSelector((state: RootState) => state.user);

  // console.log("streams :::", featuredStores)

  return (
    <>
      <div>
        <div className="store-cards-heading">
          {/* <span id="recommended-hastag">
            <Hashtag height={50} width={50} />
          </span> */}
          <span style={{color: "#9ca3af", marginLeft: "10px"}}>
            Explore our
          </span>
          <span style={{color: "#F19623"}}>stores</span>
        </div>
      </div>

      <div className="store-cards-container">
        {featuredStores.map((item, index) => (
          <div className="store-card" key={index}>
            <StoreCard
              coverImage={item.feature_media_details[1].image_url}
              logo={item.feature_media_details[0].image_url}
              // rating={4.2}
              tagLine={item.tag_line}
              // reviews={173}
              storeName={item?.seller_name}
              storeId={item?.key_value}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default StoresCardSection;

interface StoreCardProps {
  coverImage: string;
  logo: string; // URL of the product image
  discount?: number; // Discount percentage (optional)
  tagLine: string;
  // rating: string; // Rating value
  // reviews?: number; // Number of reviews
  storeName: string; // Product name
  storeId: string; // Product name
}

const StoreCard: React.FC<StoreCardProps> = ({
  coverImage,
  logo,
  // rating,,
  tagLine,
  storeName,
  storeId,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="recommended-store-card"
      onClick={() => {
        navigate(`/storefront/${storeId}`);
      }}>
      <div className="recommended-store-card-image">
        <div className="coverImage">
          <img src={coverImage} alt={storeName} />
        </div>

        <div className="recommended-store-card-content">
          <div style={{height: "50px"}} className="store-logo">
            <img src={logo} alt={storeName} />
          </div>

          <div className="recommended-store-card-rating">
            <div className="recommended-store-name">{storeName}</div>
            <div className="recommended-store-card-rating-value">{tagLine}</div>
            {/* <div className="recommended-store-card-rating-value">⭐ {rating}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
