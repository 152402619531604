import React from "react";

export interface EditSvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

export const EditSvg = function EditSvg(props: EditSvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 19 19"
      fill="none">
      <path
        d="M1.70804 18.8997C1.25791 18.8997 0.877425 18.7443 0.566592 18.4335C0.255758 18.1227 0.100342 17.7422 0.100342 17.292V4.7075C0.100342 4.2505 0.254508 3.86413 0.562842 3.5484C0.871175 3.23266 1.25291 3.08313 1.70804 3.0998H10.0888L8.78882 4.39977H1.70804C1.63111 4.39977 1.56058 4.43182 1.49647 4.49592C1.43237 4.56004 1.40032 4.63056 1.40032 4.7075V17.292C1.40032 17.369 1.43237 17.4395 1.49647 17.5036C1.56058 17.5677 1.63111 17.5998 1.70804 17.5998H14.2926C14.3695 17.5998 14.4401 17.5677 14.5042 17.5036C14.5683 17.4395 14.6003 17.369 14.6003 17.292V10.1728L15.9003 8.87285V17.292C15.9003 17.7422 15.7449 18.1227 15.434 18.4335C15.1232 18.7443 14.7427 18.8997 14.2926 18.8997H1.70804ZM6.10034 12.1005V10.5733C6.10034 10.3602 6.13881 10.1549 6.21574 9.9575C6.29266 9.76006 6.41033 9.58213 6.56874 9.4237L14.835 1.1575C14.9734 1.01903 15.1189 0.922554 15.2715 0.868071C15.424 0.813588 15.584 0.786346 15.7513 0.786346C15.9208 0.786346 16.0838 0.813588 16.2404 0.868071C16.397 0.922554 16.5408 1.01401 16.6719 1.14245L17.8041 2.2498C17.949 2.38825 18.0519 2.54008 18.1128 2.7053C18.1737 2.8705 18.2041 3.03788 18.2041 3.20745C18.2041 3.37743 18.1751 3.54202 18.117 3.70122C18.059 3.8604 17.9611 4.00863 17.8234 4.1459L9.52832 12.4409C9.36988 12.5994 9.19237 12.7154 8.99577 12.7892C8.79918 12.8629 8.59463 12.8997 8.38212 12.8997H6.90874C6.68136 12.8997 6.48978 12.8235 6.33402 12.6711C6.17823 12.5188 6.10034 12.3285 6.10034 12.1005ZM7.40032 11.5998H8.52147L14.7292 5.39205L14.1811 4.81897L13.5888 4.25167L7.40032 10.4402V11.5998Z"
        fill="black"
      />
    </svg>
  );
};
