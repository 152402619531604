import React from "react";

export interface PopBellIconProps {
  height: number | string;
  width: number | string;
  color?: string;
}

export const PopupBellIcon = function BellIcon(props: PopBellIconProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2382_9856)">
        <path
          d="M24.4829 47.7291C27.208 46.8054 28.6683 43.8475 27.7446 41.1224C26.8209 38.3973 23.8629 36.937 21.1378 37.8607C18.4127 38.7844 16.9524 41.7423 17.8761 44.4674C18.7999 47.1925 21.7578 48.6528 24.4829 47.7291Z"
          fill="#FFE266"
        />
        <path
          d="M22.8206 37.5791C22.4889 37.5791 22.1648 37.6114 21.8504 37.6707C23.3763 38.0276 24.5131 39.3958 24.5131 41.0305C24.5131 42.9367 22.9679 44.4819 21.0617 44.4819C19.427 44.4819 18.0587 43.3451 17.702 41.8192C17.6427 42.1337 17.6104 42.4577 17.6104 42.7894C17.6104 45.667 19.9431 47.9997 22.8207 47.9997C25.6983 47.9997 28.0311 45.667 28.0311 42.7894C28.031 39.9119 25.6982 37.5791 22.8206 37.5791Z"
          fill="#EFAA66"
        />
        <path
          d="M36.9218 1.30251C35.4776 0.468683 33.6309 0.963502 32.7971 2.40775L30.2952 6.74115C29.4614 8.1854 29.9562 10.0321 31.4004 10.8659C32.8447 11.6997 34.6913 11.2049 35.5252 9.76068L38.0271 5.42727C38.8609 3.98303 38.3661 2.13634 36.9218 1.30251Z"
          fill="#FFE266"
        />
        <path
          d="M35.4908 44.8871C33.4268 41.6138 40.5021 27.1285 40.5934 26.9435C44.4261 19.1769 41.4621 9.88488 33.9732 6.18928C26.4843 2.49368 17.3063 5.79393 13.4737 13.5606C13.2774 13.9584 6.46062 28.48 2.5415 28.6273L35.4908 44.8871Z"
          fill="#FFE266"
        />
        <path
          d="M33.9732 6.1897C33.1592 5.78794 32.3251 5.46913 31.4802 5.22949C35.4924 8.41881 36.8291 14.214 34.3876 19.1616C34.3516 19.2347 32.8193 22.3712 31.6619 25.6106C30.5072 28.8426 26.8085 30.3603 23.7308 28.8415L9.13171 21.6371C6.96865 25.1779 4.4218 28.5571 2.5415 28.6277L35.4908 44.8874C33.4268 41.6142 40.5021 27.1288 40.5934 26.9439C44.4261 19.1773 41.4621 9.8853 33.9732 6.1897Z"
          fill="#EFAA66"
        />
        <path
          d="M18.9489 14.1137C18.7343 14.1137 18.5183 14.0429 18.3385 13.8974C17.9214 13.56 17.8568 12.9484 18.1942 12.5313C19.1042 11.4064 20.179 10.4355 21.3888 9.64553C21.8378 9.35207 22.4399 9.47855 22.7331 9.92781C23.0265 10.377 22.9001 10.9789 22.4509 11.2722C21.4109 11.9513 20.4869 12.7859 19.7046 13.7531C19.5126 13.9905 19.2319 14.1137 18.9489 14.1137Z"
          fill="white"
        />
        <path
          d="M24.8786 10.0082C24.47 10.0082 24.0899 9.74847 23.9558 9.33914C23.7887 8.82936 24.0665 8.28063 24.5763 8.11355C24.9454 7.99252 25.325 7.88644 25.7043 7.79805C26.2267 7.67624 26.749 8.00106 26.8707 8.52357C26.9925 9.04597 26.6677 9.56828 26.1452 9.6901C25.821 9.76567 25.4967 9.8563 25.1814 9.95965C25.0809 9.99248 24.9789 10.0082 24.8786 10.0082Z"
          fill="white"
        />
        <path
          d="M32.8139 46.6015L2.60417 31.6937C1.43152 31.115 0.95001 29.6952 1.52866 28.5226L1.56674 28.4455C2.14539 27.2728 3.56515 26.7913 4.7378 27.37L34.9475 42.2778C36.1202 42.8564 36.6017 44.2762 36.023 45.4488L35.9849 45.526C35.4063 46.6987 33.9866 47.1802 32.8139 46.6015Z"
          fill="#FFE266"
        />
        <path
          d="M27.4574 44.93C27.3131 44.93 27.1665 44.8977 27.0282 44.8295L18.7238 40.7314C18.2427 40.494 18.0452 39.9116 18.2826 39.4304C18.5201 38.9494 19.1024 38.7517 19.5836 38.9892L27.888 43.0872C28.3691 43.3246 28.5666 43.907 28.3293 44.3882C28.1599 44.7311 27.8154 44.93 27.4574 44.93Z"
          fill="#D0844B"
        />
        <path
          d="M3.89187 9.5717L4.68976 11.8154C4.72395 11.9115 4.79156 11.9876 4.87694 12.0261L6.87031 12.9242C7.15191 13.0511 7.15191 13.4998 6.87031 13.6267L4.87694 14.5249C4.79156 14.5633 4.72395 14.6394 4.68976 14.7354L3.89187 16.9791C3.77919 17.2961 3.38044 17.2961 3.26776 16.9791L2.46987 14.7354C2.43568 14.6394 2.36807 14.5632 2.28269 14.5249L0.289327 13.6267C0.00772447 13.4999 0.00772447 13.0512 0.289327 12.9242L2.28269 12.0261C2.36807 11.9876 2.43568 11.9115 2.46987 11.8154L3.26776 9.5717C3.38044 9.25474 3.77909 9.25474 3.89187 9.5717Z"
          fill="#FEF2A8"
        />
        <path
          d="M13.7986 0.176305L14.3905 1.84056C14.4158 1.91186 14.466 1.9683 14.5293 1.99686L16.0079 2.66303C16.2169 2.75716 16.2169 3.08995 16.0079 3.18408L14.5293 3.85025C14.466 3.8788 14.4158 3.93524 14.3905 4.00654L13.7986 5.6708C13.715 5.90597 13.4193 5.90597 13.3357 5.6708L12.7438 4.00654C12.7184 3.93524 12.6683 3.8788 12.605 3.85025L11.1264 3.18408C10.9175 3.08995 10.9175 2.75716 11.1264 2.66303L12.605 1.99686C12.6683 1.9683 12.7184 1.91186 12.7438 1.84056L13.3357 0.176305C13.4193 -0.0587683 13.715 -0.0587683 13.7986 0.176305Z"
          fill="#FEF2A8"
        />
        <path
          d="M47.9022 8.15037C48.111 7.21118 47.519 6.28055 46.5798 6.07174C45.6406 5.86292 44.71 6.455 44.5011 7.39418C44.2923 8.33337 44.8844 9.264 45.8236 9.47282C46.7628 9.68163 47.6934 9.08955 47.9022 8.15037Z"
          fill="#FFE266"
        />
        <path
          d="M5.36777 5.54315C5.78284 5.54315 6.11932 5.20667 6.11932 4.79159C6.11932 4.37652 5.78284 4.04004 5.36777 4.04004C4.95269 4.04004 4.61621 4.37652 4.61621 4.79159C4.61621 5.20667 4.95269 5.54315 5.36777 5.54315Z"
          fill="#FFE266"
        />
      </g>
      <defs>
        <clipPath id="clip0_2382_9856">
          <rect width={props.width} height={props.height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
