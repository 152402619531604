import React, {useState} from "react";
import "./SearchBar.css";
import useMobileSize from "../../hooks/useMobileSize";
import SearchSvg from "../SvgImages/SearchSvg/SearchSvg";
import {useHits, useSearchBox} from "react-instantsearch";
import {Hit as AlgoliaHit} from "@algolia/client-search";
import {useNavigate} from "react-router";

interface SearchBarProps {
  placeholder?: string;
  onSearch?: (query: string) => void;
  isVisible: boolean;
}

// Type for the data structure of a hit
type MyHit = AlgoliaHit<{
  product_name: string;
  // image_url : string,
  // price : string
}>;

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search 'Tshirt'",
  onSearch,
}) => {
  const navigate = useNavigate();
  const {query, refine} = useSearchBox();
  const isMobile = useMobileSize(1000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState(query);

  const openModal = () => {
    navigate("/search");
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  // Handle keypress (Enter)
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      if (onSearch) onSearch(inputValue); // Call the parent's handler
      closeModal();
    }
  };

  // Update state when typing
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    refine(e.target.value);
  };

  const {items} = useHits();

  const refinedItems = items as unknown as MyHit[];

  const recommendAlgoliaSeach = (products: MyHit[]) => {
    return (
      <div className="recommended-items">
        {products.map((product, index) => (
          <div key={index} className="recommended-item">
            <p
              className="recommended-item-product-name"
              onClick={() => {
                closeModal();
                navigate(`/search?query=${product.product_name}`);
              }}>
              {product.product_name}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="search-bar">
          <span className="search-bar__icon" onClick={openModal}>
            <SearchSvg height={30} width={30} color="#ffffff" />
          </span>
        </div>
      ) : (
        <div className="search-bar" onClick={openModal}>
          <span className="search-bar__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="search-bar__svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1 0 4.35 4.35a7.5 7.5 0 0 0 12.3 12.3z"
              />
            </svg>
          </span>
          <input
            onFocus={() => openModal}
            type="text"
            className="search-bar__input"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
          />
        </div>
      )}
      {/* Modal for Search */}
      {isModalOpen && (
        <div className="search-modal">
          <div className="search-modal__header">
            <button className="search-modal__back" onClick={closeModal}>
              ←
            </button>
            <input
              type="text"
              className="search-modal__input"
              placeholder="Search for products"
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              autoFocus
            />
          </div>

          <div className="search-modal__suggestions">
            {/* <p>Popular Searches:</p> */}
            {refinedItems.length > 0 ? (
              recommendAlgoliaSeach(refinedItems) // Pass the items from Algolia search to the function
            ) : (
              <p>No results found</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
