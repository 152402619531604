import React, {Dispatch, SetStateAction} from "react";
import "./DeliveryAddressCart.css";
import {HomeSvg} from "../SvgImages/HomeSvg/HomeSvg"; // replace with the actual path to your SVGs

interface DeliveryAddressCardProps {
  // estimatedDelivery: string;
  name: string | null;
  deliverAddress: string;
  isOpenAddAddress: boolean;
  setIsAddAddressOpen: Dispatch<SetStateAction<boolean | false>>;
}

const DeliveryAddressCard: React.FC<DeliveryAddressCardProps> = ({
  name,
  deliverAddress,
  setIsAddAddressOpen,
}) => {
  const onClickChangeAddress = () => {
    setIsAddAddressOpen(true);
  };

  return (
    <div
      className="delivery-address-card-container"
      //  onClick={onClickDeliveryAddress}
    >
      <div className="delivery-address-card-header-container">
        {/* Left Section */}
        <div className="delivery-address-card-header-left-container">
          <div className="home-svg-container">
            <HomeSvg color={"grey"} width={20} height={20} />
          </div>
          <span className="delivery-address-card-header-text">
            Delivery address
          </span>
        </div>
        {/* Right Section */}
        <div className="delivery-address-card-header-right-container">
          <div className="arrow-right-svg-container"></div>
          <button className="change-button" onClick={onClickChangeAddress}>
            Change
          </button>
        </div>
      </div>

      <div className="delivery-address-card-body-container">
        {/* Optionally display estimated delivery */}
        {/* {false && (
                    <span className="estimated-delivery-text">
                        Estimated delivery {estimatedDelivery}
                    </span>
                )} */}
        <span className="buyer-name-text">{name}</span>
        <span className="delivery-address-text">{deliverAddress}</span>
      </div>
    </div>
  );
};

export default DeliveryAddressCard;
