/* eslint-disable @typescript-eslint/no-explicit-any */
import {api, apiConfig} from "../../interceptors/api";
import {
  CheckOtpAuthenticationPayload,
  CheckOtpAuthenticationResponse,
  SendOtpAuthenticationPayload,
  SendOtpAuthenticationResponse,
} from "./AuthenticationInterface";

export const getSendOtpAuthentication = async (
  payload: SendOtpAuthenticationPayload,
): Promise<SendOtpAuthenticationResponse> => {
  try {
    const response = await api.post(
      `${apiConfig.authUrl}/api/auth/v2/otp`,
      payload,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to send otp",
    );
  }
};

export const checkOtpAuthentication = async (
  payload: CheckOtpAuthenticationPayload,
): Promise<CheckOtpAuthenticationResponse> => {
  try {
    const response = await api.post(
      `${apiConfig.authUrl}/api/auth/v2/otp/verify`,
      payload,
    );
    return response.data.data;
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to validate otp",
    );
  }
};
