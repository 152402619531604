import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const EmailSvg: React.FC<SvgProps> = ({width, height, color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none">
      <path
        d="M8.77406 0.782274L15.3173 4.68611C15.5032 4.80683 15.6496 4.96575 15.7564 5.16286C15.8632 5.35997 15.9167 5.56964 15.9167 5.79186V14.5771C15.9167 14.998 15.7708 15.3543 15.4792 15.646C15.1875 15.9377 14.8312 16.0835 14.4102 16.0835H1.58979C1.16885 16.0835 0.812541 15.9377 0.520874 15.646C0.229207 15.3543 0.083374 14.998 0.083374 14.5771V5.79186C0.083374 5.56964 0.136798 5.35997 0.243645 5.16286C0.350478 4.96575 0.496839 4.80683 0.682728 4.68611L7.22598 0.782274C7.46316 0.645524 7.72117 0.577148 8.00002 0.577148C8.27887 0.577148 8.53688 0.645524 8.77406 0.782274ZM8.12823 9.46173L14.5 5.66686L8.12823 1.87198C8.08549 1.84527 8.04276 1.83192 8.00002 1.83192C7.95728 1.83192 7.91455 1.84527 7.87181 1.87198L1.50002 5.66686L7.87181 9.46173C7.91455 9.48844 7.95728 9.50179 8.00002 9.50179C8.04276 9.50179 8.08549 9.48844 8.12823 9.46173ZM7.22598 10.5386L1.33335 7.01938V14.5771C1.33335 14.6519 1.3574 14.7133 1.40548 14.7614C1.45356 14.8095 1.515 14.8335 1.58979 14.8335H14.4102C14.485 14.8335 14.5465 14.8095 14.5946 14.7614C14.6426 14.7133 14.6667 14.6519 14.6667 14.5771V7.01938L8.77406 10.5386C8.53688 10.6754 8.27887 10.7438 8.00002 10.7438C7.72117 10.7438 7.46316 10.6754 7.22598 10.5386Z"
        fill={color ? color : "#938E9E"}
      />
    </svg>
  );
};
export default EmailSvg;
