import React from "react";

interface SortBySvgProps {
  width: number | string;
  height: number | string;
  color?: string;
}

const SortBySvg: React.FC<SortBySvgProps> = ({
  width,
  height,
  color = "#7B7589",
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M5.5 3.62295V12.1667C5.5 12.6269 5.1269 13 4.66667 13C4.20643 13 3.83333 12.6269 3.83333 12.1667L3.83333 3.62295L1.92259 5.53369C1.59715 5.85913 1.06951 5.85913 0.744078 5.53369C0.418641 5.20826 0.418641 4.68062 0.744078 4.35518L4.07741 1.02185C4.40285 0.696412 4.93049 0.696412 5.25592 1.02185L8.58926 4.35518C8.91469 4.68062 8.91469 5.20826 8.58926 5.53369C8.26382 5.85913 7.73618 5.85913 7.41074 5.53369L5.5 3.62295Z"
      fill={color}
    />
    <path
      d="M12.1667 3.83333C12.1667 3.37309 11.7936 2.99999 11.3333 2.99999C10.8731 2.99999 10.5 3.37309 10.5 3.83333V12.377L8.58926 10.4663C8.26382 10.1409 7.73618 10.1409 7.41075 10.4663C7.08531 10.7917 7.08531 11.3194 7.41075 11.6448L10.7441 14.9781C11.0695 15.3036 11.5972 15.3036 11.9226 14.9781L15.2559 11.6448C15.5814 11.3194 15.5814 10.7917 15.2559 10.4663C14.9305 10.1409 14.4028 10.1409 14.0774 10.4663L12.1667 12.377V3.83333Z"
      fill={color}
    />
  </svg>
);

export default SortBySvg;
