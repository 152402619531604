export const colourCode: Record<string, string> = {
  Black: "#000000",
  White: "#FFFFFF",
  Green: "#008000",
  Blue: "#0000FF",
  Beige: "#F5F5DC",
  Orange: "#FFA500",
  Brown: "#A52A2A",
  Yellow: "#FFFF00",
  Red: "#FF0000",
  Grey: "#808080",
  Purple: "#800080",
  Pink: "#FFC0CB",
};
