import React from "react";

// Define the interface for the props
interface UnfulfilledSvgProps {
  width: string; // width should be a string
  height: string; // height should be a string
  color?: string; // color is optional and should be a string
}

const UnfulfilledSvg: React.FC<UnfulfilledSvgProps> = ({
  width,
  height,
  color = "#E5E5E8",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 18" fill="none">
      <path
        d="M15.5 5.25L8 0.875L0.5 5.25M15.5 5.25L8 9.625M15.5 5.25V12.75L8 17.125M0.5 5.25L8 9.625M0.5 5.25V12.75L8 17.125M8 9.625V17.125"
        stroke={color} // Use the 'color' prop here
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UnfulfilledSvg;
