import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const RatingSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1243_33852"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20">
        <rect
          x="0.666672"
          width={width}
          height={height}
          fill={color ? color : "#D9D9D9"}
        />
      </mask>
      <g mask="url(#mask0_1243_33852)">
        <path
          d="M8.04166 14.0211L10.6667 12.4377L13.2917 14.0419L12.6042 11.0419L14.9167 9.0419L11.875 8.77107L10.6667 5.93773L9.45833 8.75023L6.41666 9.02107L8.72916 11.0419L8.04166 14.0211ZM10.6667 13.9073L7.42479 15.8623C7.30396 15.9329 7.18291 15.9625 7.06166 15.9513C6.94041 15.94 6.83069 15.8992 6.7325 15.8288C6.63416 15.7582 6.55826 15.6661 6.50479 15.5523C6.45146 15.4386 6.44291 15.3127 6.47916 15.1748L7.33979 11.4938L4.47771 9.01628C4.37076 8.92336 4.30264 8.81572 4.27333 8.69336C4.24389 8.571 4.25159 8.45211 4.29646 8.33669C4.34132 8.22142 4.40597 8.12718 4.49041 8.05398C4.57486 7.98079 4.69028 7.93294 4.83666 7.91044L8.61375 7.58044L10.0802 4.1044C10.1335 3.9751 10.2144 3.87947 10.3229 3.81753C10.4314 3.75558 10.546 3.72461 10.6667 3.72461C10.7874 3.72461 10.9019 3.75558 11.0104 3.81753C11.1189 3.87947 11.1998 3.9751 11.2531 4.1044L12.7196 7.58044L16.4967 7.91044C16.6431 7.93294 16.7585 7.98079 16.8429 8.05398C16.9274 8.12718 16.992 8.22142 17.0369 8.33669C17.0817 8.45211 17.0894 8.571 17.06 8.69336C17.0307 8.81572 16.9626 8.92336 16.8556 9.01628L13.9935 11.4938L14.8542 15.1748C14.8904 15.3127 14.8819 15.4386 14.8285 15.5523C14.7751 15.6661 14.6992 15.7582 14.6008 15.8288C14.5026 15.8992 14.3929 15.94 14.2717 15.9513C14.1504 15.9625 14.0294 15.9329 13.9085 15.8623L10.6667 13.9073Z"
          fill={color ? color : "#6B7280"}
        />
      </g>
    </svg>
  );
};

export default RatingSvg;
