import React from "react";

export interface DeleteSvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

export const DeleteSvg = function DeleteSvg(props: DeleteSvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 14 16"
      fill="none">
      <path
        d="M3.10799 15.9001C2.66588 15.9001 2.2874 15.7427 1.97257 15.4278C1.65772 15.113 1.50029 14.7345 1.50029 14.2924V2.60015H0.950293C0.766126 2.60015 0.611751 2.53833 0.487168 2.4147C0.362584 2.29107 0.300293 2.13787 0.300293 1.9551C0.300293 1.77233 0.362584 1.61749 0.487168 1.49058C0.611751 1.36364 0.766126 1.30018 0.950293 1.30018H4.60027V1.00018C4.60027 0.783642 4.6769 0.598742 4.83017 0.445476C4.98344 0.292209 5.16833 0.215576 5.38487 0.215576H8.61567C8.8322 0.215576 9.0171 0.292209 9.17037 0.445476C9.32364 0.598742 9.40027 0.783642 9.40027 1.00018V1.30018H13.0502C13.2344 1.30018 13.3888 1.362 13.5134 1.48565C13.638 1.60928 13.7002 1.76248 13.7002 1.94525C13.7002 2.12802 13.638 2.28286 13.5134 2.40978C13.3888 2.53669 13.2344 2.60015 13.0502 2.60015H12.5002V14.2816C12.5002 14.7359 12.3428 15.1193 12.028 15.4316C11.7131 15.744 11.3347 15.9001 10.8925 15.9001H3.10799ZM11.2003 2.60015H2.80027V14.2924C2.80027 14.3822 2.82912 14.4559 2.88682 14.5136C2.94452 14.5713 3.01824 14.6002 3.10799 14.6002H10.8925C10.9823 14.6002 11.056 14.5713 11.1137 14.5136C11.1714 14.4559 11.2003 14.3822 11.2003 14.2924V2.60015ZM5.64919 12.8002C5.83196 12.8002 5.9868 12.7379 6.11372 12.6133C6.24065 12.4887 6.30412 12.3343 6.30412 12.1502V5.05013C6.30412 4.86598 6.24229 4.71161 6.11864 4.58703C5.99501 4.46244 5.84181 4.40015 5.65904 4.40015C5.47628 4.40015 5.32143 4.46244 5.19452 4.58703C5.0676 4.71161 5.00414 4.86598 5.00414 5.05013V12.1502C5.00414 12.3343 5.06596 12.4887 5.18959 12.6133C5.31323 12.7379 5.46643 12.8002 5.64919 12.8002ZM8.34149 12.8002C8.52426 12.8002 8.6791 12.7379 8.80602 12.6133C8.93293 12.4887 8.99639 12.3343 8.99639 12.1502V5.05013C8.99639 4.86598 8.93458 4.71161 8.81094 4.58703C8.68731 4.46244 8.53411 4.40015 8.35134 4.40015C8.16858 4.40015 8.01373 4.46244 7.88682 4.58703C7.75988 4.71161 7.69642 4.86598 7.69642 5.05013V12.1502C7.69642 12.3343 7.75824 12.4887 7.88189 12.6133C8.00553 12.7379 8.15873 12.8002 8.34149 12.8002Z"
        fill="#D81C1C"
      />
    </svg>
  );
};
