import React, {useState, useEffect, useRef} from "react";
import "./Juspay.style.css";
import {initiatePaymentApi} from "../../features/Payment/PaymentApi";
import {initiateBuyNowThunk} from "../../features/Cart/CartSlice";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {AppDispatch} from "../../interceptors/store";
import {InitiateBuyNowPayload} from "../../features/Cart/CartInterface";

interface JuspayProps {
  initiate?: boolean;
  redirectUrl?: string;
  userId: string;
  streamId?: string;
  addressId: string;
  amount: number;
  product: {
    product_id: string;
    varient_id: string;
    cartIds: number[];
  };
  storeId: string;
  deliveryAddress: string;
  onInitiationComplete?: () => void;
  onInitiationFailure?: () => void;
  onCancel?: () => void;
  type?: "buyNow" | "cart";
}

const Juspay: React.FC<JuspayProps> = ({
  initiate = false,
  redirectUrl = "/",
  userId,
  streamId,
  addressId,
  amount,
  product,
  storeId,
  deliveryAddress,
  onInitiationComplete,
  onInitiationFailure,
  onCancel,
  type = "buyNow",
}) => {
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const paymentLinkRef = useRef(paymentLink);
  const dispatch = useDispatch<AppDispatch>();

  const onBackButtonEvent = (e: PopStateEvent) => {
    if (paymentLinkRef.current) {
      e.preventDefault();
      window.history.replaceState(null, "", window.location.href);
      setPaymentLink(null);
      onCancel?.();
    }
  };

  useEffect(() => {
    paymentLinkRef.current = paymentLink;
  }, [paymentLink]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (initiate) {
      initiatePayments();
    } else {
      setPaymentLink(null);
    }
  }, [initiate]);

  const initiatePayments = async () => {
    try {
      if (type === "buyNow") {
        let payload: InitiateBuyNowPayload = {
          product_id: product.product_id,
          product_pricing_details_id: product.varient_id,
          quantity: 1,
          seller_store_id: storeId,
          address_id: addressId,
          delivery_address: deliveryAddress,
          selling_price: amount,
        };

        if (streamId) {
          payload = {...payload, stream_id: parseInt(streamId)};
        }

        const action = await dispatch(initiateBuyNowThunk(payload));

        if (initiateBuyNowThunk.fulfilled.match(action)) {
          if (action?.payload && action.payload?.payment_links?.web) {
            localStorage.setItem("redirectUrl", redirectUrl);
            setPaymentLink(action.payload.payment_links.web);
            onInitiationComplete?.();
          }
        } else {
          toast.error(
            (action.payload as string) ??
              "Payment is currently under maintenance. Sorry for the inconvenience",
          );
          onInitiationFailure?.();
        }
      } else {
        const payload = {
          user_id: userId,
          cart_ids: product.cartIds, // Update with correct IDs if dynamic
          order_amount: amount,
          cart_delivery_address_id: addressId,
        };

        const response = await initiatePaymentApi(payload);

        if (response?.payment_links?.web) {
          localStorage.setItem("redirectUrl", redirectUrl);
          setPaymentLink(response.payment_links.web);
          onInitiationComplete?.();
        } else {
          onInitiationFailure?.();
        }
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      onInitiationFailure?.();
      toast.error(
        "Payment is currently under maintenance. Sorry for the inconvenience",
        {
          toastId: userId,
        },
      );
    }
  };

  return (
    <React.Fragment>
      {paymentLink && (
        <div id="juspayDiv" className="fullscreen-container">
          <iframe
            src={paymentLink}
            allow="payment *"
            className="fullscreen-iframe"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Juspay;
