import React from "react";

interface TickSvgProps {
  width: string;
  height: string;
  color?: string;
}

const TickSvg: React.FC<TickSvgProps> = ({
  width,
  height,
  color = "#00A680",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2 6.9999C12.2 10.0927 9.69279 12.5999 6.6 12.5999C3.50721 12.5999 1 10.0927 1 6.9999C1 3.90711 3.50721 1.3999 6.6 1.3999C9.69279 1.3999 12.2 3.90711 12.2 6.9999Z"
        fill={color}
      />
      <path
        d="M4.73333 6.9999L5.97778 8.24435L9.22225 4.99988"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickSvg;
