import React, {useState, useRef, useEffect} from "react";
import "./Slider.css";

type SliderProps<T> = {
  options: T[];
  renderOption: (option: T, isSelected: boolean) => React.ReactNode;
  onSelect: (option: T) => void;
};

const Slider = <T,>({options, renderOption, onSelect}: SliderProps<T>) => {
  const [startIndex, setStartIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const visibleOptions = Math.floor(window.innerWidth / 40);

  const handlePrev = () => {
    setStartIndex(prevIndex => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setStartIndex(prevIndex =>
      Math.min(options.length - visibleOptions, prevIndex + 1),
    );
  };

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    onSelect(options[index]);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({left: 0, behavior: "smooth"});
    }
  }, [startIndex]);

  return (
    <div className="option-slider">
      <div className="slider-container">
        {options.length > visibleOptions && (
          <button
            className="slider-button"
            onClick={handlePrev}
            disabled={startIndex === 0}>
            &lt;
          </button>
        )}
        <div className="options-container" ref={containerRef}>
          {options
            .slice(startIndex, startIndex + visibleOptions)
            .map((option, index) => (
              <div
                key={startIndex + index}
                className={`slider-option ${
                  startIndex + index === selectedIndex ? "selected" : ""
                }`}
                onClick={() => handleSelect(startIndex + index)}>
                {renderOption(option, startIndex + index === selectedIndex)}
              </div>
            ))}
        </div>
        {options.length > visibleOptions && (
          <button
            className="slider-button"
            onClick={handleNext}
            disabled={startIndex >= options.length - visibleOptions}>
            &gt;
          </button>
        )}
      </div>
    </div>
  );
};

export default Slider;
