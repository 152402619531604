import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const PhoneIconSvg: React.FC<SvgProps> = ({width, height, color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 18" fill="none">
      <path
        d="M4.79808 15.5224H7.20191C7.30234 15.5224 7.38889 15.4861 7.46154 15.4134C7.53418 15.3408 7.5705 15.2542 7.5705 15.1538C7.5705 15.0534 7.53418 14.9669 7.46154 14.8942C7.38889 14.8216 7.30234 14.7852 7.20191 14.7852H4.79808C4.69765 14.7852 4.61111 14.8216 4.53846 14.8942C4.46582 14.9669 4.4295 15.0534 4.4295 15.1538C4.4295 15.2542 4.46582 15.3408 4.53846 15.4134C4.61111 15.4861 4.69765 15.5224 4.79808 15.5224ZM2.08977 17.75C1.66882 17.75 1.31252 17.6041 1.02085 17.3125C0.729184 17.0208 0.583351 16.6645 0.583351 16.2435V1.75642C0.583351 1.33547 0.729184 0.979167 1.02085 0.6875C1.31252 0.395833 1.66882 0.25 2.08977 0.25H9.91022C10.3312 0.25 10.6875 0.395833 10.9791 0.6875C11.2708 0.979167 11.4166 1.33547 11.4166 1.75642V16.2435C11.4166 16.6645 11.2708 17.0208 10.9791 17.3125C10.6875 17.6041 10.3312 17.75 9.91022 17.75H2.08977ZM1.83333 13.8077V16.2435C1.83333 16.3077 1.86004 16.3664 1.91346 16.4199C1.96689 16.4733 2.02566 16.5 2.08977 16.5H9.91022C9.97434 16.5 10.0331 16.4733 10.0865 16.4199C10.14 16.3664 10.1667 16.3077 10.1667 16.2435V13.8077H1.83333ZM1.83333 12.5577H10.1667V3.79163H1.83333V12.5577ZM1.83333 2.54167H10.1667V1.75642C10.1667 1.69231 10.14 1.63354 10.0865 1.5801C10.0331 1.52669 9.97434 1.49998 9.91022 1.49998H2.08977C2.02566 1.49998 1.96689 1.52669 1.91346 1.5801C1.86004 1.63354 1.83333 1.69231 1.83333 1.75642V2.54167Z"
        fill={color ? color : "#938E9E"}
      />
    </svg>
  );
};
export default PhoneIconSvg;
