import React, {useState} from "react";
import "./AboutSection.style.css";
import StoreImgSvg from "../../../../../components/SvgImages/StoreImgSvg/StoreImgSvg";
import DeliverySvg from "../../../../../components/SvgImages/DeliverySvg/DeliverySvg";
import StoreHomeSvg from "../../../../../components/SvgImages/StoreHomeSvg/StoreHomeSvg";
import PhoneIconSvg from "../../../../../components/SvgImages/PhoneIconSvg/PhoneIconSvg";
import EmailSvg from "../../../../../components/SvgImages/EmailSvg/EmailSvg";
import {RatingCount, Review} from "../../../../../features/User/UserInterface";
import {formatRatingType, renderStars} from "../../../../../utils/helpers";
import ExpandMoreSvg from "../../../../../components/SvgImages/ExpandMoreSvg/ExpandMoreSvg";
import StarFilledSvg from "../../../../../components/SvgImages/StarFilledSvg/StarFilledSvg";
import ExpandLessSvg from "../../../../../components/SvgImages/ExpandLessSvg/ExpandLessSvg";

interface StoreAboutProps {
  storeName?: string;
  freeDeliveryAmount?: number | undefined;
  email: string | undefined;
  deliveryDiscount: number | undefined;
  storeId: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  pincode: number | undefined;
  phoneNumber: string | undefined;
  setReviewOverlayOpen: (value: boolean) => void;
  profilePhoto: string | undefined;
  reviews: Review[];
  reviewCount: number;
  productCount: number;
  storeRating: number | undefined;
  ratings: RatingCount[];
}

const AboutSection: React.FC<StoreAboutProps> = ({
  storeName,
  email,
  deliveryDiscount,
  storeId,
  address1,
  address2,
  city,
  pincode,
  phoneNumber,
  setReviewOverlayOpen,
  profilePhoto,
  reviewCount,
  productCount,
  storeRating,
  ratings,
}) => {
  const [isStoreInfoOpen, setIsStoreInfoOpen] = useState(false); // Store Info initially open
  const [isReturnPolicyOpen, setIsReturnPolicyOpen] = useState(false); // Return Policy initially closed

  const toggleSection = (section: string) => {
    if (section === "storeInfo") {
      setIsStoreInfoOpen(!isStoreInfoOpen);
    } else if (section === "returnPolicy") {
      setIsReturnPolicyOpen(!isReturnPolicyOpen);
    }
  };

  return (
    <section className="storefront-about-section">
      <div className="storefront-about-section-store-info-card">
        <div className="storefront-about-section-review-card">
          <div className="storefront-about-section-store-profile">
            <img
              src={profilePhoto}
              alt="Store Profile Picture"
              className="storefront-about-section-store-image"
            />
            <h2 className="storefront-about-section-store-name">{storeName}</h2>
          </div>
          <div className="storefront-about-section-store-stats">
            <div className="storefront-about-section-stat-item store-front-bottom-line">
              <span className="storefront-about-section-stat-value">
                {reviewCount || "N/A"}
              </span>
              <span className="storefront-about-section-stat-label">
                Reviews
              </span>
            </div>
            <div className="storefront-about-section-stat-item store-front-bottom-line">
              <span className="storefront-about-section-stat-value">
                {storeRating || "N/A"}
              </span>
              <span className="storefront-about-section-stat-label">
                Ratings
              </span>
            </div>
            <div className="storefront-about-section-stat-item">
              <span className="storefront-about-section-stat-value">
                {productCount}
              </span>
              <span className="storefront-about-section-stat-label">
                Listings
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="storefront-about-section-rating-card"
        onClick={() => setReviewOverlayOpen(true)}>
        <div className="storefront-about-section-rating-card-heading">
          <h2 className="storefront-about-section-overall-rating">
            {storeRating || "N/A"}{" "}
            <span className="storefront-about-section-star">
              <StarFilledSvg height={"24"} width={"24"} />
            </span>
          </h2>
          <p className="storefront-about-section-rating-summary">
            {reviewCount || "N/A"} Reviews •{" "}
            {ratings.reduce(
              (total, rating) => total + Number(rating.rating_type_count),
              0,
            )}{" "}
            ratings
          </p>
        </div>

        <div className="storefront-about-section-rating-breakdown">
          {ratings.map((rating, index) => (
            <div className="storefront-about-section-rating-item" key={index}>
              <span className="storefront-about-section-rating-text">
                {formatRatingType(rating.rating_type)}
              </span>
              <div className="storefront-about-section-stars">
                {renderStars(rating.avg_rating)}
              </div>
              <span className="storefront-about-section-review-rating">
                {parseFloat(rating.avg_rating).toFixed(1)}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div
        className="storefront-about-section-store-info-card"
        onClick={() => toggleSection("storeInfo")}>
        <div className="storefront-about-section-store-info-card-body">
          <div className="storefront-about-section-flex-box">
            <div className="storefront-about-section-flex-item">
              <StoreImgSvg height={20} width={20} />
            </div>
            <div className="storefront-about-section-flex-item store-info-title">
              Store info
            </div>
            <div className="storefront-about-section-store-expand-svg">
              {!isStoreInfoOpen ? (
                <ExpandMoreSvg height={"20"} width={"20"} />
              ) : (
                <ExpandLessSvg height={"20"} width={"20"} />
              )}
            </div>
          </div>

          {isStoreInfoOpen && (
            <div>
              <div className="storefront-about-section-flex-box">
                <div className="storefront-about-section-flex-item">
                  <DeliverySvg height={20} width={20} color="#9CA3AF" />
                </div>
                <p className="storefront-about-section-flex-item store-offer">
                  Rs. {deliveryDiscount} off on delivery fees!
                </p>
              </div>
              <div className="storefront-about-section-flex-box">
                <div className="storefront-about-section-flex-item">
                  <StoreImgSvg height={20} width={20} color="#9CA3AF" />
                </div>
                <p className="storefront-about-section-flex-item store-id">
                  Store unique ID - {storeId}
                </p>
              </div>

              <div className="storefront-about-section-flex-box">
                <div className="storefront-about-section-flex-item">
                  <StoreHomeSvg height={20} width={20} />
                </div>
                <p className="storefront-about-section-flex-item store-address">
                  {address1}, {address2}, {city} - {pincode}
                </p>
              </div>
              <div className="storefront-about-section-flex-box">
                <div className="storefront-about-section-flex-item">
                  <PhoneIconSvg height={20} width={20} />
                </div>
                <p className="storefront-about-section-flex-item store-contact">
                  {phoneNumber || "N/A"}
                </p>
              </div>
              <div className="storefront-about-section-flex-box">
                <div className="storefront-about-section-flex-item">
                  <EmailSvg height={20} width={20} />
                </div>
                <p className="storefront-about-section-flex-item store-email">
                  {email || "N/A"}
                </p>
              </div>
              {/* <div className="flex-box">
                <div className="storefront-about-section-flex-item"><StoreTimingSvg height={20} width={20} /></div>
                <p className="storefront-about-section-flex-item store-hours">Mon - Sat | 11AM - 7PM</p>
              </div> */}
            </div>
          )}
        </div>
      </div>

      <div
        className="storefront-about-section-return-policy-card"
        onClick={() => toggleSection("returnPolicy")}>
        <div>
          <h3 className="storefront-about-section-return-policy-title">
            ↩ Return policy
          </h3>
          <div className="storefront-about-section-return-expand-svg">
            {!isReturnPolicyOpen ? (
              <ExpandMoreSvg height={"20"} width={"20"} />
            ) : (
              <ExpandLessSvg height={"20"} width={"20"} />
            )}
          </div>
        </div>
        {isReturnPolicyOpen && (
          <div>
            <p className="storefront-about-section-return-policy-description">
              Acceptable reasons to claim an exchange
            </p>
            <ul className="storefront-about-section-return-policy-list">
              <li>A defective product</li>
              <li>Missing item / piece</li>
              <li>No returns / refunds only exchange!</li>
            </ul>
            <p className="storefront-about-section-return-policy-note">
              No cancellations once order is accepted and processed. Transit
              delays are not in our hands; however, we will help you to get the
              order delivered soon!
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default AboutSection;
