import React from "react";
import "./OrdersPageCard.style.css";
import UnfulfilledSvg from "../../../../../components/SvgImages/UnfulfilledSvg";
import TickSvg from "../../../../../components/SvgImages/TickSvg";
import {Variant} from "../../../../../features/Order/OrderInterface";
import {capitalizeFirstLetter} from "../../../../../utils/helpers";

// Define the props for OrdersPageCard component
interface OrdersPageCardProps {
  productName: string;
  productDescription: string;
  productQuantity: number;
  productImage: string;
  orderStatus: string | null;
  statusDate: string;
  variants: Variant[];
}

const OrdersPageCard: React.FC<OrdersPageCardProps> = ({
  productName,
  productDescription,
  productQuantity,
  productImage,
  orderStatus,
  statusDate,
  variants,
}) => {
  // Function to capitalize the first letter of a string

  // Function to convert a timestamp to a formatted date
  const convertTimestamp = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="orders-page-card">
      <div className="orders-page-card-header">
        <div className="unfulfilled-svg-container">
          <UnfulfilledSvg height="100%" width="100%" />
          {orderStatus === "delivered" && (
            <div className="tick-svg-container">
              <TickSvg height="100%" width="100%" />
            </div>
          )}
        </div>
        <div className="orders-page-header-text">
          <div className={`orders-page-header ${orderStatus}`}>
            {capitalizeFirstLetter(
              orderStatus === "placed" || orderStatus === "accepted"
                ? "Order Confirmed"
                : orderStatus || "Status unknown",
            )}
          </div>
          <div className="orders-page-sub-header">
            On {convertTimestamp(statusDate)}
          </div>
        </div>
      </div>
      <div className="orders-page-product-container">
        <div className="orders-page-left-container">
          <img
            src={productImage}
            alt={productName}
            className="orders-page-image"
          />
        </div>
        <div className="orders-page-right-container">
          <div className="orders-page-right-container-top">
            <div className="orders-page-name">{productName}</div>
            <div className="orders-page-description">{productDescription}</div>
          </div>
          <div className="orders-page-button-container">
            <div className="orders-page-quantity-container">
              <div className="orders-page-dropdown-button">
                <span>Quantity - {productQuantity}</span>
              </div>
            </div>
            {variants &&
              variants.length > 0 &&
              variants?.[0] &&
              variants?.[0].label !== "No Variant" && (
                <div
                  className="orders-page-dropdown-button-container"
                  style={{display: "flex"}}>
                  {variants.map(
                    (variant, index) =>
                      index < 2 && (
                        <div
                          key={index}
                          className="orders-page-dropdown-button">
                          <span>
                            {variant?.label?.toLowerCase().includes("size")
                              ? "Size"
                              : variant.label}{" "}
                            - {variant.value}
                          </span>
                        </div>
                      ),
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersPageCard;
