/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getProductById, getProducts} from "./InventoryApi";
import {
  GetProductById,
  GetProductsPayload,
  ProductState,
} from "./InventoryInterface";
import {removeDuplicatesByKey} from "../../utils/helpers";

const initialState: ProductState = {
  loading: false,
  error: null,
  success: false,
  products: {
    data: [],
    page: 1,
    limit: 5,
    count: 0,
  },
  storeProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 0,
  },
};

const getFilteredProducts = (data: any) => {
  let tempProducts = [...data.products];
  tempProducts = removeDuplicatesByKey(tempProducts, "id");

  return tempProducts;
};

export const getProductThunk = createAsyncThunk(
  "inventory/getProduct",
  async (params: GetProductById, {rejectWithValue}) => {
    try {
      const response = await getProductById(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getStoreProductsThunk = createAsyncThunk(
  "inventory/getStoreProducts",
  async (payload: GetProductsPayload, {rejectWithValue}) => {
    try {
      const response = await getProducts(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getSearchProductsThunk = createAsyncThunk(
  "inventory/getStoreProducts",
  async (payload: GetProductsPayload, {rejectWithValue}) => {
    try {
      const response = await getProducts(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getProductThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProductThunk.fulfilled, state => {
        state.loading = false;
      })
      .addCase(getProductThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      .addCase(getStoreProductsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStoreProductsThunk.fulfilled, (state, action) => {
        const {data} = action.payload;

        state.loading = false;
        state.storeProducts = {
          data: getFilteredProducts(data),
          count: data.net_count,
          page: data.page,
          limit: data.limit,
        };
        state.error = null;
      })
      .addCase(getStoreProductsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    // ///get search product
    // .addCase(getSearchProductsThunk.pending, state => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(getSearchProductsThunk.fulfilled, (state, action) => {
    //   const {data} = action.payload;

    //   state.loading = false;
    //   state.storeProducts = {
    //     data: getFilteredProducts(data),
    //     count: data.net_count,
    //     page: data.page,
    //     limit: data.limit,
    //   };
    //   state.error = null;
    // })
    // .addCase(getSearchProductsThunk.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload as string;
    // })
  },
});

export default inventorySlice.reducer;
export const {reset} = inventorySlice.actions;
