import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const StoreViewsSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1243_33856"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20">
        <rect width={width} height={height} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1243_33856)">
        <path
          d="M10.0019 12.9807C10.9461 12.9807 11.7481 12.6502 12.4078 11.9893C13.0676 11.3283 13.3974 10.5257 13.3974 9.58142C13.3974 8.63718 13.0669 7.8352 12.406 7.17548C11.745 6.51576 10.9424 6.1859 9.99811 6.1859C9.05387 6.1859 8.25189 6.51638 7.59217 7.17735C6.93245 7.83834 6.60259 8.64096 6.60259 9.58521C6.60259 10.5294 6.93307 11.3314 7.59405 11.9911C8.25503 12.6509 9.05765 12.9807 10.0019 12.9807ZM10 11.8333C9.37501 11.8333 8.84376 11.6146 8.40626 11.1771C7.96876 10.7396 7.75001 10.2083 7.75001 9.58331C7.75001 8.95831 7.96876 8.42706 8.40626 7.98956C8.84376 7.55206 9.37501 7.33331 10 7.33331C10.625 7.33331 11.1563 7.55206 11.5938 7.98956C12.0313 8.42706 12.25 8.95831 12.25 9.58331C12.25 10.2083 12.0313 10.7396 11.5938 11.1771C11.1563 11.6146 10.625 11.8333 10 11.8333ZM10 15.4166C8.24574 15.4166 6.64132 14.9487 5.18673 14.0128C3.73214 13.0769 2.57108 11.8456 1.70357 10.3189C1.63412 10.1992 1.58337 10.0786 1.55132 9.9571C1.51926 9.83556 1.50323 9.71083 1.50323 9.5829C1.50323 9.45497 1.51926 9.33038 1.55132 9.20913C1.58337 9.08786 1.63412 8.9674 1.70357 8.84775C2.57108 7.32104 3.73214 6.08974 5.18673 5.15385C6.64132 4.21795 8.24574 3.75 10 3.75C11.7543 3.75 13.3587 4.21795 14.8133 5.15385C16.2679 6.08974 17.4289 7.32104 18.2964 8.84775C18.3659 8.9674 18.4166 9.08799 18.4487 9.20952C18.4807 9.33106 18.4968 9.4558 18.4968 9.58373C18.4968 9.71166 18.4807 9.83625 18.4487 9.9575C18.4166 10.0788 18.3659 10.1992 18.2964 10.3189C17.4289 11.8456 16.2679 13.0769 14.8133 14.0128C13.3587 14.9487 11.7543 15.4166 10 15.4166ZM10 14.1666C11.5695 14.1666 13.0104 13.7535 14.3229 12.9271C15.6354 12.1007 16.6389 10.9861 17.3333 9.58331C16.6389 8.18054 15.6354 7.06595 14.3229 6.23956C13.0104 5.41317 11.5695 4.99998 10 4.99998C8.43056 4.99998 6.98959 5.41317 5.67709 6.23956C4.36459 7.06595 3.36112 8.18054 2.66667 9.58331C3.36112 10.9861 4.36459 12.1007 5.67709 12.9271C6.98959 13.7535 8.43056 14.1666 10 14.1666Z"
          fill="#6B7280"
        />
      </g>
    </svg>
  );
};

export default StoreViewsSvg;
