import React from "react";

interface DropdownSvgProps {
  width: number;
  height: number;
  color: string;
}

const DropdownSvg: React.FC<DropdownSvgProps> = ({width, height, color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 6" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.244078 0.744078C0.569515 0.418641 1.09715 0.418641 1.42259 0.744078L5 4.32149L8.57741 0.744078C8.90285 0.418641 9.43049 0.418641 9.75592 0.744078C10.0814 1.06951 10.0814 1.59715 9.75592 1.92259L6.17851 5.5C5.52764 6.15088 4.47236 6.15087 3.82149 5.5L0.244078 1.92259C-0.0813592 1.59715 -0.0813592 1.06951 0.244078 0.744078Z"
        fill={color || "#938E9E"}
      />
    </svg>
  );
};

export default DropdownSvg;
