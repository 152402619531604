import React from "react";
import "./ProductInfo/ProductInfo.style.css";

interface SellerInfoProps {
  image?: string | null;
  name: string;
}

const SellerInfo: React.FC<SellerInfoProps> = ({image = null, name}) => {
  return (
    <div className="seller-info">
      <img
        src={image ?? "/avatar.png"}
        alt="Seller Profile"
        className="profile-pic"
      />
      <span className="seller-name">{name}</span>
    </div>
  );
};

export default SellerInfo;
