import React from "react";
import "./StreamListSkeleton.style.css"; // Optional: If you want to keep the styles in a separate file

const StreamListSkeleton: React.FC = () => {
  return (
    <div className="streamlist-skeleton-carousel">
      <div
        className="streamlist-skeleton"
        style={{width: "35vw", height: "28vh", borderRadius: "20px"}}></div>
      <div
        className="streamlist-skeleton streamlist-skeleton-text"
        style={{width: "35vw", height: "1rem"}}></div>
    </div>
  );
};

export default StreamListSkeleton;
