import React from "react";
import "./ZoopLogo.style.css";
import {SvgProps} from "../ChatSvg/ChatSvg";

const ZoopLogo: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 116 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1977_6822)">
        <path
          d="M6.59793 25.4784C2.2141 24.7884 0.0409365 20.9221 0.0409365 17.9202C0.0409365 14.5038 2.83661 10.2231 8.04733 10.2231H21.8869C27.4783 10.2231 29.7565 14.952 29.7565 17.9539C29.7565 19.4371 29.3083 20.9915 28.3408 22.3715L23.2332 29.3766C27.4783 30.0329 29.7902 33.7604 29.7902 36.9011C29.7902 40.3174 27.0977 44.5625 21.8513 44.5625H8.0117C2.69598 44.5625 0.0390625 40.2143 0.0390625 36.8655C0.0390625 35.3823 0.487195 33.8279 1.48846 32.4817L6.59605 25.4747L6.59793 25.4784Z"
          fill="#276F8E"
        />
        <path
          d="M105.363 31.2664V33.164C105.363 36.5803 102.637 40.8253 97.3906 40.8253C92.1443 40.8253 89.418 36.4771 89.418 33.1283V14.1455C89.418 10.7292 92.2136 6.44849 97.4244 6.44849H103.049C111.506 6.44849 116.096 12.6605 116.096 18.9418C116.096 24.6026 112.265 30.2277 105.363 31.2627V31.2664Z"
          fill="#276F8E"
        />
        <path
          d="M72.1811 42.1155C62.1028 42.1155 54.4414 34.8328 54.4414 24.9289C54.4414 15.025 62.1028 7.77612 72.1811 7.77612C82.2594 7.77612 89.887 15.0588 89.887 24.9289C89.887 34.7991 82.295 42.1155 72.1811 42.1155Z"
          fill="#276F8E"
        />
        <path
          d="M40.8178 35.0748C30.7395 35.0748 23.0781 27.7922 23.0781 17.8883C23.0781 7.98435 30.7395 0.735474 40.8178 0.735474C50.8961 0.735474 58.5238 8.0181 58.5238 17.8883C58.5238 27.7584 50.9317 35.0748 40.8178 35.0748Z"
          fill="#F09728"
        />
        <path
          d="M55.685 8.2937C56.2569 14.8882 52.6287 21.8539 45.6686 26.6165C40.0716 30.4453 33.8784 31.7354 28.5664 30.5653C31.709 33.4004 35.9859 35.0748 40.8179 35.0748C50.9299 35.0748 58.5238 27.8615 58.5238 17.8882C58.5238 14.2638 57.4888 10.9881 55.685 8.29558V8.2937Z"
          fill="#EB7627"
        />
        <path
          d="M29.4565 11.9912C29.4565 11.9912 33.5703 6.19356 37.4048 4.79103C37.4048 4.79103 39.0885 3.201 35.0666 4.04289C31.0465 4.88478 28.4271 9.55924 28.2396 10.7761C28.0521 11.9912 28.894 13.1143 29.4546 11.9912H29.4565Z"
          fill="white"
        />
        <path
          d="M26.9787 15.2425C27.598 15.2425 28.1 14.7405 28.1 14.1213C28.1 13.502 27.598 13 26.9787 13C26.3594 13 25.8574 13.502 25.8574 14.1213C25.8574 14.7405 26.3594 15.2425 26.9787 15.2425Z"
          fill="white"
        />
        <path
          d="M41.083 34.5593C41.0305 35.7743 41.1543 37.0494 41.8124 38.0713C42.1105 38.5363 42.508 38.93 42.8249 39.3819C43.4437 40.2651 43.7362 41.3919 43.5224 42.4476C43.3087 43.5032 42.5568 44.4595 41.5443 44.8251"
          stroke="black"
          strokeWidth="0.28313"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.7885 34.3304C40.7885 34.3304 40.8016 34.8798 40.5653 35.1067C40.4003 35.266 39.9128 35.8867 40.3947 35.7742C40.9722 35.6392 41.4541 35.7123 41.7672 35.7179C42.0804 35.7235 42.1947 35.6935 41.921 35.3823C41.801 35.2473 41.8103 35.1254 41.8385 35.0035C42.1216 33.8035 40.7885 34.3304 40.7885 34.3304Z"
          fill="#EB7627"
        />
      </g>
      <defs>
        <clipPath id="clip0_1977_6822">
          <rect width="116" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ZoopLogo;
