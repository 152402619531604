import React, {Dispatch, SetStateAction} from "react";
import {useNavigate} from "react-router";
import "./MultipleProductCart.css";
import {CartItemDetails, GroupedStore} from "../../features/Cart/CartInterface";
import {DeleteSvg} from "../SvgImages/DeleteSvg/DeleteSvg";
import Loader from "../CustomLoader/CustomLoader";
import {convertIntoINRV2} from "../../utils/helpers";
interface SingleCartCardProps {
  cartDetails: GroupedStore; // Grouped store with cart details
  onDelete: (product: CartItemDetails) => void; // Callback to delete product from the cart
  onUpdateQuantity: (
    id: number,
    quantity: number,
    product: CartItemDetails,
  ) => void; // Callback to update quantity of a product
  quantityLoaders: {[key: number]: boolean}; // Track loading state for individual products
  setQuantityLoaders: Dispatch<SetStateAction<{[key: number]: boolean}>>; // Setter for the loading state object
}

interface Variant {
  label?: string;
  value?: string;
}

export const SingleCart: React.FC<SingleCartCardProps> = ({
  cartDetails,
  onUpdateQuantity,
  quantityLoaders,
  onDelete,
  setQuantityLoaders,
}) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIncrease = (product: any) => {
    const newQuantity = product.quantity + 1;
    setQuantityLoaders(prev => ({...prev, [product.id]: true}));
    onUpdateQuantity(product.id, newQuantity, product);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDecrease = (product: any) => {
    const newQuantity = product.quantity - 1;
    onUpdateQuantity(product.id, newQuantity, product);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRemove = (product: any) => {
    onDelete(product);
  };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"; // Fallback image
  };

  return (
    <>
      {cartDetails?.products.map((product, index) => (
        <div className="seller_store_card_container" key={index}>
          <div className="cart-product-container">
            {/* Left */}
            <div className="cart-product-left-container">
              <div className="cart-image-container">
                <img
                  onError={handleError}
                  src={product.image}
                  alt={product.product_name}
                  className="product-image-v2"
                  onClick={() => {
                    navigate(`/product/${product.product_id}`);
                  }}
                />
              </div>
            </div>

            {/* Right */}
            <div className="cart-product-right-container">
              <p className="product-name-v2" title={product.product_name}>
                {product.product_name}
              </p>
              <p
                className="product-description-v2"
                title={product.product_description}>
                {product.product_description}
              </p>

              <div className="product_controls">
                <div className="quantity_controls">
                  {product.quantity > 1 ? (
                    <button
                      onClick={() => handleDecrease(product)}
                      className="control_button">
                      - {/* Text for Decrease */}
                    </button>
                  ) : (
                    <button
                      onClick={() => handleRemove(product)}
                      className="remove_button">
                      <DeleteSvg height={20} width={20} color={"#E62E2E"} />
                    </button>
                  )}

                  <span className="quantity_text">
                    {" "}
                    {quantityLoaders[product.id] ? (
                      <Loader size={15} />
                    ) : (
                      product.quantity
                    )}
                  </span>
                  <button
                    onClick={() => {
                      handleIncrease(product);
                    }}
                    className="control_button">
                    + {/* Text for Increase */}
                  </button>
                </div>

                {product?.variants &&
                  product.variants.length > 0 &&
                  product.variants[0].label !== "No Variant" && (
                    <div
                      style={{
                        display: "flex",
                      }}>
                      {product?.variants?.map(
                        (variant: Variant, index: number) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: index > 1 ? "none" : "flex",
                              }}>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: "10px",
                                  fontWeight: "600",
                                  marginLeft: "5px",
                                  padding: "5px",
                                  background: "#cdcdcd",
                                  borderRadius: "5px",
                                }}>
                                {variant?.label?.toLowerCase().includes("size")
                                  ? "Size"
                                  : variant?.label}{" "}
                                - {variant?.value}
                              </p>
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
              </div>

              <p className="price-text">
                {convertIntoINRV2(product.product_selling_price)}{" "}
                {Number(product.product_price) >
                  Number(product.product_selling_price) && (
                  <span className="mrp-text">
                    <span className="line-through">
                      {convertIntoINRV2(product.product_price)}
                    </span>{" "}
                    <span className="discount-text">
                      {Math.round(
                        ((Number(product.product_price) -
                          Number(product.product_selling_price)) *
                          100) /
                          Number(product.product_price),
                      )}
                      % off
                    </span>
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
