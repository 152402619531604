/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useState} from "react";

type Platform = "android" | "ios" | "web" | "unknown";

const useMobilePlatform = (): Platform => {
  const [platform, setPlatform] = useState<Platform>("unknown"); // Default to 'unknown'

  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/android/i.test(userAgent)) {
      setPlatform("android");
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      setPlatform("ios");
    } else {
      setPlatform("web");
    }
  }, []);

  return platform;
};

export default useMobilePlatform;
