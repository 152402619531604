import React from "react";
import "./SortBy.style.css"; // Import external CSS file for styling

interface SortByProps {
  sortOptions: string[];
  selectedSortOption: string;
  show: boolean;
  onClose: () => void;
  onSortOptionChange: (option: string) => void;
}

interface CheckableListItemProps {
  title: string;
  selected: boolean | undefined;
  onPress: () => void;
}

export const SortBy = ({
  sortOptions,
  selectedSortOption,
  show,
  onClose,
  onSortOptionChange,
}: SortByProps) => {
  const handleOptionChange = (option: string) => {
    onSortOptionChange(option);
  };

  const CheckableListItem = ({
    title,
    selected,
    onPress,
  }: CheckableListItemProps) => {
    return (
      <div className="checkable-item" onClick={onPress}>
        <span className="item-text">{title}</span>
        <input
          type="checkbox"
          checked={selected}
          onChange={onPress}
          className="check-box"
        />
      </div>
    );
  };

  if (!show) return null; // Don't render anything if modal is not shown

  return (
    <div className="sort-by-modal">
      <div className="sort-by-content">
        <div className="sort-by-header">
          <span className="sort-by-title">Sort By</span>
          <button className="close-button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="sort-by-options-container">
          {sortOptions.map(option => (
            <CheckableListItem
              key={option}
              title={option}
              selected={selectedSortOption === option}
              onPress={() => handleOptionChange(option)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
