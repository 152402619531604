/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import {BuyerDetails} from "../../../../../features/Order/OrderInterface";
import {
  capitalizeFirstLetter,
  convertTimestamp,
  findByKey,
} from "../../../../../utils/helpers";
import "./OrderStatusCard.style.css";
import IndicatorSvg from "../../../../../components/SvgImages/IndicatorSvg/IndicatorSvg";
import InProgressIndicatorSvg from "../../../../../components/SvgImages/InProgressIndicatorSvg/InProgressIndicatorSvg";
import {AppDispatch} from "../../../../../interceptors/store";
import {useDispatch} from "react-redux";
import {getOrderDeliveryStatusThunk} from "../../../../../features/Order/OrderSlice";
import DropdownSvg from "../../../../../components/SvgImages/DropdownSvg/DropdownSvg";

interface OrderStatusCardProps {
  orderItemCount: number | undefined;
  orderNum?: string;
  buyerDetails?: BuyerDetails;
  currentStatus?: string;
}

const statusConfig = {
  picked_up: {
    name: "Picked Up",
    status: "picked_up",
  },
  in_transit: {
    name: "In Transit",
    status: "in_transit",
  },
  shipped: {
    name: "Shipped",
    status: "shipped",
  },
  out_for_delivery: {
    name: "Out for Delivery",
    status: "out_for_delivery",
  },
  delivered: {
    name: "Delivered",
    status: "delivered",
  },
};

interface OrderStatusIndicatorCardProps {
  item: {
    status: string;
    name: string;
  };
  index: number;
}

const OrderStatusCard: React.FC<OrderStatusCardProps> = ({
  orderItemCount = 0,
  orderNum,
  buyerDetails,
  currentStatus,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [orderStatusData, setOrderStatusData] = useState<any[]>([]);
  // const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    getOrderDeliveryStatus(String(orderNum));
  }, [orderNum]);

  const getOrderDeliveryStatus = async (orderId: string) => {
    // // setLoading(true)
    try {
      const action = await dispatch(getOrderDeliveryStatusThunk({orderId}));
      if (getOrderDeliveryStatusThunk.fulfilled.match(action)) {
        // success
        // setLoading(false)
        const responseOrder = action.payload.data.delivery_status || [];
        const mappedData =
          responseOrder?.map((status, index) => ({
            shipment_id: status.shipment_id || `${index}`,
            status: status.status || "No Status Available",
            status_date: status.status_date || "",
          })) || [];

        setOrderStatusData(mappedData);
      } else {
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      console.log("error", error);
    }
  };

  const handletoggleDropdown = () => {
    // if (isDropdownOpen)
    // else setIsDropdownOpen(!isDropdownOpen);
    // console.log("Dropdown clicked", isDropdownOpen);

    setIsDropdownOpen(!isDropdownOpen);
  };

  const OrderStatusIndicatorCard: React.FC<OrderStatusIndicatorCardProps> = ({
    item,
    index,
  }) => {
    const orderStatusItem = findByKey(orderStatusData, "status", item.status);
    const isPending: boolean = !orderStatusItem || !orderStatusItem.status_date;

    return (
      <div
        className="order-status-card-dropdown-content-container"
        key={`status.shipment_id-${index}`}>
        {/* Left */}
        <div className="order-status-card-dropdown-content-left-container">
          <div className="order-status-card-dropdown-content-left-svg-container">
            {isPending ? (
              <InProgressIndicatorSvg
                width={32}
                height={32}
                inProgress={isPending}
              />
            ) : (
              <IndicatorSvg width={32} height={32} />
            )}
            {index < Object.keys(statusConfig).length - 1 &&
              (isPending ? (
                <div className="order-status-card-vertical-line" />
              ) : (
                <div className="order-status-card-vertical-line-pending" />
              ))}
          </div>
        </div>

        {/* Right */}
        <div className="order-status-card-dropdown-content-right-container">
          <div className="order-status-card-dropdown-content-right-text-container">
            <span className="order-status-card-dropdown-content-right-row-one-text">
              {item.name}
            </span>
            {orderStatusItem?.status_date && (
              <span className="order-status-card-dropdown-content-right-row-two-text">
                {convertTimestamp(orderStatusItem.status_date, true)}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="order-status-card-container">
      <div className="order-status-card-header">
        {orderItemCount} {orderItemCount === 1 ? "Item" : "Items"}
        {" - "}
        {capitalizeFirstLetter(
          currentStatus === "pending" ||
            currentStatus === "accepted" ||
            currentStatus === "pickup"
            ? "Order Confirmed"
            : currentStatus,
        )}
      </div>
      <div className="order-status-card-order-id">#{orderNum}</div>
      <div className="order-status-card-buyer-name">
        {buyerDetails?.buyer_name}
      </div>
      <div className="order-status-card-buyer-address">
        {buyerDetails?.buyer_address}
      </div>
      <div
        className="order-status-dropdown-card-header-left"
        onClick={handletoggleDropdown}>
        <div className="order-status-dropdown-card-header-left-row-two-text">
          {capitalizeFirstLetter(
            currentStatus === "pending" ||
              currentStatus === "accepted" ||
              currentStatus === "pickup"
              ? "Order Confirmed"
              : currentStatus,
          )}
        </div>

        <div className="dropdown-svg-container">
          <DropdownSvg width={12} height={12} color="#888" />
        </div>
      </div>

      {isDropdownOpen && (
        <div className="order-status-card-dropdown-content">
          {Object.entries(statusConfig).map(([, item], index) => (
            <OrderStatusIndicatorCard item={item} index={index} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderStatusCard;
