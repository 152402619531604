import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const StarFilledSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1194_699"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill={color ? color : "#D9D9D9"} />
      </mask>
      <g mask="url(#mask0_1194_699)">
        <path
          d="M12 16.689L8.10976 19.035C7.96476 19.1196 7.81951 19.1552 7.67401 19.1417C7.52851 19.1282 7.39684 19.0792 7.27901 18.9947C7.16101 18.9101 7.06993 18.7995 7.00576 18.663C6.94176 18.5265 6.93151 18.3755 6.97501 18.21L8.00776 13.7927L4.57326 10.8197C4.44493 10.7082 4.36318 10.5791 4.32801 10.4322C4.29268 10.2854 4.30193 10.1427 4.35576 10.0042C4.40959 9.86589 4.48718 9.75281 4.58851 9.66498C4.68984 9.57714 4.82834 9.51973 5.00401 9.49273L9.53651 9.09673L11.2963 4.92548C11.3603 4.77031 11.4573 4.65556 11.5875 4.58123C11.7177 4.50689 11.8552 4.46973 12 4.46973C12.1448 4.46973 12.2823 4.50689 12.4125 4.58123C12.5427 4.65556 12.6398 4.77031 12.7038 4.92548L14.4635 9.09673L18.996 9.49273C19.1717 9.51973 19.3102 9.57714 19.4115 9.66498C19.5128 9.75281 19.5904 9.86589 19.6443 10.0042C19.6981 10.1427 19.7073 10.2854 19.672 10.4322C19.6368 10.5791 19.5551 10.7082 19.4268 10.8197L15.9923 13.7927L17.025 18.21C17.0685 18.3755 17.0583 18.5265 16.9943 18.663C16.9301 18.7995 16.839 18.9101 16.721 18.9947C16.6032 19.0792 16.4715 19.1282 16.326 19.1417C16.1805 19.1552 16.0353 19.1196 15.8903 19.035L12 16.689Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default StarFilledSvg;
