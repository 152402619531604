import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const ZoopFooterSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 98 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.53839 20.7641C1.83663 20.1851 0.00158243 16.9405 0.00158243 14.4213C0.00158243 11.5543 2.36229 7.96191 6.76228 7.96191H18.4486C23.17 7.96191 25.0937 11.9304 25.0937 14.4496C25.0937 15.6942 24.7153 16.9987 23.8983 18.1568L19.5854 24.0355C23.17 24.5862 25.1222 27.7144 25.1222 30.3501C25.1222 33.217 22.8486 36.7795 18.4185 36.7795H6.7322C2.24354 36.7795 0 33.1305 0 30.3202C0 29.0755 0.378408 27.7711 1.22389 26.6413L5.5368 20.761L5.53839 20.7641Z"
        fill="#9CA3AF"
      />
      <path
        d="M88.9372 25.6212V27.2136C88.9372 30.0805 86.6351 33.643 82.205 33.643C77.7749 33.643 75.4728 29.994 75.4728 27.1837V11.2533C75.4728 8.38631 77.8335 4.79395 82.2335 4.79395H86.9834C94.1241 4.79395 98 10.007 98 15.2784C98 20.0288 94.7653 24.7494 88.9372 25.618V25.6212Z"
        fill="#9CA3AF"
      />
      <path
        d="M60.9175 30.76C52.4072 30.76 45.9379 24.6484 45.9379 16.337C45.9379 8.02565 52.4072 1.94238 60.9175 1.94238C69.4277 1.94238 75.8686 8.05397 75.8686 16.337C75.8686 24.6201 69.4578 30.76 60.9175 30.76Z"
        fill="#9CA3AF"
      />
      <path
        d="M34.4335 28.8176C25.9233 28.8176 19.4539 22.706 19.4539 14.3946C19.4539 6.08326 25.9233 0 34.4335 0C42.9438 0 49.3846 6.11158 49.3846 14.3946C49.3846 22.6777 42.9739 28.8176 34.4335 28.8176Z"
        fill="#D1D5DB"
      />
      <path
        d="M46.9877 6.34277C47.4706 11.8769 44.4069 17.7225 38.5297 21.7193C33.8035 24.9324 28.5739 26.015 24.0884 25.0331C26.742 27.4123 30.3535 28.8175 34.4337 28.8175C42.9724 28.8175 49.3848 22.7641 49.3848 14.3945C49.3848 11.3529 48.5108 8.60394 46.9877 6.34435V6.34277Z"
        fill="#6B7280"
      />
      <path
        d="M24.8403 9.44588C24.8403 9.44588 28.314 4.58053 31.5519 3.40353C31.5519 3.40353 32.9737 2.06918 29.5775 2.77569C26.1829 3.48221 23.971 7.40502 23.8127 8.42624C23.6544 9.44588 24.3653 10.3884 24.8387 9.44588H24.8403Z"
        fill="white"
      />
      <path
        d="M22.7488 12.1749C23.2717 12.1749 23.6956 11.7536 23.6956 11.2339C23.6956 10.7143 23.2717 10.293 22.7488 10.293C22.2259 10.293 21.802 10.7143 21.802 11.2339C21.802 11.7536 22.2259 12.1749 22.7488 12.1749Z"
        fill="white"
      />
      <path
        d="M34.6582 28.3848C34.6139 29.4044 34.7184 30.4744 35.2741 31.332C35.5258 31.7222 35.8615 32.0527 36.1291 32.4319C36.6516 33.173 36.8986 34.1187 36.7181 35.0046C36.5376 35.8905 35.9027 36.693 35.0477 36.9998"
        stroke="#9CA3AF"
        strokeWidth="0.239572"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4102 28.1933C34.4102 28.1933 34.4212 28.6544 34.2217 28.8448C34.0824 28.9785 33.6708 29.4994 34.0777 29.405C34.5653 29.2917 34.9722 29.353 35.2366 29.3577C35.5011 29.3625 35.5976 29.3373 35.3665 29.0761C35.2651 28.9628 35.2731 28.8605 35.2968 28.7582C35.5359 27.7512 34.4102 28.1933 34.4102 28.1933Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default ZoopFooterSvg;
