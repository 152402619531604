import React from "react";
import "./SelectAddressV2.css";

interface FooterProps {
  onClose: () => void;
}

const Footer: React.FC<FooterProps> = ({onClose}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: "3%",
        width: window.innerWidth > 700 ? "94%" : "90%",
        padding: "0.5rem 1.25rem 0.5rem 1.25rem",
        boxShadow: "0px -4px 20px 0px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        background: "white",
        // maxWidth: "1000px",
      }}>
      <div className="selectAddressV2-action-wrapper">
        <button className="selectAddressV2-negative-button" onClick={onClose}>
          Add new address
        </button>
      </div>
    </div>
  );
};

export default Footer;
