/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getLiveStreams} from "./LiveStreamApi";
import {GetStreamDetailsResponse, LiveState} from "./LiveStreamInterface";

const initialState: LiveState = {
  loading: false,
  error: null,
  success: false,
  streams: [],
};

/* eslint no-empty: "error" */
export const getLiveStreamsThunk = createAsyncThunk(
  "liveStream/getLiveStreams",
  async (_: any, {rejectWithValue}) => {
    try {
      const response: GetStreamDetailsResponse = await getLiveStreams();
      // console.log("slice", response.data)

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const liveStreamSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getLiveStreamsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLiveStreamsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action?.payload?.data) {
          state.streams = action.payload.data;
        }
      })
      .addCase(getLiveStreamsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default liveStreamSlice.reducer;
export const {reset} = liveStreamSlice.actions;
