import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {
  CategoryProductsProps,
  // GetProductsByFilterPayload,
  GetProductsPayload,
  Product,
  ProductState,
  SearchApiPayload,
  SearchProductsProps,
} from "./ProductInterface";
import {
  getProducts,
  getProductById,
  // getProductSkuById,
  searchApi,
  // getProductsByFilterApi,
} from "./ProductAPI";
import {removeDuplicatesByKey} from "../../utils/helpers";

// Category data structure
interface CategoryProductsData {
  page: number;
  products: Product[];
  net_count: number;
  limit: number;
}

export const initialProductState: ProductState = {
  storeProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 0,
  },
  categoryProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 0,
  },
  searchProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 10,
  },
  product: {
    data: null,
    loading: false,
  },
  loading: false,
  error: null,
};

// Filter products and remove duplicates
const getFilteredProducts = (
  previousProducts: Product[],
  data: CategoryProductsData,
): Product[] => {
  let tempProducts =
    data.page === 1
      ? [...data.products]
      : [...previousProducts, ...data.products];

  tempProducts = removeDuplicatesByKey(tempProducts, "id");

  return tempProducts;
};

export const getCategoryProductsThunk = createAsyncThunk(
  "getCategoryProducts",
  async (payload: GetProductsPayload, {rejectWithValue}) => {
    try {
      const response = await getProducts(payload);
      return response;
    } catch (error) {
      console.error("Error fetching products:", error); // Log the error
      return rejectWithValue("Failed to fetch products");
    }
  },
);

export const getProductByIdThunk = createAsyncThunk(
  "getProductById",
  async (productId: number, {rejectWithValue}) => {
    try {
      const response = await getProductById(productId);
      return response;
    } catch (error) {
      console.error("Error fetching products:", error); // Log the error
      return rejectWithValue("Failed to fetch product");
    }
  },
);

export const getSearchThunk = createAsyncThunk(
  "products/getSearch",
  async (payload: SearchApiPayload, {rejectWithValue}) => {
    try {
      const response = await searchApi(payload);
      return response;
    } catch (error) {
      console.error("Error fetching products:", error); // Log the error
      return rejectWithValue("Failed to fetch product");
    }
  },
);

export const productSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    reset: () => initialProductState,
    setSearchProducts: (state, action: PayloadAction<SearchProductsProps>) => {
      state.searchProducts = action.payload;
    },
    setCategoryProducts: (
      state,
      action: PayloadAction<CategoryProductsProps>,
    ) => {
      state.categoryProducts = action.payload;
    },
  },
  extraReducers: builder => {
    builder

      // Get Category products
      .addCase(getCategoryProductsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoryProductsThunk.fulfilled, (state, action) => {
        const {data} = action.payload;

        state.loading = false;
        state.categoryProducts = {
          data: getFilteredProducts(state.categoryProducts.data, data),
          count: data.net_count,
          page: data.page,
          limit: data.limit,
        };
        state.error = null;
      })
      .addCase(getCategoryProductsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Product By ID
      .addCase(getProductByIdThunk.pending, state => {
        state.product.loading = true;
        state.error = null;
      })
      .addCase(getProductByIdThunk.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.product.loading = false;
        state.product.data = data.product;
        state.error = null;
      })
      .addCase(getProductByIdThunk.rejected, (state, action) => {
        state.product.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default productSlice.reducer;
export const {reset, setSearchProducts, setCategoryProducts} =
  productSlice.actions;
