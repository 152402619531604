import React, {FC} from "react";
import "./ChatSuggestionModal.style.css";

type User = {
  uname: string;
  color?: string;
};

type ChatSuggestionModalProps = {
  users: User[];
  onSelect: (username: string) => void;
};

const ChatSuggestionModal: FC<ChatSuggestionModalProps> = ({
  users,
  onSelect,
}) => {
  return (
    <div className="ChatSuggestionModal__modal">
      <ul>
        {users.map((user, index) => (
          <li key={index} onClick={() => onSelect(user.uname)}>
            {user.uname}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatSuggestionModal;
