import React from "react";
import CircularAvatar from "../CircularAvatar/CircularAvatar";
import "./MultipleProductCart.css";
import {BottomButton} from "../Button/Button";
import {GroupedStore} from "../../features/Cart/CartInterface";
import {groupProductsByStore} from "../../utils/helpers";
import {useNavigate} from "react-router";
import {convertIntoINRV2} from "../../utils/helpers";
interface SellerStoreCardProps {
  cartDetails: Array<{
    id: number;
    cartId: number;
    product_id: number;
    product_pricing_details_id: number;
    quantity: number;
    price: string;
    product_price: string;
    product_selling_price: string;
    product_name: string;
    product_description: string;
    seller_store_id: string;
    store_profile_url?: string;
    store_name: string;
    image: string;
  }>;
  selectedProduct: GroupedStore | null;
}

const MultipleProductCart: React.FC<SellerStoreCardProps> = ({cartDetails}) => {
  const navigate = useNavigate();

  const handleProceedCheckout = (cart: GroupedStore) => {
    navigate(`/checkout/${cart.store_id}`);
  };

  // Group products by store using the helper function
  const cartArray: GroupedStore[] = groupProductsByStore(cartDetails);

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log("Image failed to load, setting fallback...");
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png";
  };
  return (
    <>
      {cartArray.map((store, storeIndex) => (
        <div className="seller_store_card_container" key={storeIndex}>
          <div className="seller_store_card_header_container">
            {/* Store Header */}
            <div className="seller_store_card_header_left_container">
              <div className="seller_store_image_container">
                <CircularAvatar
                  imageSource={
                    store?.store_profile_url ||
                    "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/sellerprofile.png"
                  }
                />
              </div>
              <div className="seller_store_name_container">
                <p className="seller_store_name_text">{store?.store_name}</p>
              </div>
            </div>
          </div>

          {/* Render Products for the Store */}
          {store.products.map((cart, productIndex) => (
            <div
              className="cart-product-container"
              key={`Product-${store.store_id}-${productIndex}`}>
              {/* Left */}
              <div className="cart-product-left-container">
                <img
                  src={cart.image}
                  alt={cart.product_name}
                  onError={handleError}
                  className="MultipleProductCart-product-image"
                  onClick={() => {
                    navigate(`/product/${cart.product_id}`);
                  }}
                />
              </div>

              {/* Right */}
              <div className="cart-product-right-container">
                <p
                  className="MultipleProductCart-product-name"
                  title={cart.product_name}>
                  {cart.product_name}
                </p>
                <p
                  className="MultipleProductCart-product-description"
                  title={cart.product_description}>
                  {cart.product_description}
                </p>
                <p className="MultipleProductCart-price-text">
                  {convertIntoINRV2(cart.product_selling_price)}{" "}
                  {Number(cart.product_price) >
                    Number(cart.product_selling_price) && (
                    <span className="mrp-text">
                      <span className="line-through">
                        {convertIntoINRV2(cart.product_price)}
                      </span>{" "}
                      <span className="discount-text">
                        {Math.round(
                          ((Number(cart.product_price) -
                            Number(cart.product_selling_price)) *
                            100) /
                            Number(cart.product_price),
                        )}
                        % off
                      </span>
                    </span>
                  )}
                </p>
              </div>
            </div>
          ))}

          {/* Checkout Button */}
          <BottomButton
            onButtonClick={() => handleProceedCheckout(store)}
            label="Proceed to Checkout"
            color=""
          />
        </div>
      ))}
    </>
  );
};

export default MultipleProductCart;
