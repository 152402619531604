import React, {useState, useEffect, CSSProperties, ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./Carousel.css";

type CarouselProps = {
  children: ReactNode;
  jumpToIndex?: number;
  carouselStyle?: CSSProperties;
  showIndicator?: boolean;
};

const Carousel: React.FC<CarouselProps> = ({
  children,
  jumpToIndex,
  carouselStyle = {},
  showIndicator = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const childrenCount = React.Children.count(children);

  useEffect(() => {
    if (
      jumpToIndex !== undefined &&
      jumpToIndex >= 0 &&
      jumpToIndex < childrenCount
    ) {
      setCurrentIndex(jumpToIndex);
    }
  }, [jumpToIndex, childrenCount]);

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % childrenCount);
  };

  const handlePrev = () => {
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + childrenCount) % childrenCount,
    );
  };

  return (
    <div className="carousel" style={carouselStyle}>
      <button onClick={handlePrev} className="carousel-button prev">
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="carousel-content">
        {React.Children.map(children, (child, index) => (
          <div
            className={`carousel-item ${index === currentIndex ? "active" : ""}`}
            style={{display: index === currentIndex ? "" : "none"}}>
            {child}
          </div>
        ))}
      </div>
      <button onClick={handleNext} className="carousel-button next">
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      {showIndicator && childrenCount > 1 && (
        <div className="carousel-indicator">
          <div className="dots-container">
            {React.Children.map(children, (_, index) => (
              <div
                className={`dot ${index === currentIndex ? "active" : ""}`}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
