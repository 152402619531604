import React from "react";
import "./StoreInfo.style.css";
import RatingSvg from "../../../../../components/SvgImages/RatingSvg/RatingSvg";
import BoughtPastMonthSvg from "../../../../../components/SvgImages/BoughtPastMonthSvg/BoughtPastMonthSvg";
import DeliverySvg from "../../../../../components/SvgImages/DeliverySvg/DeliverySvg";
import ShareSvg from "../../../../../components/SvgImages/ShareSvg/ShareSvg";
import StoreViewsSvg from "../../../../../components/SvgImages/StoreViewsSvg/StoreViewsSvg";

interface StoreInfoProps {
  storeName: string | undefined;
  freeDeliveryAmount: number | undefined;
  email: string | undefined;
  profilePhoto: string | undefined;
  isActive: boolean | undefined;
  storeRating: number | undefined;
  pastMonthOrder: string | undefined;
  storeDescription: string | undefined;
  productCount: number;
}

const StoreInfo: React.FC<StoreInfoProps> = ({
  storeName,
  freeDeliveryAmount,
  profilePhoto,
  isActive,
  storeRating,
  pastMonthOrder,
  storeDescription,
  productCount,
}) => {
  return (
    <section className="storefront-store-info">
      <div className="storefront-store-info-store-header">
        <img
          src={profilePhoto}
          alt="Store Profile"
          className="storefront-store-info-store-avatar"
        />
        <div className="storefront-store-info-store-details">
          <div
            className="storefront-store-info-store-name text-truncate"
            style={{textAlign: "left"}}>
            {storeName}
          </div>
          {storeDescription && (
            <div
              className="storefront-store-info-store-description text-truncate"
              style={{textAlign: "left"}}>
              {storeDescription}
            </div>
          )}

          {/* <p className="storefront-store-info-store-category">Fashion </p> */}
        </div>
        <div
          style={{display: "flex", flexDirection: "row", columnGap: "0.5rem"}}>
          <div className="storefront-store-info-store-status">
            {isActive ? "Active" : "Inactive"}
          </div>
          <div
            onClick={() => {
              const url = window.location.href; // Detect the current hosted URL
              if (navigator.share) {
                navigator
                  .share({
                    title: storeName ?? "Check out!!!",
                    text: `Check out ${storeName ?? ""} on Zoop :`,
                    url: url, // Use the detected URL
                  })
                  .then(() => console.log("Shared successfully!"))
                  .catch(error => console.error("Error sharing:", error));
              } else {
                alert("Your browser does not support the Web Share API.");
              }
            }}>
            <ShareSvg height={25} width={25} />
          </div>
        </div>
      </div>

      <div className="storefront-store-info-store-stats">
        <div className="storefront-store-info-stat">
          <RatingSvg height={24} width={24} />
          <p className="storefront-store-info-stat-number">
            {storeRating || "N/A"}
          </p>
          <p className="storefront-store-info-stat-label">Rating</p>
        </div>
        <div className="storefront-store-info-stat">
          <StoreViewsSvg height={24} width={24} />
          <div className="storefront-store-info-stat-number">
            {productCount}
          </div>
          <div className="storefront-store-info-stat-label">Total products</div>
        </div>
        <div className="storefront-store-info-stat">
          <BoughtPastMonthSvg height={24} width={24} />
          <div className="storefront-store-info-stat-number">
            {pastMonthOrder || "N/A"}
          </div>
          <div className="storefront-store-info-stat-label">Orders shipped</div>
        </div>
      </div>

      {/* Free Delivery Section */}
      {freeDeliveryAmount && (
        <div className="storefront-store-info-free-delivery">
          <div className="storefront-store-info-free-delivery-svg">
            <DeliverySvg height={24} width={24} />
          </div>

          <div className="storefront-store-info-free-delivery-para">
            Free delivery above ₹{freeDeliveryAmount || "N/A"}
          </div>
        </div>
      )}
    </section>
  );
};

export default StoreInfo;
