import React from "react";
import "../App.css";
import "./Home.css";
import CategorySection from "../components/Category/Category";
import RecommendedProduct from "../components/RecommendedProduct/RecommendProduct";
import {LiveCardComponent} from "../components/LiveCards/LiveCard";
import StoresCardSection from "../components/Stores/Stores";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15%",
        }}>
        <span className="live-card-bg"></span>
        <Hero />
      </div>
    </div>
  );
};

export default Home;

const stripColor = [
  "#60ABB8",
  "#74B6C1",
  "#88C0CA",
  "#9CCBD3",
  "#AFD5DB",
  "#C3E0E4",
  "#D7EAED",
  "#EBF5F6",
];

const Hero = () => {
  return (
    <>
      <div className="gradient-background">
        {/* Add stripes */}
        {[...Array(8)].map((_, index) => (
          <div
            key={index}
            className="gradient-stripe"
            style={{
              top: `${index * 12}%`,
              backgroundColor: `${stripColor[index]}`,
            }}
          />
        ))}

        {/* Main content */}
        <div className="content-container">
          <div className="main-title">
            Welcome to the
            <br />
            <span className="brand-name">zoop</span> Universe
          </div>
          <CategorySection />
          <RecommendedProduct />
          <LiveCardComponent />
          <StoresCardSection />
          <Footer />
        </div>
      </div>
    </>
  );
};
