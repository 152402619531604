import React, {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Home.style.css";
import {useNavigate} from "react-router";
import Carousel from "../../../components/mobile/Carousel/Carousel";
import Loader from "../../../Loader/Loader";
import LiveStream from "../../../components/mobile/LiveStream/LiveStream";
import {useSelector} from "react-redux";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import {LiveStreamProps} from "../../../features/LiveStream/LiveStreamInterface";
import {RootState} from "../../../interceptors/store";

const MobileHome: React.FC = () => {
  const [liveStreams, setLiveStreams] = useState<LiveStreamProps[]>([]);
  const [initialIndex, setInitialIndex] = useState<number>(0);

  const {streams, loading: streamsLoading} = useSelector(
    (state: RootState) => state.liveStream,
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (streams && streams.length > 0) {
      populateStreams();
    }
  }, [streams]);

  const populateStreams = async () => {
    let tempInitialIndex = initialIndex;
    for (let index = 0; index < streams.length; index++) {
      const item = streams[index];
      if (item.status === "open") {
        tempInitialIndex = index;
      }
      if (item.status === "in_progress" || item.status === "url_generated") {
        tempInitialIndex = index;
        break;
      }
    }
    setInitialIndex(tempInitialIndex);
    setLiveStreams(streams);
  };

  const handleEnterStreamPage = (streamId: string) => {
    ReactGA.event({
      category: "User",
      action: "Enter Stream 1",
      label: `Enter Stream by ${localStorage.getItem("userId")} on ${streamId}`,
      value: 1,
      nonInteraction: true,
      // @ts-expect-error: Ignoring the type check for custom dimensions
      dimension1: location.pathname + location.search,
      dimension2: localStorage.getItem("userId") || null,
      dimension3: streamId,
    });
    navigate(`/stream/${streamId}`);
  };

  if (streamsLoading) {
    return <Loader />;
  }

  return (
    <div className="app">
      <div style={{maxWidth: "1000px", alignSelf: "center"}}>
        <div className="live-streams-container">
          <Carousel jumpToIndex={initialIndex}>
            {liveStreams.map((item, index) => (
              <div
                key={index}
                className="live-stream-container"
                style={{display: item.isActive === 1 ? "flex" : "none"}}>
                <LiveStream
                  item={item}
                  key={item.id}
                  streamId={item.id?.toString()}
                  showCountdown={true}
                  onHandleStreamPage={() =>
                    handleEnterStreamPage(item.id?.toString())
                  }
                  showSaleProducts={false}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default MobileHome;
