import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const ExpandLessSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1194_3679"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1194_3679)">
        <path
          d="M10 8.69909L6.60575 12.0933C6.49037 12.2087 6.34535 12.2678 6.17067 12.2704C5.996 12.2731 5.84831 12.2141 5.72758 12.0933C5.60685 11.9726 5.54648 11.8263 5.54648 11.6543C5.54648 11.4822 5.60685 11.3359 5.72758 11.2151L9.47277 7.46996C9.55076 7.39197 9.63302 7.33695 9.71956 7.3049C9.80609 7.27286 9.89957 7.25684 10 7.25684C10.1004 7.25684 10.1939 7.27286 10.2804 7.3049C10.367 7.33695 10.4492 7.39197 10.5272 7.46996L14.2724 11.2151C14.3878 11.3305 14.4468 11.4756 14.4495 11.6502C14.4522 11.8249 14.3932 11.9726 14.2724 12.0933C14.1517 12.2141 14.0053 12.2744 13.8333 12.2744C13.6613 12.2744 13.515 12.2141 13.3942 12.0933L10 8.69909Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default ExpandLessSvg;
