import React from "react";
import "./RecommendProductSkeleton.css";

export const RecommendProductSkeleton = () => {
  return (
    <div className="recommended-product-card skeleton">
      <div className="recommended-card-image">
        <div className="skeleton-image"></div>
        {/* <div className="skeleton-discount-badge"></div> */}
        <div className="skeleton-rating">
          <div className="skeleton-rating-value"></div>
          <div className="skeleton-reviews"></div>
        </div>
      </div>
      <div className="recommended-card-content">
        <div className="skeleton-name"></div>
        {/* <div className="skeleton-description"></div> */}
        <div className="recommended-price-section">
          <div className="skeleton-price"></div>
          <div className="skeleton-original-price"></div>
        </div>
      </div>
    </div>
  );
};
