import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const StoreHomeSvg: React.FC<SvgProps> = ({width, height, color}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 15" fill="none">
      <path
        d="M1.99998 12.8334H4.78846V8.63464C4.78846 8.42123 4.86064 8.24235 5.005 8.09799C5.14936 7.95363 5.32825 7.88145 5.54167 7.88145H8.45829C8.67171 7.88145 8.8506 7.95363 8.99496 8.09799C9.13932 8.24235 9.2115 8.42123 9.2115 8.63464V12.8334H12V5.46157C12 5.41884 11.9906 5.38011 11.9719 5.34539C11.9532 5.31067 11.9279 5.27995 11.8958 5.25324L7.15223 1.68753C7.10949 1.65014 7.05874 1.63145 6.99998 1.63145C6.94122 1.63145 6.89047 1.65014 6.84773 1.68753L2.10415 5.25324C2.0721 5.27995 2.04673 5.31067 2.02802 5.34539C2.00933 5.38011 1.99998 5.41884 1.99998 5.46157V12.8334ZM0.75 12.8334V5.46157C0.75 5.22306 0.803354 4.9971 0.910062 4.7837C1.01677 4.5703 1.16427 4.39455 1.35256 4.25647L6.09615 0.682761C6.35938 0.4819 6.66023 0.38147 6.99869 0.38147C7.33715 0.38147 7.63885 0.4819 7.90381 0.682761L12.6474 4.25647C12.8357 4.39455 12.9832 4.5703 13.0899 4.7837C13.1966 4.9971 13.25 5.22306 13.25 5.46157V12.8334C13.25 13.1742 13.1268 13.4677 12.8806 13.714C12.6343 13.9602 12.3408 14.0833 12 14.0833H8.71473C8.50133 14.0833 8.32244 14.0112 8.17806 13.8668C8.0337 13.7224 7.96152 13.5435 7.96152 13.3301V9.13141H6.03844V13.3301C6.03844 13.5435 5.96626 13.7224 5.8219 13.8668C5.67752 14.0112 5.49863 14.0833 5.28523 14.0833H1.99998C1.65917 14.0833 1.36565 13.9602 1.1194 13.714C0.873132 13.4677 0.75 13.1742 0.75 12.8334Z"
        fill={color ? color : "#938E9E"}
      />
    </svg>
  );
};

export default StoreHomeSvg;
