import React, {useEffect} from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  matchPath,
} from "react-router-dom";
import useMobileSize from "./hooks/useMobileSize";
import "./App.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import "promise-polyfill/src/polyfill";
import {
  // getCategoriesThunk,
  getFeaturedStoresThunk,
  getUserThunk,
  getZoopRecommendsThunk,
} from "./features/User/UserSlice";
import {setAuthentication} from "./features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import {getLiveStreamsThunk} from "./features/LiveStream/LiveStreamSlice";
import {AppDispatch, RootState} from "./interceptors/store";

import MobileHome from "./pages/mobile/Home/MobileHome";
import DesktopHome from "./pages/Home";
import Stream from "./pages/mobile/Stream/Stream";
import Login from "./pages/mobile/Login";
import StreamVideo from "./pages/mobile/StreamVideo/StreamVideo";
import PaymentConfirmation from "./pages/mobile/PaymentConfirmation/PaymentConfirmation";
import ProductDetail from "./pages/mobile/ProductDetail/ProductDetail";
import OrdersPage from "./pages/mobile/OrdersPage/OrdersPage";
import CartPage from "./pages/mobile/Cart/Cart";
import OrderDetailsPage from "./pages/mobile/OrderDetailsPage/OrderDetailsPage";
import Header from "./components/mobile/Header/Header";
import Storefront from "./pages/mobile/Storefront/Storefront";
import SellerCart from "./pages/mobile/Cart/CheckOutCart";
import cookieUtils from "./utils/cookies";
import ProductsPage from "./pages/mobile/ProductsPage/ProductsPage";
import {Searchpage} from "./pages/mobile/SearchPage/SearchPage";

const excludeHeader: string[] = ["/login", "/stream/:id/live"];

// Initialize Google Analytics with your Tracking ID
ReactGA.initialize(process.env.REACT_APP_TRACKING_NUMBER);

const App: React.FC = () => {
  const isMobile = useMobileSize(1000);
  const dispatch = useDispatch<AppDispatch>();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  const onPageLoad = () => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }

    fetchProductRecommends();
    fetchLiveStreams();
    fetchFeaturedStores();
  };

  const fetchLiveStreams = async () => {
    try {
      const action = await dispatch(getLiveStreamsThunk({}));
      if (getLiveStreamsThunk.fulfilled.match(action)) {
        // Success handling here
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProductRecommends = async () => {
    try {
      const action = await dispatch(
        getZoopRecommendsThunk({key_name: "product_pricing_id", limit: 8}),
      );
      if (getZoopRecommendsThunk.fulfilled.match(action)) {
        // Success handling here
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFeaturedStores = async () => {
    try {
      const action = await dispatch(
        getFeaturedStoresThunk({key_name: "store_id", limit: 8}),
      );
      if (getFeaturedStoresThunk.fulfilled.match(action)) {
        // Success handling here
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      isSignedIn,
      isMobile,
      userId: localStorage.getItem("userId") || null,
    });
  }, [isSignedIn]);

  const fetchUser = async () => {
    try {
      const action = await dispatch(getUserThunk());
      if (getUserThunk.fulfilled.match(action)) {
        const data = action.payload?.user;

        if (
          data &&
          data.first_name &&
          data.last_name &&
          data.email &&
          data.user_name
        ) {
          localStorage.setItem("username", data?.user_name);
          localStorage.setItem("showChat", "true");
          localStorage.setItem("switchMobileView", "true");
          if (location.pathname === "/") {
            navigate("/");
          }
        }
      } else {
        toast.error("Error fetching user");
        dispatch(setAuthentication(false));
      }
    } catch {
      toast.error("Error fetching user");
      dispatch(setAuthentication(false));
    }
  };

  const shouldRenderHeader = !excludeHeader.some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  const renderMobileView = !["/"].some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  return (
    <div className={renderMobileView ? "main-wrapper" : ""}>
      {shouldRenderHeader && <Header key="header-menu" />}

      <Routes>
        {/* {!isMobile ? (
          <>
            <Route path="/" element={<DesktopHome />} />
          </>
        ) : ( */}
        <>
          <Route path="/" element={<DesktopHome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/stream/:id" element={<Stream />} />
          <Route path="/stream/:id/live" element={<StreamVideo />} />
          <Route path="/payment" element={<PaymentConfirmation />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/checkout" element={<CartPage />} />
          <Route path="/orders/:orderId" element={<OrderDetailsPage />} />
          <Route path="*" element={<MobileHome />} />
          <Route path="/search" element={<Searchpage />} />
          <Route path="/storefront/:storeId" element={<Storefront />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/checkout/:storeId" element={<SellerCart />} />
        </>
        {/* )} */}
      </Routes>
    </div>
  );
};

export default App;
