/*global process */
/*eslint no-undef: "error"*/

export default {
  API_URL: process.env.REACT_APP_BACKEND_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  USER_URL: process.env.REACT_APP_USER_URL,
  INVENTORY_URL: process.env.REACT_APP_INVENTORY_URL,
  LIVE_STREAM_URL: process.env.REACT_APP_LIVESTREAM_URL,
  NOTIFICATION_URL: process.env.REACT_APP_NOTIFICATION_URL,
  STORAGE_URL: process.env.REACT_APP_STORAGE_URL,
  PAYMENT_URL: process.env.REACT_APP_PAYMENT_URL,
  CART_URL: process.env.REACT_APP_CART_URL,
  ORDER_URL: process.env.REACT_APP_ORDER_URL,
  JWT_PUBLIC_KEY: process.env.REACT_APP_JWT_PUBLIC_KEY,
  ALGOLIA_APPLICATION_ID: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  ALGOLIA_SEARCH_API_KEY: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
};
