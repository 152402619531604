import React from "react";

// export interface AppleStoreBadgegProps {
//     height: number | string;
//     width: number | string;
//     color?: string;
//     onClick?: () => void;
// }

export const AppleStoreBadge = function AppleStoreBadge() {
  return (
    <svg
      width="136"
      height="46"
      viewBox="0 0 136 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.0625"
        y="1.0625"
        width="133.875"
        height="43.875"
        rx="7.3125"
        fill="black"
      />
      <rect
        x="1.0625"
        y="1.0625"
        width="133.875"
        height="43.875"
        rx="7.3125"
        stroke="#A6A6A6"
        strokeWidth="1.125"
      />
      <path
        d="M92.2155 22.1014V24.6788H90.6V26.369H92.2155V32.1121C92.2155 34.0732 93.1027 34.8576 95.3346 34.8576C95.7268 34.8576 96.1003 34.8109 96.4272 34.7549V33.0833C96.147 33.1113 95.9696 33.13 95.6614 33.13C94.6622 33.13 94.2233 32.6631 94.2233 31.5985V26.369H96.4272V24.6788H94.2233V22.1014H92.2155Z"
        fill="white"
      />
      <path
        d="M102.113 34.9977C105.082 34.9977 106.903 33.0086 106.903 29.7122C106.903 26.4344 105.073 24.436 102.113 24.436C99.1431 24.436 97.3128 26.4344 97.3128 29.7122C97.3128 33.0086 99.1338 34.9977 102.113 34.9977ZM102.113 33.2141C100.366 33.2141 99.3859 31.9347 99.3859 29.7122C99.3859 27.5083 100.366 26.2196 102.113 26.2196C103.85 26.2196 104.84 27.5083 104.84 29.7122C104.84 31.9254 103.85 33.2141 102.113 33.2141Z"
        fill="white"
      />
      <path
        d="M108.461 34.8016H110.469V28.797C110.469 27.3682 111.543 26.4064 113.065 26.4064C113.42 26.4064 114.018 26.4718 114.186 26.5278V24.548C113.971 24.492 113.588 24.464 113.289 24.464C111.963 24.464 110.833 25.1924 110.544 26.1916H110.394V24.6321H108.461V34.8016Z"
        fill="white"
      />
      <path
        d="M119.171 26.1449C120.656 26.1449 121.627 27.1815 121.674 28.7783H116.538C116.65 27.1908 117.686 26.1449 119.171 26.1449ZM121.664 32.0561C121.291 32.8499 120.46 33.2888 119.246 33.2888C117.639 33.2888 116.603 32.1588 116.538 30.3752V30.2631H123.719V29.5628C123.719 26.369 122.01 24.436 119.18 24.436C116.313 24.436 114.492 26.4998 114.492 29.7495C114.492 32.9993 116.276 34.9977 119.19 34.9977C121.515 34.9977 123.14 33.8771 123.597 32.0561H121.664Z"
        fill="white"
      />
      <path
        d="M79.049 31.0462C79.2039 33.5433 81.2848 35.1404 84.3821 35.1404C87.6923 35.1404 89.7636 33.4659 89.7636 30.7945C89.7636 28.6942 88.5828 27.5327 85.7178 26.8648L84.1789 26.4874C82.3592 26.0615 81.6236 25.4904 81.6236 24.4935C81.6236 23.2352 82.7657 22.4125 84.4789 22.4125C86.105 22.4125 87.2277 23.2159 87.431 24.5032H89.541C89.4152 22.1512 87.3439 20.4961 84.5079 20.4961C81.4591 20.4961 79.4265 22.1512 79.4265 24.6387C79.4265 26.6906 80.5783 27.9102 83.1045 28.5006L84.9048 28.9361C86.7535 29.3717 87.5665 30.0105 87.5665 31.0752C87.5665 32.3141 86.2889 33.2142 84.5467 33.2142C82.6786 33.2142 81.3816 32.3722 81.1977 31.0462H79.049Z"
        fill="white"
      />
      <path
        d="M58.2508 24.464C56.8687 24.464 55.6734 25.155 55.057 26.313H54.9076V24.6321H52.9746V38.1821H54.9823V33.2608H55.1411C55.6734 34.3347 56.822 34.9697 58.2694 34.9697C60.8375 34.9697 62.4717 32.9433 62.4717 29.7122C62.4717 26.4811 60.8375 24.464 58.2508 24.464ZM57.6811 33.1674C56.0002 33.1674 54.945 31.8413 54.945 29.7215C54.945 27.5924 56.0002 26.2663 57.6905 26.2663C59.39 26.2663 60.4079 27.5643 60.4079 29.7122C60.4079 31.8693 59.39 33.1674 57.6811 33.1674Z"
        fill="white"
      />
      <path
        d="M69.4972 24.464C68.1151 24.464 66.9198 25.155 66.3034 26.313H66.154V24.6321H64.221V38.1821H66.2287V33.2608H66.3875C66.9198 34.3347 68.0684 34.9697 69.5158 34.9697C72.0839 34.9697 73.7181 32.9433 73.7181 29.7122C73.7181 26.4811 72.0839 24.464 69.4972 24.464ZM68.9275 33.1674C67.2466 33.1674 66.1914 31.8413 66.1914 29.7215C66.1914 27.5924 67.2466 26.2663 68.9368 26.2663C70.6364 26.2663 71.6543 27.5643 71.6543 29.7122C71.6543 31.8693 70.6364 33.1674 68.9275 33.1674Z"
        fill="white"
      />
      <path
        d="M49.3723 34.8016H51.6759L46.6332 20.8349H44.3005L39.2578 34.8016H41.484L42.7713 31.0946H48.0947L49.3723 34.8016ZM45.3556 23.3707H45.5201L47.543 29.2749H43.323L45.3556 23.3707Z"
        fill="white"
      />
      <path
        d="M40.6094 10.3003V17.0379H43.042C45.0498 17.0379 46.2124 15.8006 46.2124 13.6481C46.2124 11.5283 45.0404 10.3003 43.042 10.3003H40.6094ZM41.6553 11.2528H42.9253C44.3214 11.2528 45.1478 12.1399 45.1478 13.6621C45.1478 15.2076 44.3354 16.0854 42.9253 16.0854H41.6553V11.2528Z"
        fill="white"
      />
      <path
        d="M49.7731 17.136C51.2579 17.136 52.1684 16.1414 52.1684 14.4932C52.1684 12.8543 51.2533 11.8551 49.7731 11.8551C48.2883 11.8551 47.3732 12.8543 47.3732 14.4932C47.3732 16.1414 48.2837 17.136 49.7731 17.136ZM49.7731 16.2441C48.9 16.2441 48.4097 15.6045 48.4097 14.4932C48.4097 13.3913 48.9 12.7469 49.7731 12.7469C50.6416 12.7469 51.1365 13.3913 51.1365 14.4932C51.1365 15.5998 50.6416 16.2441 49.7731 16.2441Z"
        fill="white"
      />
      <path
        d="M59.9221 11.9532H58.9182L58.0124 15.8333H57.933L56.8871 11.9532H55.9253L54.8794 15.8333H54.8046L53.8942 11.9532H52.8763L54.277 17.0379H55.3089L56.3548 13.2932H56.4342L57.4848 17.0379H58.526L59.9221 11.9532Z"
        fill="white"
      />
      <path
        d="M61.0782 17.0379H62.082V14.0636C62.082 13.2699 62.5536 12.7749 63.296 12.7749C64.0384 12.7749 64.3933 13.1812 64.3933 13.9983V17.0379H65.3972V13.7461C65.3972 12.5368 64.7715 11.8551 63.6369 11.8551C62.8711 11.8551 62.3669 12.196 62.1194 12.7609H62.0447V11.9532H61.0782V17.0379Z"
        fill="white"
      />
      <path d="M66.9782 17.0379H67.982V9.96875H66.9782V17.0379Z" fill="white" />
      <path
        d="M71.7575 17.136C73.2423 17.136 74.1528 16.1414 74.1528 14.4932C74.1528 12.8543 73.2377 11.8551 71.7575 11.8551C70.2727 11.8551 69.3576 12.8543 69.3576 14.4932C69.3576 16.1414 70.2681 17.136 71.7575 17.136ZM71.7575 16.2441C70.8844 16.2441 70.3941 15.6045 70.3941 14.4932C70.3941 13.3913 70.8844 12.7469 71.7575 12.7469C72.626 12.7469 73.1209 13.3913 73.1209 14.4932C73.1209 15.5998 72.626 16.2441 71.7575 16.2441Z"
        fill="white"
      />
      <path
        d="M77.1439 16.2768C76.5976 16.2768 76.2007 16.0107 76.2007 15.5531C76.2007 15.1049 76.5182 14.8667 77.2186 14.82L78.4606 14.7407V15.1656C78.4606 15.7959 77.9003 16.2768 77.1439 16.2768ZM76.8871 17.1219C77.5548 17.1219 78.1104 16.8325 78.4139 16.3235H78.4933V17.0379H79.4598V13.564C79.4598 12.4901 78.7408 11.8551 77.4661 11.8551C76.3128 11.8551 75.491 12.4154 75.3883 13.2885H76.3595C76.4715 12.929 76.8591 12.7236 77.4194 12.7236C78.1058 12.7236 78.4606 13.0271 78.4606 13.564V14.0029L77.0832 14.0823C75.8739 14.157 75.1922 14.6846 75.1922 15.5998C75.1922 16.529 75.9066 17.1219 76.8871 17.1219Z"
        fill="white"
      />
      <path
        d="M82.8665 17.1219C83.5668 17.1219 84.1598 16.7904 84.4633 16.2348H84.5427V17.0379H85.5046V9.96875H84.5007V12.7609H84.426C84.1505 12.2006 83.5622 11.8691 82.8665 11.8691C81.5824 11.8691 80.756 12.887 80.756 14.4932C80.756 16.1041 81.5731 17.1219 82.8665 17.1219ZM83.1513 12.7703C83.9917 12.7703 84.5194 13.438 84.5194 14.4979C84.5194 15.5624 83.9964 16.2208 83.1513 16.2208C82.3015 16.2208 81.7926 15.5718 81.7926 14.4932C81.7926 13.424 82.3062 12.7703 83.1513 12.7703Z"
        fill="white"
      />
      <path
        d="M92.0143 17.136C93.4991 17.136 94.4096 16.1414 94.4096 14.4932C94.4096 12.8543 93.4945 11.8551 92.0143 11.8551C90.5295 11.8551 89.6144 12.8543 89.6144 14.4932C89.6144 16.1414 90.5249 17.136 92.0143 17.136ZM92.0143 16.2441C91.1412 16.2441 90.6509 15.6045 90.6509 14.4932C90.6509 13.3913 91.1412 12.7469 92.0143 12.7469C92.8828 12.7469 93.3778 13.3913 93.3778 14.4932C93.3778 15.5998 92.8828 16.2441 92.0143 16.2441Z"
        fill="white"
      />
      <path
        d="M95.7385 17.0379H96.7424V14.0636C96.7424 13.2699 97.214 12.7749 97.9564 12.7749C98.6988 12.7749 99.0536 13.1812 99.0536 13.9983V17.0379H100.057V13.7461C100.057 12.5368 99.4318 11.8551 98.2972 11.8551C97.5315 11.8551 97.0272 12.196 96.7797 12.7609H96.705V11.9532H95.7385V17.0379Z"
        fill="white"
      />
      <path
        d="M104.681 10.6878V11.9765H103.873V12.8216H104.681V15.6932C104.681 16.6737 105.124 17.0659 106.24 17.0659C106.437 17.0659 106.623 17.0426 106.787 17.0146V16.1788C106.647 16.1928 106.558 16.2021 106.404 16.2021C105.904 16.2021 105.685 15.9687 105.685 15.4364V12.8216H106.787V11.9765H105.685V10.6878H104.681Z"
        fill="white"
      />
      <path
        d="M108.134 17.0379H109.138V14.0683C109.138 13.2979 109.596 12.7796 110.417 12.7796C111.127 12.7796 111.505 13.1905 111.505 14.0029V17.0379H112.509V13.7555C112.509 12.5461 111.842 11.8598 110.758 11.8598C109.993 11.8598 109.456 12.2006 109.208 12.7703H109.129V9.96875H108.134V17.0379Z"
        fill="white"
      />
      <path
        d="M116.131 12.7096C116.873 12.7096 117.359 13.2278 117.382 14.0263H114.814C114.87 13.2325 115.388 12.7096 116.131 12.7096ZM117.377 15.6652C117.191 16.062 116.775 16.2815 116.168 16.2815C115.365 16.2815 114.847 15.7165 114.814 14.8247V14.7687H118.405V14.4185C118.405 12.8216 117.55 11.8551 116.135 11.8551C114.702 11.8551 113.791 12.887 113.791 14.5119C113.791 16.1367 114.683 17.136 116.14 17.136C117.303 17.136 118.115 16.5757 118.344 15.6652H117.377Z"
        fill="white"
      />
      <path
        d="M28.3657 23.3385C28.3901 21.4473 29.4057 19.6579 31.0169 18.6674C30.0005 17.2158 28.298 16.2954 26.5268 16.24C24.6377 16.0417 22.8062 17.3704 21.8435 17.3704C20.8622 17.3704 19.38 16.2597 17.784 16.2925C15.7036 16.3597 13.7641 17.5425 12.7519 19.3614C10.5762 23.1283 12.1991 28.6645 14.2833 31.7095C15.3261 33.2005 16.5447 34.866 18.1394 34.8069C19.6998 34.7422 20.2826 33.8119 22.1662 33.8119C24.0323 33.8119 24.5791 34.8069 26.2061 34.7694C27.8805 34.7422 28.9355 33.2717 29.9417 31.7666C30.6909 30.7042 31.2674 29.53 31.6499 28.2875C29.6821 27.4553 28.368 25.475 28.3657 23.3385Z"
        fill="white"
      />
      <path
        d="M25.2926 14.2375C26.2056 13.1415 26.6554 11.7328 26.5464 10.3105C25.1516 10.457 23.8632 11.1237 22.9379 12.1776C22.0331 13.2074 21.5622 14.5913 21.6512 15.9592C23.0466 15.9735 24.4183 15.325 25.2926 14.2375Z"
        fill="white"
      />
    </svg>
  );
};
