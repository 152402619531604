import {useEffect, useRef, useState} from "react";

type UsePollingReturn = {
  isPolling: boolean;
  startPolling: () => void;
  stopPolling: () => void;
  pollCount: number;
};

const usePolling = (
  callback: () => void,
  delay: number,
  maxPolls: number,
): UsePollingReturn => {
  const savedCallback = useRef<(() => void) | null>(null);
  const [pollCount, setPollCount] = useState<number>(0);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    if (isPolling && pollCount < maxPolls) {
      const id = setInterval(() => {
        if (savedCallback.current) {
          savedCallback.current();
          setPollCount(prevCount => prevCount + 1);
        }
      }, delay);

      return () => clearInterval(id);
    }
  }, [isPolling, delay, pollCount, maxPolls]);

  const startPolling = () => {
    setPollCount(0);
    setIsPolling(true);
  };

  const stopPolling = () => {
    setIsPolling(false);
  };

  return {isPolling, startPolling, stopPolling, pollCount};
};

export default usePolling;
