import React, {useEffect, useState} from "react";
import "./Storefront.style.css";
import DynamicTabs from "../../../components/mobile/DynamicTabs/DynamicTabs";
import ReviewOverlay from "./Components/ReviewOverlay/ReviewOverlay";
import ProductSection from "./Components/ProductSection/ProductSection";
import AboutSection from "./Components/AboutSection/AboutSection";
import StoreInfo from "./Components/StoreInfo/StoreInfo";
import ZoopFooterSvg from "../../../components/SvgImages/ZoopFooterSvg/ZoopFooterSvg";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {
  getStoreFrontAboutThunk,
  getStoreFrontDetailsThunk,
  getStoreRatingCountThunk,
  getStoreReviewsThunk,
} from "../../../features/User/UserSlice";
import {getStoreProductsThunk} from "../../../features/Inventory/InventorySlice";
import {RatingCount, Review} from "../../../features/User/UserInterface";
import {useParams} from "react-router";
import SkeletonLoader from "./Components/Skeleton/StoreFrontSkeleton";

type StoreFrontParams = {
  storeId: string;
};

const Storefront: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {storeFront} = useSelector((state: RootState) => state.user);
  const {storeProducts} = useSelector((state: RootState) => state.inventory);
  const [isReviewOverlayOpen, setIsReviewOverlayOpen] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [reviewCount, setReviewCount] = useState<number>(0);
  const [productCount, setProductCount] = useState<number>(0);
  const [storeRatingCount, setStoreRatingCount] = useState<RatingCount[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  const [itemsPerPage] = useState<number>(10);
  const totalPages = Math.ceil(productCount / itemsPerPage);

  useEffect(() => {
    getStoreFrontDetails();
    getStoreFrontAbout();
    // callFetchProducts(1);
    getStoreReviews();
    getStoreRatingCount();
  }, []);

  useEffect(() => {
    callFetchProducts();
  }, [dispatch, currentPage, itemsPerPage]);

  const params = useParams<StoreFrontParams>();

  const getStoreFrontDetails = async () => {
    setLoading(true);
    try {
      const action = await dispatch(
        getStoreFrontDetailsThunk({
          storeId: params.storeId ?? "",
          type: "details",
        }),
      );
      if (getStoreFrontDetailsThunk.fulfilled.match(action)) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const getStoreFrontAbout = async () => {
    setLoading(true);
    try {
      const action = await dispatch(
        getStoreFrontAboutThunk({
          storeId: params.storeId ?? "",
          type: "about",
        }),
      );
      if (getStoreFrontAboutThunk.fulfilled.match(action)) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const callFetchProducts = async (name: string = "") => {
    try {
      const action = await dispatch(
        getStoreProductsThunk({
          store_id: params.storeId,
          page: currentPage,
          limit: itemsPerPage,
          product_name: name,
        }),
      );
      if (getStoreProductsThunk.fulfilled.match(action)) {
        const productCount = action.payload.data.net_count;
        setProductCount(productCount);
      } else {
        return (
          <div style={{justifyContent: "center"}}>
            <text>No products</text>
          </div>
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getStoreReviews = async () => {
    setLoading(true);
    try {
      const action = await dispatch(
        getStoreReviewsThunk({
          storeId: params.storeId ?? "",
        }),
      );
      if (getStoreReviewsThunk.fulfilled.match(action)) {
        setLoading(false);
        const reviews = action.payload.data.review_details;
        const reviewCount = action.payload.data.count;
        setReviewCount(reviewCount);
        setReviews(reviews);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const getStoreRatingCount = async () => {
    setLoading(true);
    try {
      const action = await dispatch(
        getStoreRatingCountThunk({
          storeId: params.storeId ?? "",
        }),
      );
      if (getStoreRatingCountThunk.fulfilled.match(action)) {
        setLoading(false);
        setStoreRatingCount(action.payload.data.result);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const tabData = [
    {
      label: "Products",
      content: (
        <ProductSection
          products={storeProducts.data}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      ),
    },

    {
      label: "Reviews",
      hide: true,
      content: (
        <AboutSection
          storeName={storeFront?.details?.store_name}
          freeDeliveryAmount={undefined}
          email={storeFront.about?.email}
          deliveryDiscount={storeFront.details?.delivery_discount}
          storeId={storeFront.details?.store_id}
          address1={storeFront.about?.address_details_line1}
          address2={storeFront.about?.address_details_line2}
          city={storeFront.about?.city}
          pincode={storeFront.about?.pincode}
          phoneNumber={storeFront.about?.phone_no}
          setReviewOverlayOpen={setIsReviewOverlayOpen}
          profilePhoto={storeFront.details?.profile_url}
          reviews={reviews}
          reviewCount={reviewCount}
          productCount={productCount}
          storeRating={storeFront.about?.store_rating}
          ratings={storeRatingCount}
        />
      ),
    },
  ];

  return (
    <div className="storefront">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div>
          {/* <header className="storefront-header">
        <div className="storefront-header-content">
          <img src="logo.png" alt="Logo" className="storefront-logo" />
          <div className="storefront-search-bar">
            <div className="search-icon">

              <SearchSvg height={20} width={20} />
            </div>
            <input type="text" placeholder="Search" className="storefront-search-input" />
          </div>
        </div>
      </header> */}

          <StoreInfo
            storeName={storeFront?.details?.store_name}
            email={storeFront?.about?.email}
            freeDeliveryAmount={storeFront.details?.free_delivery_amount}
            profilePhoto={storeFront.details?.profile_url}
            isActive={storeFront.about?.is_active}
            storeRating={storeFront.about?.store_rating}
            pastMonthOrder={storeFront.about?.past_month_order_count}
            storeDescription={storeFront?.details?.description}
            productCount={storeProducts.count}
          />

          <DynamicTabs tabs={tabData} scrollable={false} />

          <ReviewOverlay
            isOpen={isReviewOverlayOpen}
            onClose={prev => {
              if (prev) {
                setIsReviewOverlayOpen(!prev);
              }
            }}
            reviews={reviews}
            reviewCount={reviewCount}
          />
          {/* Footer */}
          <footer className="storefront-footer">
            <div className="storefront-footer-box">
              <p className="storefront-footer-zoop">Welcome to ZOOP universe</p>
              <p className="storefront-footer-made-in">Made in Gurugram</p>
              <div className="storefront-footer-divider"></div>
              <div className="storefront-zoop-logo">
                <ZoopFooterSvg height={37} width={98} />
              </div>
            </div>
          </footer>
        </div>
      )}
    </div>
  );
};

export default Storefront;
