import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const ShopSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5048 9.85757V5.13257C14.5048 2.95795 12.7419 1.19507 10.5673 1.19507C8.39268 1.19507 6.62981 2.95795 6.62981 5.13257V9.85757M18.5536 7.76516L19.8799 20.3652C19.9534 21.0626 19.4065 21.6701 18.7052 21.6701H2.42942C1.72811 21.6701 1.18125 21.0626 1.25466 20.3652L2.58098 7.76516C2.64426 7.16397 3.15122 6.70757 3.75574 6.70757H17.3789C17.9834 6.70757 18.4903 7.16397 18.5536 7.76516ZM7.02356 9.85757C7.02356 10.075 6.84727 10.2513 6.62981 10.2513C6.41234 10.2513 6.23606 10.075 6.23606 9.85757C6.23606 9.64011 6.41234 9.46382 6.62981 9.46382C6.84727 9.46382 7.02356 9.64011 7.02356 9.85757ZM14.8986 9.85757C14.8986 10.075 14.7223 10.2513 14.5048 10.2513C14.2873 10.2513 14.1111 10.075 14.1111 9.85757C14.1111 9.64011 14.2873 9.46382 14.5048 9.46382C14.7223 9.46382 14.8986 9.64011 14.8986 9.85757Z"
        stroke="white"
        strokeWidth="1.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShopSvg;
