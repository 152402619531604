import React from "react";
import "./SearchPage.css"; // Link the CSS file for styles

export const SearchPageSkeletonLoader: React.FC = () => {
  return (
    <>
      {/* Products Skeleton */}
      <div className="storefront-skeleton-products">
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
          <div className="storefront-skeleton-product-card-name"></div>
          <div className="storefront-skeleton-product-card-description"></div>
          <div className="storefront-skeleton-product-card-price"></div>
        </div>
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
          <div className="storefront-skeleton-product-card-name"></div>
          <div className="storefront-skeleton-product-card-description"></div>
          <div className="storefront-skeleton-product-card-price"></div>
        </div>
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
        </div>
        <div className="storefront-skeleton-product-card">
          <div className="storefront-skeleton-product-card-image"></div>
        </div>
      </div>
    </>
  );
};

export default SearchPageSkeletonLoader;
