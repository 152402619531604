/* eslint-disable @typescript-eslint/no-explicit-any */
import {api, apiConfig} from "../../interceptors/api";
import {
  GetProductById,
  GetProductResponse,
  GetProductsPayload,
  GetProductsResponse,
  GetSearchProductsPayload,
  GetSearchProductsResponse,
} from "./InventoryInterface";

export const getProducts = async (
  payload: GetProductsPayload,
): Promise<GetProductsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/public/product`,
      {
        params: payload,
      },
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getProductById = async (
  params: GetProductById,
): Promise<GetProductResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/product/${params?.product_id}`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || "Failed to fetch product");
  }
};

export const getSearchProduct = async (
  payload: GetSearchProductsPayload,
): Promise<GetSearchProductsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/search`,
      {
        params: payload,
      },
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};
