import React from "react";
import "./ZoopLogo.style.css";
import {SvgProps} from "../ChatSvg/ChatSvg";

const ZoopGreyLogo: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 95 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.36885 20.2024C1.78041 19.639 0.00153399 16.482 0.00153399 14.0309C0.00153399 11.2414 2.28997 7.74609 6.55528 7.74609H17.8839C22.4608 7.74609 24.3256 11.6073 24.3256 14.0584C24.3256 15.2695 23.9588 16.5387 23.1668 17.6655L18.9859 23.3853C22.4608 23.9212 24.3532 26.9648 24.3532 29.5293C24.3532 32.3188 22.1492 35.785 17.8547 35.785H6.52612C2.17486 35.785 0 32.2346 0 29.5002C0 28.2892 0.366825 27.02 1.18643 25.9207L5.36732 20.1993L5.36885 20.2024Z"
        fill="white"
      />
      <path
        d="M86.2163 24.9285V26.4779C86.2163 29.2674 83.9847 32.7336 79.6902 32.7336C75.3957 32.7336 73.1641 29.1831 73.1641 26.4488V10.9488C73.1641 8.15935 75.4525 4.66406 79.7178 4.66406H84.3223C91.2444 4.66406 95.0017 9.73629 95.0017 14.8652C95.0017 19.4873 91.866 24.0803 86.2163 24.9254V24.9285Z"
        fill="white"
      />
      <path
        d="M59.0523 29.9295C50.8026 29.9295 44.5312 23.9831 44.5312 15.8963C44.5312 7.8095 50.8026 1.89062 59.0523 1.89062C67.3021 1.89062 73.5458 7.83706 73.5458 15.8963C73.5458 23.9555 67.3312 29.9295 59.0523 29.9295Z"
        fill="white"
      />
      <path
        d="M33.3804 28.0389C25.1307 28.0389 18.8594 22.0924 18.8594 14.0057C18.8594 5.91887 25.1307 0 33.3804 0C41.6302 0 47.8739 5.94643 47.8739 14.0057C47.8739 22.0649 41.6593 28.0389 33.3804 28.0389Z"
        fill="#D1D5DB"
      />
      <path
        d="M45.5499 6.17188C46.018 11.5564 43.0481 17.2441 37.3508 21.1329C32.7693 24.2592 27.6997 25.3125 23.3516 24.3572C25.9239 26.6721 29.4249 28.0393 33.3802 28.0393C41.6575 28.0393 47.8736 22.1495 47.8736 14.006C47.8736 11.0466 47.0264 8.37193 45.5499 6.17341V6.17188Z"
        fill="#6B7280"
      />
      <path
        d="M24.0805 9.19072C24.0805 9.19072 27.4479 4.45685 30.5866 3.31165C30.5866 3.31165 31.9649 2.01336 28.6727 2.70078C25.382 3.3882 23.2378 7.20501 23.0844 8.19863C22.9309 9.19072 23.62 10.1078 24.0789 9.19072H24.0805Z"
        fill="white"
      />
      <path
        d="M22.0506 11.8467C22.5575 11.8467 22.9685 11.4368 22.9685 10.9312C22.9685 10.4255 22.5575 10.0156 22.0506 10.0156C21.5437 10.0156 21.1328 10.4255 21.1328 10.9312C21.1328 11.4368 21.5437 11.8467 22.0506 11.8467Z"
        fill="white"
      />
      <path
        d="M33.5986 27.6172C33.5557 28.6093 33.657 29.6504 34.1957 30.4848C34.4397 30.8645 34.7651 31.186 35.0245 31.5549C35.531 32.276 35.7704 33.1962 35.5955 34.0581C35.4205 34.9201 34.805 35.7009 33.9762 35.9995"
        stroke="#9CA3AF"
        strokeWidth="0.239572"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3552 27.4319C33.3552 27.4319 33.3659 27.8805 33.1726 28.0658C33.0375 28.1959 32.6384 28.7027 33.0329 28.6108C33.5056 28.5006 33.9001 28.5603 34.1564 28.5649C34.4127 28.5695 34.5063 28.545 34.2822 28.2908C34.184 28.1806 34.1917 28.0811 34.2147 27.9816C34.4465 27.0017 33.3552 27.4319 33.3552 27.4319Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default ZoopGreyLogo;
