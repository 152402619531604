import React, {ChangeEvent, useState, SetStateAction, Dispatch} from "react";
import "./RemindMePopup.css"; // Assuming you style the component here
import {PopupBellIcon} from "../SvgImages/PopBellIcon/PopBellIcon";
import {IndianFlagIcon} from "../SvgImages/IndianFlag/IndianFlag";
import WhatsAppIcon from "../SvgImages/WhatsappIcon/WhatsAppIcon";
import Loader from "../CustomLoader/CustomLoader";
interface RemindMePopupProps {
  onSubmit: (phoneNumber: string) => void;
  onCancel: () => void;
  onOkay: () => void;
  isOpen: boolean;
  setLoader: Dispatch<SetStateAction<boolean>>;
  loader: boolean;
}

const RemindMePopup: React.FC<RemindMePopupProps> = ({
  onSubmit,
  isOpen,
  onCancel,
  loader,
  onOkay,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [reminderSent, setReminderSent] = useState<boolean>(false);
  const handleSubmit = async () => {
    if (phoneNumber) {
      onSubmit(phoneNumber);
      setReminderSent(true);
    } else {
      setReminderSent(false);
    }
  };

  const preventWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    // Blur the input element that triggered the event
    (event.target as HTMLInputElement).blur();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      return;
    }
  };

  const handleMobileNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="remind-me-popup-overlay">
      <div className="remind-me-popup">
        <div className="popup-header">
          {/* <div className="icon">🔔</div> */}
          <PopupBellIcon width={50} height={50} />
          <p>{reminderSent ? "Thank you! stay tuned" : "Remind me!!"} </p>
        </div>
        {reminderSent ? (
          <>
            <div className="popup-whatsapp-text-container">
              <WhatsAppIcon width={36} height={30} />
              <p className="popup-whatsapp-text">
                {`We'll notify you at`}
                <span style={{color: "black"}}>
                  {" "}
                  {`${phoneNumber ? "+91 " + phoneNumber : "your provided mobile number,"}`}{" "}
                </span>
                {`when the live stream starts`}
              </p>
            </div>
            <div className="popup-footer">
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#1E799C",
                  color: "white",
                }}
                className="popup-cancel-button"
                onClick={onOkay}>
                Okay
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="popup-body">
              <div className="phone-input">
                <span style={{margin: "3px"}}>
                  <IndianFlagIcon width={35} height={30} />
                </span>
                <select className="country-code">
                  <option value="+91">+91</option>
                  {/* Add more country codes as needed */}
                </select>
                <input
                  type="number"
                  className="phone-number"
                  value={phoneNumber}
                  placeholder="Enter number"
                  onWheel={preventWheel}
                  onKeyDown={handleKeyDown}
                  maxLength={10}
                  onChange={handleMobileNumberChange}
                />
              </div>
              <div className="popup-whatsapp-text-container">
                <WhatsAppIcon width={36} height={30} />
                <p className="popup-whatsapp-text">
                  {`We'll send you a message on WhatsApp to ensure you don't miss this live show!`}
                </p>
              </div>
            </div>
            <div className="popup-footer">
              <button className="popup-cancel-button" onClick={onCancel}>
                Cancel
              </button>
              <button
                style={{
                  backgroundColor:
                    phoneNumber.length != 10 ? "#EDEEF2" : "#1E799C",
                  color: phoneNumber.length != 10 ? "grey" : "white",
                }}
                disabled={phoneNumber.length != 10 ? true : false}
                className="popup-submit-button"
                onClick={handleSubmit}>
                {loader ? <Loader size={15} /> : "Submit"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RemindMePopup;
